export function calculateLineHeadPosition (ev, element) {
  const progressWidth = element.getBoundingClientRect().width
  const leftPosition = ev.target.getBoundingClientRect().left
  let pos = (ev.clientX - leftPosition) / progressWidth

  try {
    if (!ev.target.className.match(/^ar\\-line\\-control/)) {
      return
    }
  } catch (err) {
    return
  }

  pos = pos < 0 ? 0 : pos
  pos = pos > 1 ? 1 : pos

  return pos
}

export function convertTimeMMSS (seconds) {
  if(seconds === Infinity) return '00:00'
  return new Date(seconds * 1000).toISOString().substr(14, 5)
}

export function convertTimeSS (mmss) {
  let items = mmss.split(':')
  return Number(items[0]) * 60 + Number(items[1]) - 1
}
