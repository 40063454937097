<template>
  <div>
    <div class="chat-body" v-if="!hasActiveClient && !showDashboard">
      <div class="tab-pane fade show active no-message">
        <div class="empty-screen">
          <img src="@/assets/images/empty-logo.png" class="img-fluid" alt="empty-logo" />
          <h4 class="my-3">暂无会话</h4>
        </div>
      </div>
    </div>
    <div class="chat-body" v-if="showDashboard">
      <Dashboard />
    </div>
    <el-tabs class="chat-body chat-tabs" :value="activeName" type="border-card" v-if="hasActiveClient && !showDashboard" @tab-click="tabClick">
      <el-tab-pane :key="0" name="chat" v-if="activeClient && activeClient.openid">
        <span slot="label">
          <i class="el-icon-chat-dot-round"></i>对话
        </span>
        <Conversation />
      </el-tab-pane>
      <el-tab-pane :key="1" v-if="activeClient && activeClient.openid" name="inquiry" label="问诊">
        <InquiryForm />
      </el-tab-pane>
      <el-tab-pane :key="2" name="healthRecord" label="病历">
        <HealthRecord :edit="edit" />
      </el-tab-pane>
      <el-tab-pane :key="3" name="care" label="随诊">
        <CareList />
      </el-tab-pane>
      <el-tab-pane :key="4" name="subscribe" label="预约">
        <SubscribeList />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Conversation from '@/components/Conversation.vue'
import HealthRecord from '@/components/HealthRecord.vue'
import InquiryForm from '@/components/InquiryForm.vue'
import SubscribeList from '@/components/SubscribeList.vue'
import CareList from '@/components/CareList.vue'
import Dashboard from '@/components/Dashboard.vue'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
export default {
  name: 'conversation_content',

  data () {
    return { edit: true }
  },

  computed: {
    ...mapState('index', ['showDashboard']),
    ...mapState('chat', ['activeName']),
    ...mapGetters('chat', ['hasActiveClient', 'hasActiveMessages', 'activeMessages', 'activeClient'])
  },

  components: { Conversation, HealthRecord, InquiryForm, CareList, Dashboard, SubscribeList },

  methods: {
    ...mapMutations('chat', ['setActiveTabIndex', 'setActiveName']),
    ...mapActions('chat', ['openChatTab']),
    tabClick (tab) {
      this.setActiveTabIndex({ index: Number(tab.index) })
      this.setActiveName({ openid: this.activeClient.openid, name: tab.name })
      if (tab.index == 0) this.openChatTab()
    }
  }
}
</script>
<style lang="less">
.no-message {
  text-align: center;
  margin-top: 50px;
}
.item sup {
  right: 5px !important;
  top: 10px !important;
}
.chat-tabs {
  & > .el-tabs__header {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  & > .el-tabs__content {
    margin-top: 39px;
  }
}
</style>