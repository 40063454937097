<template>
  <div class="container-fluid">
    <div class="header">
      <div class="h5 mb-2 text-gray-800 pt-3">您好，{{userInfo.doctor_name}}</div>
      <div class="h5 mb-2 text-gray-800 pt-3">诊疗总量：{{hrStatusCount.total? hrStatusCount.total : 0}}人次</div>
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-6">
        <!-- Area Chart -->
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="chart-area">
              <div class="chartjs-size-monitor">
                <div class="chartjs-size-monitor-expand">
                  <div class=""></div>
                </div>
                <div class="chartjs-size-monitor-shrink">
                  <div class=""></div>
                </div>
              </div>
            </div>
            <!-- <div id="areaChart" :style="{width: '100%', height: '300px'}"></div> -->
            <div id="lineChart" :style="{width: '100%', height: '300px'}"></div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 count col-lg-6">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h4 class="count-title">
                  排队病人
                </h4>
                <div class="count-content" style="color: #30a5ff;">
                  {{hrStatusCount.lineup ? hrStatusCount.lineup : 0}}
                </div>
              </div>
              <div class="col-md-6">
                <h4 class="count-title">
                  问诊病人
                </h4>
                <div class="count-content" style="color: #ef4040;">
                  {{hrStatusCount.inquiring ? hrStatusCount.inquiring : 0}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h4 class="count-title">
                  随诊病人
                </h4>
                <div class="count-content" style="color: #1ebfae;">
                  {{hrStatusCount.caring ? hrStatusCount.caring : 0}}
                </div>
              </div>
              <div class="col-md-6">
                <h4 class="count-title">
                  满意度
                  <el-tooltip v-if="hrStatusCount.Treat || hrStatusCount.Service" class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <p v-if="hrStatusCount.Treat">治疗满意度：{{hrStatusCount.Treat}}</p>
                      <p v-if="hrStatusCount.Service">服务满意度：{{hrStatusCount.Service}}</p>
                    </div>
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </h4>
                <div style="color: #ffb53e;">
                  <div v-if="!hrStatusCount.Treat && !hrStatusCount.Service" class="count-content">
                    *
                  </div>
                  <div class="count-content" v-else>
                    {{hrStatusCount.Treat}}<span v-if="hrStatusCount.Service && hrStatusCount.Treat">/</span>{{hrStatusCount.Service}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-8 col-lg-7">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="chart-area">
              <div class="chartjs-size-monitor">
                <div class="chartjs-size-monitor-expand">
                  <div class=""></div>
                </div>
                <div class="chartjs-size-monitor-shrink">
                  <div class=""></div>
                </div>
              </div>
            </div>
            <div id="areaBarChart" :style="{width: '100%', height: '400px'}"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div id="pieChart" :style="{width: '100%', height: '300px'}"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-8 col-lg-7">
        <!-- Area Chart -->
        <div class="row">
          <div class="col-xl-6">
            <div class="card shadow mb-4">
              <div class="card-body">
                <h5 class="table-title">
                  预约病人
                </h5>
                <div class="d-flex">
                  <el-select class="mr-2" :value="subscribers.activeDay" @change="changeTime($event,'day')" placeholder="请选择">
                    <el-option v-for="item in subscribers.dayList" :key="item.label" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select :value="subscribers.activeHour" @change="changeTime($event,'hour')" placeholder="请选择">
                    <el-option v-for="item in subscribers.hourList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>
                <div>
                  <el-table :data="subscribers.currentSubscribers" stripe style="width: 100%">
                    <template slot="empty">
                      <p>当前时段没有病人预约</p>
                    </template>
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column label="状态">
                      <template slot-scope="scope">
                        <span>{{subscribeStatusMapping[scope.row.status]}}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card shadow mb-4">
              <div class="card-body">
                <h5 class="table-title">
                  随诊提醒
                </h5>
                <el-table :data="reminderList" stripe style="width: 100%">
                  <el-table-column prop="name" label="姓名">
                  </el-table-column>
                  <el-table-column prop="ageText" label="年龄">
                  </el-table-column>
                  <el-table-column prop="gender" label="性别">
                  </el-table-column>
                  <el-table-column prop="diagnose" label="诊断结果">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import echarts from 'echarts'
import walden from '../assets/walden'
export default {

  data () {
    return {
      subscribeStatusMapping: { 'Pending': '预约中', 'Completed': '已完成', 'Expired': '已过期' }
    }
  },
  computed: {
    ...mapState('index', ['userInfo']),
    ...mapState('dashboard', ['hrStatusCount', 'caringHRs', 'reminderList', 'subscribers']),
    ...mapGetters('dashboard', ['formatAreaChart', 'formatPieChart', 'formatLineChart', 'formatBarChart'])
  },

  async mounted () {
    if (!this.userInfo.hospitalid) {
      await this.getUserInfo()
    }
    await this.getHealthRecordAreaChart()
    await this.getSubscribeAreaBarChart()
    await this.getHealthRecordStatusCount()
    await this.getSubscriberList()
    await this.getReminderList()
    await this.getLastCaringStatusPieChart()
    this.$nextTick(() => {
      this.drawLineChart()
      this.drawAreaBarChart()
      this.drawPieChart()
    });
  },

  methods: {
    ...mapActions('index', ['getUserInfo']),
    ...mapActions('dashboard', ['getHealthRecordAreaChart', 'getHealthRecordStatusCount', 'getSubscriberList', 'getReminderList', 'getLastCaringStatusPieChart', 'getSubscribeAreaBarChart']),
    ...mapMutations('dashboard', ['updateCurrentSubscribers']),
    drawLineChart () {
      let lineChart = echarts.init(document.getElementById('lineChart'))
      lineChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return `问诊人数：${params[0].data}`
          },
        },
        title: {
          text: '门诊统计'
        },
        xAxis: this.formatLineChart.xAxis,
        yAxis: this.formatLineChart.yAxis,
        series: this.formatLineChart.series
      })
    },
    drawAreaChart () {
      echarts.registerTheme('walden', walden)
      let areaChart = echarts.init(document.getElementById('areaChart'), 'walden')
      areaChart.setOption({
        color: this.color,
        title: {
          text: '门诊病人'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: this.formatAreaChart.legend,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: this.formatAreaChart.xAxis,
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.formatAreaChart.series
      });
    },
    drawAreaBarChart () {
      echarts.registerTheme('walden', walden)
      let areaBarChart = echarts.init(document.getElementById('areaBarChart'), 'walden')
      areaBarChart.setOption(this.formatBarChart)
    },
    drawPieChart () {
      echarts.registerTheme('walden', walden)
      let pieChart = echarts.init(document.getElementById('pieChart'), 'walden')
      pieChart.setOption({
        title: this.formatPieChart.title,
        legend: this.formatPieChart.legend,
        series: this.formatPieChart.series,
        tooltip: this.formatPieChart.tooltip
      })
    },
    changeTime (value, type) {
      this.updateCurrentSubscribers({ value: value, type: type })
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less">
.container-fluid {
  background-color: #f8f9fc;
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
    }
  }
  .shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  }
}
.count {
  .count-title {
    text-align: center;
  }
  .count-content {
    text-align: center;
    display: block;
    font-size: 40px;
    top: 38px;
    font-weight: 800;
  }
}
.header {
  display: flex;
  justify-content: space-between;
}
</style>