<template>
  <div>
    <div class="chat-left-search">
      <form>
        <div class="input-group">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ selectedOption }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Inquiring">问诊</el-dropdown-item>
              <el-dropdown-item command="Caring">随诊</el-dropdown-item>
              <el-dropdown-item command="All">所有人</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input type="search" class="form-control" v-model="keyword" @input="handleSearchChatUser" placeholder="搜索"></el-input>
          <el-button class="mx-1 create-patient" @click="handleCreate">新建</el-button>
        </div>
      </form>
    </div>
    <div class="chat-left-body">
      <div class="nav flex-column nav-pills chat-userlist" id="chat-list-tab" role="tablist" aria-orientation="vertical">
        <template v-for="user in allClients">
          <a class="nav-link" :class="{ active: user.active }" :key="user.id" @click="showMessages(user)">
            <div class="media" :class="{ active: user.active }">
              <div v-if="user.openid" class="user-status" :class="[user.online ? 'online': 'offline']"></div>
              <img class="align-self-center rounded-circle" v-if="user.user_avatar_url" :src="user.user_avatar_url" alt="avatar" />
              <img class="align-self-center rounded-circle" v-else src="@/assets/images/girl.svg" alt="avatar" />
              <div class="media-body d-flex justify-between">
                <div>
                  <h5>
                    {{ user.username }}
                    <el-tag size="mini">{{ user.fm_name }}</el-tag>
                    <span class="badge badge-danger ml-2">{{ user.unReadMessageCount }}</span>
                    <span class="chat-timing">{{ user.messageFormatSentDate }}</span>
                  </h5>
                  <p>{{ user.type === 'Image' ? '[图片]' 
                  : user.type === 'Prescription' ? '[处方单]' 
                  : user.type === 'Inquiry' ? '[问诊表单]' 
                  : user.type === 'Question' ? '[问诊问题]' 
                  : user.type === 'Audio' ? '[语音]' 
                  : user.type === 'Post' ? '[文章推荐]'
                  : user.type === 'BotText' ? user.message.reply
                  : user.message }}</p>
                </div>
              </div>
            </div>
          </a>
        </template>
      </div>
    </div>
    <CreatePatientInfo :show="dialogVisible" @closeDialog="dialogVisible = false" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CreatePatientInfo from '../components/CreatePatientInfo'
export default {
  name: 'ChatUsers',

  data () {
    return {
      keyword: '',
      dialogVisible: false,
      innerVisible: false,
      type: '',
      dialogClose: false,
      disableBtn: false,
      isClick: false,
      radio: '',
      complete: false,
    }
  },
  components: { CreatePatientInfo },
  computed: {
    ...mapGetters('inquiry', ['activeHealthRecord']),
    ...mapState('chat', ['allClients', 'isUpdateClient']),
    ...mapGetters('chat', ['selectedOption']),
  },
  methods: {
    ...mapActions('chat', ['switchActiveUser', 'switchUsersByStatus', 'getFamilyMembers']),
    ...mapMutations('chat', ['setSelectedKey', 'setActiveName']),
    ...mapMutations('index', ['openRightBar', 'openChatContent']),
    ...mapMutations('inquiry', ['setSecondHR']),
    handleCreate () {
      if (this.activeHealthRecord.id) {
        let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
        if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
          this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
            confirmButtonText: '离开',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.dialogVisible = true
          }).catch(() => {
          })
        } else {
          this.dialogVisible = true
        }
      } else
        this.dialogVisible = true
    },
    showMessages (user) {
      if (this.activeHealthRecord.id) {
        let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
        if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
          this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
            confirmButtonText: '离开',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.bundleShowMessage(user)
          }).catch(() => {
          })
        } else {
          this.bundleShowMessage(user)
        }
      } else
        this.bundleShowMessage(user)
    },
    bundleShowMessage (user) {
      if (this.isUpdateClient) {
        Object.assign(user, { isUpdate: true })
      }
      this.switchActiveUser(user)
      this.openRightBar()
      this.openChatContent()
      this.setActiveName({ openid: user.openid })
    },
    handleCommand (command) {
      if (command !== this.selectedKey) {
        if (this.activeHealthRecord.id) {
          let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
          if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
            this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
              confirmButtonText: '离开',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              this.setSelectedKey(command)
              this.switchUsersByStatus()
            }).catch(() => {
            })
          } else {
            this.setSelectedKey(command)
            this.switchUsersByStatus()
          }
        } else {
          this.setSelectedKey(command)
          this.switchUsersByStatus()
        }
      }
    },
    async querySearch (queryString, cb) {
      if (!queryString || queryString.length <= 4) {
        cb([])
      } else {
        await this.getFamilyMembers({ queryString: queryString, type: 'phone' })
        cb(this.familyMembers)
      }
    },
    async queryNameSearch (queryString, cb) {
      if (!queryString) {
        cb([])
      } else {
        await this.getFamilyMembers({ queryString: queryString, type: 'name' })
        cb(this.familyMembers)
      }
    },
    async handleSearchChatUser () {
      if (this.activeHealthRecord.id) {
        let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
        if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
          this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
            confirmButtonText: '离开',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await this.switchUsersByStatus({ searchKey: this.keyword })
          }).catch(() => {
          })
        } else {
          await this.switchUsersByStatus({ searchKey: this.keyword })
        }
      } else
        await this.switchUsersByStatus({ searchKey: this.keyword })
    },
  }
}
</script>

<style scoped lang="less">
.nav-link {
  width: 100%;

  .media-body {
    width: calc(100% - 50px);
  }
  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.online {
  background-color: #19a299 !important;
}
.offline {
  background-color: #9e9e9e !important;
}
.connect {
  background-color: #2196f3 !important;
}
.el-dropdown {
  .el-dropdown-link {
    font-weight: bold;
    padding: 10px 10px;
  }
}
.create-patient {
  height: 38px;
  background: #19a299;
  color: #fff;
}
.chat-left-search {
  padding: 10px 0 10px 0;
  border-radius: 3px;
}
.el-form-item {
  .age-input {
    width: 8rem;
  }
  .flex-shrink {
    flex-shrink: 0;
  }
  .el-form-item__label {
    line-height: 25px !important;
  }
  .adjust-form {
    position: relative;
    top: 8px;
  }
}
.form {
  .el-form-item__error {
    display: none !important;
  }
}
.el-dialog__body {
  padding: 0px 20px !important;
}
.create-new {
  margin-top: 2rem;
  color: #909399;
  cursor: pointer;
}
.create-new:hover {
  color: #f56c6c;
}
.select-template {
  width: 15rem;
}
.form-control {
  padding: 0px;
  height: 0px;
}
</style>
