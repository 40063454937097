<template>
  <div class="tab-pane fade show profile" :class="[showProfilePanel ? 'active' : '']">
    <div class="chat-profilebar">
      <HeadBar />
      <div class="chat-left-body">
        <div class="profilebar">
          <el-image class="profile-pic img-fluid avatar" :src="userInfo.doctor_avatar_url" :preview-src-list="[userInfo.doctor_avatar_url]" alt="avatar" fit="cover"></el-image>
          <div class="profile-edit">
            <i class="feather icon-camera upload-button"></i>
            <input @change="getFile($event)" class="profile-upload upload-file" type="file" accept="image/*" />
          </div>
          <h5>{{ userInfo.doctor_name }}</h5>
          <!-- <p class="mb-0">{{ userInfo.hospital_name }}</p> -->
        </div>
        <div class="profile-detail">
          <ul class="list-unstyled mb-0">
            <li class="media">
              <i class="feather icon-home"></i>
              <div class="media-body">
                <p>诊所</p>
                <div class="input-group">
                  <input type="text" class="form-control" :value="userInfo.hospital_name" readonly>
                </div>
              </div>
            </li>
            <li class="media">
              <i class="feather icon-user" style="color: #FF5722;"></i>
              <div class="media-body">
                <p>用户名</p>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="doctorName">
                  <div class="input-group-append">
                    <el-popover placement="top" width="160" v-model="updateNameVisible">
                      <p>昵称修改</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="updateNameVisible = false">点错了</el-button>
                        <el-button type="primary" size="mini" @click="updateName2">确定</el-button>
                      </div>
                      <el-button v-show="userInfo.doctor_name !== userInfo.doctorName" class="btn btn-link no-border" type="button" slot="reference">修改</el-button>
                    </el-popover>
                    <el-button v-if="userInfo.doctor_name !== userInfo.doctorName" @click="resetDoctorName" class="btn btn-link no-border cancel" type="button">取消</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media">
              <img style="margin-right: 15px;" class="icon" src="@/assets/images/department.svg">
              <div class="media-body">
                <p>科室</p>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="doctorDep">
                  <div class="input-group-append">
                    <el-popover placement="top" width="160" v-model="updateDepVisible">
                      <p>科室修改</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="updateDepVisible = false">点错了</el-button>
                        <el-button type="primary" size="mini" @click="updateDep">确定</el-button>
                      </div>
                      <el-button v-show="userInfo.department !== userInfo.doctorDepartment" class="btn btn-link no-border" type="button" slot="reference">修改</el-button>
                    </el-popover>
                    <el-button v-if="userInfo.department !== userInfo.doctorDepartment" @click="resetDoctorDepartment" class="btn btn-link no-border cancel" type="button">取消</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media">
              <i class="feather icon-speaker" style="color: #FF9800;"></i>
              <div class="media-body">
                <p>角色</p>
                <div class="role">
                  <el-tag class="mb-1" v-for="(role, index) in userInfo.roles" :key="index" size="small" type="success">
                    {{role}}
                  </el-tag>
                </div>
              </div>
            </li>
            <li class="media">
              <i class="fa fa-tag" style="color: #4CAF50;"></i>
              <div class="media-body">
                <p>自我描述</p>
                <div class="input-group">
                  <el-input type="textarea" autosize class="form-control description" v-model="doctorDes"></el-input>
                  <div class="input-group-append">
                    <el-popover placement="top" width="160" v-model="updateDesVisible">
                      <p>描述修改</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="updateDesVisible = false">点错了</el-button>
                        <el-button type="primary" size="mini" @click="updateDes">确定</el-button>
                      </div>
                      <el-button v-show="userInfo.doctor_description !== userInfo.doctorDescription" class="btn btn-link no-border" type="button" slot="reference">修改</el-button>
                    </el-popover>
                    <el-button v-if="userInfo.doctor_description !== userInfo.doctorDescription" @click="resetDoctorDescription" class="btn btn-link no-border cancel" type="button">取消</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media">
              <i class="el-icon-key" style="color: #4CAF50;"></i>
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button size="mini" @click="dialogVisible = true">修改密码</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isPharamacist && userInfo.isDoctor">
              <i class="feather icon-navigation" style="color: #03A9F4;"></i>
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button type="success" size="mini" @click="switchOperation('showApproval')">处方审批</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isPharamacist || userInfo.isDoctor">
              <!-- <i class="feather icon-navigation" style="color: #03A9F4;"></i> -->
              <img class="icon" src="@/assets/images/medicine.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button type="danger" size="mini" @click="switchOperation('showMedicineList')">药品管理</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isAdministrator">
              <img class="icon" src="@/assets/images/doctor.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button size="mini" @click="switchOperation('showDoctorList')">医生管理</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isDoctor">
              <img class="icon" src="@/assets/images/log.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button type="primary" size="mini" @click="switchOperation('showTreatmentLog')">门诊日志</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isEditor">
              <img class="icon" src="@/assets/images/post.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button type="warning" size="mini" @click="switchOperation('showPostList')">文章列表</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isManager">
              <img class="icon" src="@/assets/images/bot.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button type="info" size="mini" @click="switchOperation('showBotConfig')">助手管理</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li class="media" v-if="userInfo.isManager">
              <img class="icon" src="@/assets/images/subscribe.svg">
              <div class="media-body">
                <div class="input-group">
                  <div class="form-control"></div>
                  <div class="input-group-append">
                    <el-button class="subscribe-btn" size="mini" @click="switchOperation('showSubscribe')">上班时间</el-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%" :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
      <el-form>
        <el-form-item label="旧密码">
          <el-input type="password" v-model="oldPassword" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="password" placeholder="请输入8位数以上的新的密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input type="password" v-model="rePassword" placeholder="请输入8位数以上的新的密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleChangePassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import HeadBar from '@/components/HeadBar.vue'
export default {
  name: 'profile',
  data () {
    return {
      updateNameVisible: false,
      updateDesVisible: false,
      updateDepVisible: false,
      dialogVisible: false,
      dialogClose: false,
      oldPassword: '',
      password: '',
      rePassword: '',
    }
  },

  components: { HeadBar },

  computed: {
    ...mapState('index', ['userInfo', 'showProfilePanel']),
    doctorName: {
      get () {
        return this.userInfo.doctor_name
      },
      set (value) {
        if (value) {
          this.updateUserName(value)
        }
      }
    },
    doctorDes: {
      get () {
        return this.userInfo.doctor_description
      },
      set (value) {
        this.updateUserDes(value)
      }
    },
    doctorDep: {
      get () {
        return this.userInfo.department
      },
      set (value) {
        this.updateUserDep(value)
      }
    }
  },

  methods: {
    ...mapMutations('index', ['resetDoctorName', 'resetDoctorDescription', 'resetDoctorDepartment', 'switchApproval', 'switchTreatmentLog', 'switchPostLost', 'updateUserName', 'updateUserDes', 'updateUserDep', 'switchOperation']),
    ...mapMutations('chat', ['setActiveTabIndex']),
    ...mapActions('index', ['updateName', 'updateDescription', 'updateDepartment', 'updateAvatar', 'changePassword']),

    updateName2 () {
      this.updateNameVisible = false
      this.updateName()
    },
    updateDes () {
      this.updateDesVisible = false
      this.updateDescription()
    },
    updateDep () {
      this.updateDepVisible = false
      this.updateDepartment()
    },
    closeDialog () {
      this.emptyPassword()
      this.dialogVisible = false
    },
    emptyPassword () {
      this.password = ''
      this.oldPassword = ''
      this.newPassword = ''
    },
    async handleChangePassword () {
      if (this.password !== this.rePassword) {
        this.$message('新的密码和重复输入密码不一致')
        return
      } else if (this.password.length < 8 || !this.oldPassword.length) {
        this.$message('请按要求输入密码')
      }
      else {
        this.changePassword({ id: this.userInfo.id, oldPassword: this.oldPassword, password: this.password })
      }
    },
    async getFile (event) {
      if (event.target.files && event.target.files.length > 0) {
        let file = event.target.files[0]
        let imgName = file.name;
        let idx = imgName.lastIndexOf(".")
        if (idx != -1) {
          let ext = imgName.substr(idx + 1).toUpperCase()
          ext = ext.toLowerCase()
          if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
            //upload file
            await this.updateAvatar({
              file: file,
              fileType: ext
            })
          } else {
            this.$message({
              message: '仅支持.png, .jpg, .jpeg图片格式',
              type: 'warning'
            });
          }
        }
      }
    },
  }
};
</script>
<style lang="less">
.profile {
  .role {
    padding-top: 5px;
    span {
      margin-right: 10px;
    }
  }
  .avatar {
    height: 120px !important;
    width: 120px !important;
  }
  .no-border {
    border: 0 !important;
  }
  .cancel {
    margin-left: 5px;
    margin-top: -2px;
    height: 26px;
  }
  .upload-file {
    opacity: 0;
    position: absolute;
    margin-left: -20px;
    display: unset !important;
  }
  .description {
    height: auto !important;
    margin-right: 10px;

    textarea {
      border: 0 !important;
      padding-left: 0;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .subscribe-btn{
      background: #a3aedb;
      color:#fff;
  }
}
</style>
