<template>
  <div>
    <el-dialog title="病历模板管理" :visible.sync="show" width="46%" :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
      <el-dialog width="56%" :title="title" :visible.sync="innerVisible" append-to-body>
        <div class="inner-container">
          <div v-if="type!=='add'">
            <label>模板名称</label>
            <el-input class="mr-5" type="text" v-model="targetTemplate.name"></el-input>
          </div>
          <el-form v-else ref="form" label-width="80px">
            <el-form-item label="模板名称">
              <el-input v-model="targetTemplate.name"></el-input>
            </el-form-item>
            <el-form-item label="疾病类型">
              <el-input v-model="targetTemplate.sickness"></el-input>
            </el-form-item>
            <el-form-item label="患者类型">
              <el-radio-group v-model="targetTemplate.child_adult" @change="handleForceUpdate">
                <el-radio label="成人">成人</el-radio>
                <el-radio label="儿童">儿童</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="门诊类型">
              <el-radio-group v-model="targetTemplate.first_subsequent_visit" @change="handleForceUpdate">
                <el-radio label="初诊">初诊</el-radio>
                <el-radio label="复诊">复诊</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="my-4 d-flex" v-for="(item) in targetTemplate.fieldMapping" :key="item.label">
            <div style="width: 100%;">
              <div class="undraggable">{{item.label}}</div>
              <div class="d-flex">
                <div style="width: 100%;">
                  <el-input class="mr-2 " :autosize="{ minRows: 2}" v-model="targetTemplate[item.field]" type="textarea" :placeholder="item.label" @input="handleForceUpdate"></el-input>
                </div>
                <i class="el-icon-close template-close red undraggable" @click="handleDeleteItem(item.label)"></i>
              </div>
              <div v-if="!item.showAdd" class="center"><i class="cursor-pointer el-icon-circle-plus-outline" @click="showAddSection(item.label)"></i></div>
              <div class="mt-2" v-else>
                <el-select v-model="addOption" placeholder="请选择">
                  <el-option v-for="item in notChoiceOptions" :key="item.value" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
                <div class="d-flex">
                  <el-input class="mt-2 mr-2" v-model="addContent" type="textarea"></el-input>
                  <div class="add-icon">
                    <i class="el-icon-close red cursor-pointer" @click="handleHideItem(item.label)"></i>
                    <i class="el-icon-check green cursor-pointer" @click="handleAddItem(item.label)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取消</el-button>
          <el-button type="primary" @click="handleUpdateTemplate">{{btnTitle}}</el-button>
        </span>
      </el-dialog>
      <div class="templates-table">
        <div class="row mb-2">
          <div class="col-md-6">名称</div>
          <div class="col-md-6">操作</div>
        </div>
        <div class="template-container">
          <vuedraggable class="transition" v-model="copyHRTemplates" filter=".undraggable-list">
            <transition-group>
              <div v-for="template in copyHRTemplates" :key="template.id" class="row mb-2">
                <div class="col-md-6 undraggable-list">{{template.name}}</div>
                <div class="col-md-4 undraggable-list">
                  <div class="d-flex">
                    <el-button size="small" :type="template.visible ? 'danger':'success'" @click="handleVisible(template)">{{template.visible ? '隐藏':'显示'}}</el-button>
                    <el-button size="small" type="primary" @click="edit(template)">编辑内容</el-button>
                  </div>
                </div>
                <div class="col-md-1 cursor-pointer">
                  <img style="width: 2rem;" src="@/assets/images/drag.svg">
                </div>
              </div>
            </transition-group>
          </vuedraggable>
        </div>
      </div>
      <span slot="footer" style="justify-content: space-between;" class="dialog-footer d-flex ">
        <el-button type="success" @click="changeHRTemplatesSequence">调整顺序</el-button>
        <div>
          <el-button @click="handleClose">关闭</el-button>
          <el-button type="primary" @click="handleAddTemplate">新增</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import vuedraggable from 'vuedraggable'

export default {

  data () {
    return {
      dialogClose: false,
      targetTemplate: {},
      innerVisible: false,
      notChoiceOptions: [],
      addOption: '',
      addContent: '',
      type: '',
      title: '',
      btnTitle: '',
      inputDraggable: false
    }
  },

  components: { vuedraggable },

  props: {
    show: Boolean
  },

  computed: {
    ...mapState('index', ['allSections', 'hrTemplates']),
    copyHRTemplates: {
      get () {
        return this.hrTemplates
      },
      set (value) {
        this.updateHRTemplates(value)
      }
    }
  },

  methods: {
    ...mapMutations('index', ['updateHRTemplates']),
    ...mapActions('index', ['updateHRTemplate']),
    ...mapActions('inquiry', ['changeHRTemplatesSequence']),
    handleClose () {
      this.$emit('closeDialog')
    },
    edit (row) {
      this.targetTemplate = JSON.parse(JSON.stringify(row))
      this.getFieldMapping(this.targetTemplate)
      this.title = '编辑病历模板'
      this.btnTitle = '修改'
      this.innerVisible = true
    },
    async handleUpdateTemplate () {
      let template = {}
      let visible_sections = {}
      this.targetTemplate.fieldMapping.forEach(item => {
        visible_sections[item.label] = item.field
        template[item.field] = this.targetTemplate[item.field]
      })
      if (this.btnTitle !== '修改' && !this.targetTemplate.name && !this.targetTemplate.sickness) {
        this.$message('模板名称或疾病类型必填')
        return
      }
      Object.assign(template, { visible_sections: JSON.stringify(visible_sections) }, { id: this.targetTemplate.id, name: this.targetTemplate.name, sickness: this.targetTemplate.sickness, child_adult: this.targetTemplate.child_adult, first_subsequent_visit: this.targetTemplate.first_subsequent_visit, isEdit: this.btnTitle === '修改' })
      await this.updateHRTemplate(template)
      if (this.btnTitle !== '修改') this.innerVisible = false
    },
    handleForceUpdate () {
      this.$forceUpdate()
    },
    handleDeleteItem (label) {
      this.targetTemplate.fieldMapping.splice(this.targetTemplate.fieldMapping.findIndex(item => item.label === label), 1)
      this.$forceUpdate()
    },
    handleVisible (row) {
      let template = { id: row.id }
      template.visible = row.visible ? 0 : 1
      this.updateHRTemplate(Object.assign(template, { isEdit: true }))
    },
    showAddSection (label) {
      this.notChoiceOptions = []
      let labels = this.targetTemplate.fieldMapping.map(item => item.label)
      for (let item in this.allSections) {
        if (!labels.includes(item)) {
          this.notChoiceOptions.push({ label: item, value: this.allSections[item] })
        }
      }
      let targetItem = {}, cannotOpen = false
      this.targetTemplate.fieldMapping.forEach(item => {
        if (item.label === label) {
          targetItem = item
        } else if (item.label !== label && item.showAdd) {
          this.$message('请先关闭或者完成已经开启的模块')
          cannotOpen = true
        }
      })
      if (cannotOpen) return
      targetItem.showAdd = true
      this.$forceUpdate()
    },
    handleHideItem (label) {
      this.targetTemplate.fieldMapping.some(item => {
        if (item.label === label) {
          item.showAdd = false
          return true
        }
      })
      this.addOption = ''
      this.addContent = ''
      this.$forceUpdate()
    },
    handleAddItem (label) {
      let section = {
        label: this.addOption,
        value: this.addContent,
        field: this.allSections[this.addOption],
        showAdd: false
      }
      let index = 0
      this.targetTemplate.fieldMapping.some(item => {
        index++
        if (label === item.label) {
          item.showAdd = false
          return true
        }
      })
      this.targetTemplate[section.field] = ''
      this.targetTemplate.fieldMapping.splice(index, 0, section)
      Object.assign(this.targetTemplate, { [section.field]: section.value })
      this.addOption = ''
      this.addContent = ''
      this.$forceUpdate()
    },
    handleAddTemplate () {
      this.targetTemplate = JSON.parse(JSON.stringify(this.hrTemplates.find(item => {
        if (item.sickness === '默认') {
          return true
        }
      })))
      this.targetTemplate.id = ''
      this.targetTemplate.name = ''
      this.targetTemplate.child_adult = ''
      this.targetTemplate.first_subsequent_visit = ''
      this.targetTemplate.sickness = ''
      this.type = 'add'
      this.getFieldMapping(this.targetTemplate)
      for (let section of this.targetTemplate.fieldMapping) {
        this.targetTemplate[section.field] = this.targetTemplate[section.field] || ''
      }
      this.title = '新增病历模板'
      this.btnTitle = '确认'
      this.innerVisible = true
    },
    getFieldMapping (template) {
      if (!template.visible_sections) {
        this.$message('模板有误')
        return
      }
      this.targetTemplate.sections = JSON.parse(template.visible_sections)
      let fieldMapping = []
      for (let key in this.targetTemplate.sections) {
        let field = this.targetTemplate.sections[key]
        fieldMapping.push({ label: key, field: field, value: this.targetTemplate[field], showAdd: false })
      }
      this.targetTemplate.fieldMapping = fieldMapping
    },
  }
}
</script>
<style lang="less">
.sections {
  display: flex;
  align-items: center;
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
.el-dialog__body {
  overflow-x: hidden;
}
.template-container {
  height: 360px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
.template-close {
  width: 1rem !important;
  height: 1rem;
  line-height: 3.2rem;
  margin: 0 auto;
  font-weight: bold;
  cursor: pointer;
}
.el-dialog__body {
  padding: 0px 20px !important;
}
.center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.green {
  color: green;
}
.red {
  color: red;
}
.justify-between {
  justify-content: space-between;
}
.undraggable {
  width: 100%;
  margin-right: 1rem;
}
.draggable {
  cursor: pointer;
  align-self: center;
}
.templates-table .transition {
  overflow-y: scroll;
  overflow-x: hidden;
}
.border-line {
  border-bottom: 1px solid #eee;
}
.inner-container {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
