<template>
  <div>
    <div class="table-container">
      <div class="source">
        <div class="flex filter mb-2">
          <el-select v-model="category" placeholder="请选择问题类别" @change="changeCategory">
            <el-option v-for="(item, index) in categories" :key="index" :label="item" :value="item"></el-option>
          </el-select>
          <el-input class="search-input" placeholder="请输入查找内容" clearable v-model="question"></el-input>
          <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="handleSearchBotQA">查找</el-button>
        </div>
        <div class="table-content">
          <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="cloneBotQAList">
            <template slot="empty">
              <p v-text="pageNumber===0 ? '暂无数据' : ''"></p>
            </template>
            <el-table-column label="问题" width="200">
              <template slot-scope="scope">
                <el-input type="textarea" :readonly="scope.row.readonly" :data-id="scope.row.id" data-field="question" @input="handleChange($event, scope.row.id, 'question')" v-model="scope.row.question"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="回答">
              <template slot-scope="scope">
                <div class="posts d-flex" v-if="scope.row.reply_type === 'Post'">
                  <div class="list">
                    <div v-for="post in scope.row.posts" :key="post.id" class="item">{{post.title}}<i class="el-icon-close" style="color:red" :data-id="post.id" @click="handleDeletePost($event, scope.row)"></i></div>
                  </div>
                  <el-autocomplete popper-class="my-autocomplete" v-model="scope.row.searchKey" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect" @change="handleSearch(scope.row)">
                    <i class="el-icon-search el-input__icon" slot="suffix">
                    </i>
                  </el-autocomplete>
                </div>
                <el-input v-else v-model="scope.row.reply" :data-id="scope.row.id" data-field="reply" @input="handleChange($event, scope.row.id, 'reply')" :value="scope.row.reply"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width=200>
              <template slot-scope="scope">
                <div class="d-flex">
                  <el-button size="small" type="primary" icon="el-icon-edit" @click="manageQuestion(scope.row.id)">问题管理</el-button>
                  <el-button size="small" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="loading-info">
            <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
            <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
          </div>
        </div>
        <div class="d-flex mt-2">
          <el-button class="mr-2" type="warning" @click="handleUpdate">更新</el-button>
          <el-button class="mr-2" type="success" @click="dialogVisible = true">新增</el-button>
          <el-button type="primary" @click="showTestDialog = true">测试</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="新增配置" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="newBotQA" label-width="80px">
        <el-form-item label="问题">
          <el-input v-model="newBotQA.question"></el-input>
        </el-form-item>
        <el-form-item label="问题分类">
          <el-select v-model="newBotQA.category" placeholder="请选择问题类别">
            <el-option v-for="(item, index) in categories" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回复类型">
          <el-select v-model="newBotQA.reply_type" placeholder="请选择回复类型">
            <el-option v-for="(type,index) in replyTypes" :key="index" :label="type.label" :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="newBotQA.reply_type" label="回复">
          <el-input v-if="newBotQA.reply_type === 'Text'" v-model="newBotQA.reply" placeholder="请输入回复内容"></el-input>
          <el-autocomplete v-else-if="newBotQA.reply_type === 'Post'" popper-class="my-autocomplete" v-model="newBotQA.reply" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleNewSelect">
            <i class="el-icon-search el-input__icon" slot="suffix">
            </i>
          </el-autocomplete>
        </el-form-item>
        <div class="list">
          <div v-for="post in this.newBotQA.posts" :key="post.id" class="item">{{post.title}}<i class="el-icon-close" style="color:red" :data-id="post.id" @click="handleNewDeletePost($event)"></i></div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCreate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="沃沃助手" :visible.sync="showTestDialog" width="60%">
      <div class="chat-content">
        <div class="chat-message mt-1" v-for="(msg, index) in messages" :key="index" :class="msg.type ==='ask' ? 'chat-message-right' : 'chat-message-left'">
          <div class="chat-message-text" :style="msg.type ==='ask' ? 'background:#19a299;color:#fff' : ''">
            <span v-if="msg.replyType==='Text' || !msg.replyType" class="message">{{ msg.msg }}</span>
            <div v-else-if="msg.replyType ==='Post'">
              [ 文章推荐 ]
              <div v-html="msg.msg"></div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="d-flex dialog-footer">
        <el-input class="chat-input mr-3" v-model="testMsg" @keyup.enter="handleSendTestMessage" placeholder="请输入内容"></el-input>
        <el-button type="primary" @click="handleSendTestMessage">发送</el-button>
      </span>
    </el-dialog>
    <el-dialog title="问题管理" :visible.sync="showQuestionManagement" width="60%">
      <el-form :model="botQuestion">
        <el-form-item>
          <el-input type="textarea" rows="10" v-model="botQuestion.questions"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showQuestionManagement = false">取 消</el-button>
        <el-button type="primary" @click="upsertManageQuestion">更 新</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'bot_QA',

  data () {
    return {
      searchKey: '',
      id: '',
      botQA: {},
      dialogVisible: false,
      showTestDialog: false,
      showQuestionManagement: false,
      newBotQA: { id: '', question: '', reply: '', posts: [], reply_type: '', category: '' },
      replyTypes: [{ label: "文字", value: "Text" }, { label: "资讯文章", value: "Post" }],
      testMsg: '',
      question: '',
      pageIndex: 0,
      type: '',
      isClick: true,
      category: '',
      newCategory: '',
      currentQaId: ''
    }
  },

  async mounted () {
    await this.getBotQACategories()
  },

  computed: {
    ...mapState('botQA', ['botQAList', 'botQuestion', 'messages', 'loading', 'pageNumber', 'categories']),
    ...mapGetters('botQA', ['posts']),
    disabled () {
      return this.loading || this.noMore
    },
    noMore () {
      return this.pageNumber && this.pageIndex >= this.pageNumber
    },
    cloneBotQAList () {
      return JSON.parse(JSON.stringify(this.botQAList))
    }
  },

  methods: {
    ...mapActions('botQA', ['getBotQAList', 'getBotPostList', 'deleteBotQA', 'deleteBotQuestion', 'createBotQA', 'batchUpdateBotQA', 'batchDeleteAndInsertBotQuestion', 'sendChatMessage', 'getBotQACategories', 'getBotQuestion']),
    ...mapMutations('botQA', ['updateBotQA', 'updateBotQuestion', 'addMessage', 'emptyBotQAList', 'emptyBotQuestion', 'emptyBotQAPageNumber', 'switchBotQALoading']),
    async handleSearch (row) {
      this.id = row.id
      this.botQA = row
    },
    async querySearch (queryString, cb) {
      if (!queryString) {
        cb([])
      } else {
        await this.getBotPostList(queryString)
        cb(this.posts)
      }
    },
    createFilter (queryString) {
      return (post) => {
        return (post.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect (item) {
      let index = this.botQA.posts.findIndex(post => post.id === item.id)
      if (index === -1) {
        this.botQA.posts.push({ id: item.id, title: item.value })
        this.updateBotQA({ id: this.botQA.id, value: JSON.stringify({ postids: this.botQA.posts.map(post => post.id) }), field: 'reply' })
      }
    },
    handleNewSelect (item) {
      this.newBotQA.reply_type = 'Post'
      if (!this.newBotQA.posts.length) {
        this.newBotQA.posts.push({ id: item.id, title: item.value })
      } else {
        let index = this.newBotQA.posts.findIndex(post => post.id === item.id)
        if (index === -1) {
          this.newBotQA.posts.push({ id: item.id, title: item.value })
        }
      }
    },
    async manageQuestion (bot_qaid) {
      await this.getBotQuestion(bot_qaid)
      this.currentQaId = bot_qaid
      this.showQuestionManagement = true
    },
    async upsertManageQuestion () {
      await this.batchDeleteAndInsertBotQuestion()
      await this.getBotQuestion(this.currentQaId)
    },
    handleDeletePost (e, row) {
      let postid = e.currentTarget.dataset.id
      let index = this.botQAList.findIndex(item => item.id === row.id)
      let item = this.botQAList[index].posts
      item.splice(item.findIndex(post => post.id === postid), 1)
      this.updateBotQA({ id: row.id, value: JSON.stringify({ postids: item.map(post => post.id) }), field: 'reply' })
    },
    handleDelete (id) {
      this.$confirm('确认删除', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.deleteBotQA(id)
      })
    },
    handleNewDeletePost (e) {
      let postid = e.currentTarget.dataset.id
      this.newBotQA.posts.splice(this.newBotQA.posts.splice(post => post.id === postid))
    },
    async handleCreate () {
      this.dialogVisible = false
      if (!this.newBotQA.question || !this.newBotQA.reply_type || !this.newBotQA.category) {
        this.$message.warning('问题、分类和回复不能为空')
        return
      } else {
        if (this.newBotQA.reply_type === 'Post') {
          let reply = {
            postids: this.newBotQA.posts.map(post => post.id)
          }
          this.newBotQA.reply = JSON.stringify(reply)
        }
        await this.createBotQA(this.newBotQA)
      }
      this.newBotQA = {}
    },
    handleChange (value, id, field) {
      this.updateBotQA({ id: id, value: value, field: field })
    },
    // handleQuestionChange (value, id) {
    //   this.updateBotQuestion({ id: id, value: value })
    // },
    handleUpdate () {
      this.batchUpdateBotQA()
    },
    async handleSearchBotQA () {
      if (this.isClick) {
        this.isClick = false
        this.initBotQAList()
        await this.load()
        this.isClick = true
      }
    },
    async handleSendTestMessage () {
      this.addMessage({ msg: this.testMsg, type: 'ask', replyType: '' })
      await this.sendChatMessage({ message: this.testMsg })
      this.testMsg = ''
    },
    async load () {
      this.switchBotQALoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      await this.getBotQAList({ question: this.question, category: this.category === '全部类别' ? '' : this.category, pageIndex: this.pageIndex++ })
    },
    initBotQAList () {
      this.pageIndex = 0
      this.emptyBotQAList()
      this.emptyBotQAPageNumber()
    },
    async changeCategory () {
      this.initBotQAList()
      await this.load()
    }
  },

}
</script>
<style lang="less" scoped>
.table-container {
  border-radius: 3px;
  transition: 0.2s;
  .source {
    padding: 24px;
  }
  .table-content {
    .log {
      height: calc(100vh - 228px) !important;
      overflow: auto !important;
      .el-table {
        td {
          height: 57px;
          vertical-align: top;
        }
      }
    }
  }
}
.loading-info {
  display: block;
  height: 1rem;
}
.posts {
  justify-content: space-between;
  align-items: center;
  .post-search {
    width: 10rem;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  .item {
    margin: 1px 5px;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #409eff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
  }
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.chat-content {
  height: 376px;
  overflow: auto;
  background: #eee;
  margin-bottom: 10px;
  padding: 10px;
  .chat-message-right {
    text-align: right;
  }
  .chat-message-left {
    text-align: left;
  }
  .chat-message-text {
    background-color: #ffffff;
    color: #4c595f;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 3px;
    position: relative;
  }
}
.el-dialog__body {
  //   padding-top: 10px;
  padding: 0px !important;
}
.filter {
  .search-input {
    width: 16rem;
  }
}
</style>