<template>
  <div>
    <el-dialog title="新建病人信息" :visible.sync="show" width="60%" :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
      <el-dialog width="30%" title="已在系统中找到该手机号关联的用户" :visible.sync="innerVisible" append-to-body :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
        <el-table ref="singleTable" :data="systemUsers" highlight-current-row @row-click="showRow" style="width: 100%">
          <el-table-column label="选择" width="70" header-align="center" align="center">
            <template slot-scope="scope">
              <el-radio class="radio" v-model="radio" :label="scope.$index"></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="账户" header-align="center" align="center"></el-table-column>
          <el-table-column prop="familyNames" label="家庭成员" header-align="center" align="center"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCancelInner">返回</el-button>
          <el-button type="primary" @click="createHRByUseSystemInfo">创建病历</el-button>
        </span>
      </el-dialog>
      <div v-if="currentStep == 0" class="fm-container">
        <el-input placeholder="请输入患者姓名或者手机号" v-model="patientSearchKey" @input="handleSearchPatient"></el-input>
        <el-table class="mt-2 fm-table" height="360" :data="familyMembers" highlight-current-row @row-click="showFamilyRow">
          <el-table-column label="选择" width="70">
            <template slot-scope="scope">
              <el-radio class="radio" v-model="familyRadio" :label="scope.$index"></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="ageText" label="年龄"></el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              <span v-if="scope.row.gender===1">男</span>
              <span v-if="scope.row.gender===0">女</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form v-loading="disableBtn" v-if="currentStep == 1" ref="patientForm" class="form" :model="patientForm" label-width="80px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="电话号" prop="phone">
              <el-input :readonly="useSystemInfo" class="adjust-form" v-model="patientForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input :readonly="useSystemInfo" class="adjust-form" v-model="patientForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="住址" prop="address">
              <el-input class="adjust-form" v-model="patientForm.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="年龄" prop="age">
          <div class="d-flex">
            <el-input v-model="patientForm.y" :min="0" @input="patientForm.y > 99 ? patientForm.y = 99 : patientForm.y" class="age-input adjust-form" type="number"></el-input><span class="mx-1 adjust-form">岁</span>
            <el-input v-model="patientForm.m" :min="0" @input="patientForm.m > 12 ? patientForm.m = 12 : patientForm.m" class="age-input adjust-form" type="number"></el-input><span class="mx-1 adjust-form">月</span>
            <el-input v-model="patientForm.d" :min="0" @input="patientForm.d > 30 ? patientForm.d = 30 : patientForm.d" class="age-input adjust-form" type="number"></el-input><span class="mx-1 adjust-form">天</span>
          </div>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-radio-group class="adjust-form" v-model="patientForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重" prop="weight">
              <div class="d-flex">
                <el-input v-model="patientForm.weight" class="age-input adjust-form" type="number"></el-input>
                <span class="ml-2 flex-shrink">kg</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="病历模板" prop="hrTemplate">
          <el-select class="select-template" value-key="id" v-model="patientForm.hrTemplate" placeholder="请选择病历模板">
            <template v-for="item in hrTemplates">
              <div v-if="item.visible" :key="item.id">
                <el-option :label="item.name" :value="item"></el-option>
              </div>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="disableBtn" @click="handleCancel">取消</el-button>
        <el-button :disabled="disableBtn" type="info" v-if="currentStep === 1" @click="handlePrevStep">上一步</el-button>
        <el-button :disabled="disableBtn" type="primary" @click="nextStep('patientForm')">{{currentStep === 1 ? '创建':'下一步'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      dialogClose: false,
      disableBtn: false,
      patientSearchKey: '',
      innerVisible: false,
      selectUser: {},
      selectFamilyMember: {},
      radio: '',
      familyRadio: '',
      currentStep: 0,
      patientForm: {
        name: '',
        phone: '',
        gender: '',
        weight: 0,
        hrTemplate: '',
        userid: '',
        y: 0,//岁
        m: 0,//月
        d: 0, //天
        age: 0,
        address: '',
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        hrTemplate: [{ required: true, message: '请选择问诊模板', trigger: 'blur' }],
      },
      loading: false,
      useSystemInfo: false
    }
  },

  props: {
    show: Boolean
  },

  computed: {
    ...mapState('chat', ['familyMembers', 'systemUsers', 'hrWithUserInfo', 'newFamilyMember']),
    ...mapState('index', ['hrTemplates']),
  },

  methods: {
    ...mapActions('chat', ['getFamilyMembers', 'isUserExists', 'updateFamilyMember', 'switchUsersByStatus', 'switchActiveUser', 'createOfflinePatient', 'createHealthRecord', 'createFamilyMember']),
    ...mapMutations('chat', ['emptyFamilyMembers', 'setSelectedKey']),
    async handleSearchPatient () {
      let numReg = /^[0-9]+.?[0-9]*$/
      if (!this.patientSearchKey) {
        this.familyRadio = ''
        this.emptyFamilyMembers()
      }
      else if (!numReg.test(this.patientSearchKey)) {
        await this.getFamilyMembers({ queryString: this.patientSearchKey, type: 'name' })
      } else if ((numReg.test(this.patientSearchKey) && this.patientSearchKey.length >= 4)) {
        await this.getFamilyMembers({ queryString: this.patientSearchKey, type: 'phone' })
      }
    },
    async createHRByUseSystemInfo () {
      this.setSelectedKey('Inquiring')
      if (!this.selectUser.id && !this.useSystemInfo) {
        this.$message('请选择一个用户')
        return
      }
      let isFamilyMemberUpdatable = false
      if (this.selectUser.familyMembers && this.selectUser.familyMembers.length) {
        this.patientForm.userid = this.selectUser.id
        for (let fm of this.selectUser.familyMembers) {
          if (fm.name === this.patientForm.name) {
            isFamilyMemberUpdatable = true
            this.patientForm.id = fm.id
            await this.updateFamilyMember(this.patientForm)
            break
          }
        }
      } else if (this.useSystemInfo) {
        isFamilyMemberUpdatable = true
        await this.updateFamilyMember(this.patientForm)
      }
      if (!isFamilyMemberUpdatable) {
        await this.createFamilyMember(this.patientForm)
        this.patientForm.id = this.newFamilyMember.id
      }
      isFamilyMemberUpdatable = false
      await this.createHealthRecord(this.patientForm)
      this.setSelectedKey('Inquiring')
      await this.switchUsersByStatus()
      await this.switchActiveUser({ id: this.hrWithUserInfo.patientid, health_recordid: this.hrWithUserInfo.id, openid: this.hrWithUserInfo.openid, isUpdate: true })
      this.disableBtn = false
      this.innerVisible = false
      this.$emit('closeDialog')
      this.emptyDialogInfo()
      this.resetForm('patientForm')
    },
    async createNewPatient () {
      await this.createOfflinePatient(this.patientForm)
      this.setSelectedKey('Inquiring')
      await this.switchUsersByStatus()
      await this.switchActiveUser({ id: this.hrWithUserInfo.patientid, health_recordid: this.hrWithUserInfo.id, openid: this.hrWithUserInfo.openid, isUpdate: true })
      this.disableBtn = false
      this.$emit('closeDialog')
      this.emptyDialogInfo()
      this.resetForm('patientForm')
    },
    showFamilyRow (row) {
      event.preventDefault()
      this.familyRadio = this.familyMembers.indexOf(row)
      this.selectFamilyMember = row
    },
    showRow (row) {
      event.preventDefault()
      this.radio = this.systemUsers.indexOf(row)
      this.selectUser = row
    },
    handleCancel () {
      this.emptyDialogInfo()
      this.$emit('closeDialog')
    },
    emptyDialogInfo () {
      this.selectUser = {}
      this.selectFamilyMember = {}
      this.emptyFamilyMembers()
      this.emptyPatientForm()
      this.radio = ''
      this.familyRadio = ''
      this.currentStep = 0
      this.patientSearchKey = ''
    },
    handleCancelInner () {
      this.innerVisible = false
      this.selectUser = {}
      this.radio = ''
      this.disableBtn = false
    },
    nextStep (formName) {
      if (this.currentStep == 0) {
        this.fillFamilyInfo()
        this.currentStep += 1
      } else {
        this.handleCreateOfflinePatient(formName)
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    fillFamilyInfo () {
      if (!this.familyMembers.length || !this.selectFamilyMember.name || this.selectFamilyMember.name === '新建病人') {
        this.emptyPatientForm()
        this.selectFamilyMember = {}
        this.useSystemInfo = false
        this.familyRadio = ''
      }
      if (this.selectFamilyMember.name) {
        this.useSystemInfo = true
        let ages = this.selectFamilyMember.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
        let ageYear = parseInt(ages[0]),
          ageMonths = parseInt(ages[1].substring(0, 2)),
          ageDays = parseInt(ages[1].substring(2, 4))
        this.patientForm.id = this.selectFamilyMember.id
        this.patientForm.name = this.selectFamilyMember.name
        this.patientForm.y = ageYear
        this.patientForm.m = ageMonths
        this.patientForm.d = ageDays
        this.patientForm.gender = this.selectFamilyMember.gender
        this.patientForm.phone = this.selectFamilyMember.phone
        this.patientForm.weight = this.selectFamilyMember.weight
        this.patientForm.userid = this.selectFamilyMember.userid
        this.patientForm.address = this.selectFamilyMember.detail
        this.patientForm.openid = this.selectFamilyMember.openid
      } else {
        let numReg = /^[0-9]+.?[0-9]*$/
        if (numReg.test(this.patientSearchKey)) {
          this.patientForm.phone = this.patientSearchKey
        } else {
          this.patientForm.name = this.patientSearchKey
        }
      }
    },
    emptyPatientForm () {
      this.patientForm = {
        name: '',
        phone: '',
        gender: '',
        weight: 0,
        type: '',
        first_subsequent_visit: '',
        sicknessType: '',
        y: 0,//岁
        m: 0,//月
        d: 0, //天
        age: 0,
        address: '',
        userid: ''
      }
      this.selectUser = {}
      this.radio = ''
    },
    handleCreateOfflinePatient (formName) {
      if (!(/^\+?[0-9][0-9]*$/.test(this.patientForm.phone))) {
        this.$message('请输入正确的手机号')
        return
      }
      let age = (this.patientForm.y * 10000 + this.patientForm.m * 100 + this.patientForm.d * 1) / 10000
      if (age == 0) {
        this.$message('年龄必填')
        return
      }
      this.patientForm.age = age
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.disableBtn = true
          if (this.useSystemInfo) {
            this.createHRByUseSystemInfo()
          } else {
            await this.isUserExists(this.patientForm.phone)
            if (this.systemUsers.length > 1) {
              this.selectUser = {}
              this.innerVisible = true
            } else if (this.systemUsers.length === 1) {
              this.selectUser = this.systemUsers[0]
              this.createHRByUseSystemInfo()
            } else {
              this.createNewPatient()
            }
          }
        } else {
          return false
        }
      })
    },
    handlePrevStep () {
      this.currentStep -= 1
    }
  }
}
</script>
<style lang="less">
.myWrap {
  transition: all ease 0.6s;
}
.myWrap-enter-active {
  animation: identifier 0.6s;
  overflow: hidden;
}
.myWrap-leave-active {
  animation: against 0.6s;
  overflow: hidden;
}
@keyframes identifier {
  from {
    height: 0;
  }
  to {
    height: 360px;
  }
}

@keyframes against {
  from {
    height: 360px;
  }
  to {
    height: 0;
  }
}
</style>