<template>
  <div>
    <el-dialog title="管理问诊模板" :visible.sync="show" width="50%" :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
      <el-dialog width="40%" :title="innerDialogTitle" :visible.sync="innerDialogVisible" append-to-body :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
        <div v-if="innerDialogTitle === '新增诊断模板'" class="d-flex align-center mb-3">
          <span>诊断模板名称</span>
          <el-input class="template-category" v-model="targetTemplate.category"></el-input>
        </div>
        <div class="inner-height">
          <el-tag class="mb-2" type="info" v-for="(item,index) in targetTemplate.illnessItems" :key="index" closable :disable-transitions="false" @close="handleClose(item)">
            {{item}}
          </el-tag>
          <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加</el-button>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerDialogVisible = false">关 闭</el-button>
          <el-button type="primary" @click="handleUpdate">保 存</el-button>
        </span>
      </el-dialog>
      <el-table :data="diagnosisTemplates" height="400" style="width: 100%">
        <el-table-column prop="category" label="分类" width="180">
        </el-table-column>
        <el-table-column label="诊断">
          <template slot-scope="scope">
            <div class="cursor-pointer">
              <span class="mr-2" v-for="(item,index) in scope.row.illnessItems" :key="index">{{item}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button @click="showEditCategory(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handleAddTemplate">新增</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      targetTemplate: {},
      innerDialogVisible: false,
      inputVisible: false,
      inputValue: '',
      innerDialogTitle: '',
      dialogClose: false
    }
  },

  computed: {
    ...mapState('index', ['diagnosisTemplates'])
  },

  props: {
    show: Boolean
  },

  methods: {
    ...mapActions('index', ['upsertDiagnosisTemplate']),
    handleUpdate () {
      if (!this.targetTemplate.illnessItems.length || !this.targetTemplate.category) {
        this.$message('诊断内容和名称不能为空')
        return
      }
      this.upsertDiagnosisTemplate(this.targetTemplate)
      this.innerDialogVisible = false
    },
    close () {
      this.$emit('close')
    },
    showEditCategory (row) {
      this.innerDialogTitle = row.category
      this.targetTemplate = JSON.parse(JSON.stringify(row))
      this.innerDialogVisible = true
    },
    handleClose (tag) {
      this.targetTemplate.illnessItems.splice(this.targetTemplate.illnessItems.indexOf(tag), 1);
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm () {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.targetTemplate.illnessItems.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleAddTemplate () {
      this.innerDialogTitle = '新增诊断模板'
      this.targetTemplate = {
        category: '',
        illnessItems: []
      }
      this.innerDialogVisible = true
    }
  }
}
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.inner-height {
  max-height: 200px;
  overflow-y: auto;
}
.template-category {
  width: 16rem;
  margin-left: 1rem;
}
</style>