<template>
  <div class="light-layout">
    <div class="chat-layout">
      <div class="chat-leftbar" :class="[showRightPanel ? 'chat-leftbar-hidden' : '']">
        <div class="tab-content">
          <Chat v-if="userInfo.isDoctor" />
          <Profile />
        </div>
        <ChatMenu v-if="userInfo.isDoctor" />
      </div>
      <div class="chat-rightbar" :class="rightBarCls">
        <div class="chat-detail">
          <ConversationHeader />
          <ConversationContent v-if="!showApproval && !showTreatmentLog && !showPostList && !showBotConfig && !showMedicineList && !showDoctorList && !showSubscribe" />
          <Chatter v-if="activeTabIndex === 0" />
          <PrescriptionApprovalList v-if="showApproval" />
          <TreatmentLog v-if="showTreatmentLog" />
          <PostList v-if="showPostList" />
          <BotQA v-if="showBotConfig" />
          <MedicineList v-if="showMedicineList" />
          <DoctorList v-if="showDoctorList" />
          <Subscribe v-if="showSubscribe" />
        </div>
        <Inquiry />
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/plugins/slick/slick.css"
import "@/assets/plugins/slick/slick-theme.css"
import "@/assets/css/flag-icon.min.css"
import ConversationContent from "@/components/ConversationContent.vue"
import ConversationHeader from "@/components/ConversationHeader.vue"
import Profile from "@/components/Profile.vue"
import ChatMenu from "@/components/ChatMenu.vue"
import Chatter from "@/components/Chatter.vue"
import PrescriptionApprovalList from "@/components/PrescriptionApprovalList.vue"
import TreatmentLog from '@/components/TreatmentLog.vue'
import PostList from '@/components/PostList.vue'
import BotQA from '@/components/BotQA.vue'
import MedicineList from '@/components/MedicineList.vue'
import Chat from "@/components/Chat.vue"
import Inquiry from "@/components/Inquiry.vue"
import Subscribe from "@/components/Subscribe.vue"
import DoctorList from "@/components/DoctorList.vue"
import { mapState } from "vuex"
export default {
  name: "index",

  computed: {
    ...mapState("index", ["showRightBar", "showRightPanel", "userInfo", "showApproval", 'showTreatmentLog', 'showPostList', 'showBotConfig', 'showMedicineList', 'showDoctorList', 'showSubscribe']),
    ...mapState('chat', ['activeTabIndex']),
    rightBarCls () {
      let res = this.showRightPanel ? 'chat-rightbar-response' : ''
      res += this.showRightBar ? ' show' : ''
      return res
    }
  },

  components: {
    ConversationContent,
    Chat,
    Chatter,
    ConversationHeader,
    Profile,
    ChatMenu,
    Inquiry,
    PrescriptionApprovalList,
    TreatmentLog,
    PostList,
    BotQA,
    MedicineList,
    DoctorList,
    Subscribe
  }
};
</script>

<style>
.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
}
.el-tabs--border-card {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.03) !important;
  box-shadow: none !important;
}
</style>
