<template>
  <div class="table-container">
    <div class="source">
      <div class="filter pb-2 d-flex justify-between">
        <div class="d-flex">
          <div class="demo-input-suffix ml-2">
            <el-input placeholder="请输入医生姓名" clearable v-model="name"></el-input>
          </div>
          <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="handleSearchDoctorName">查找</el-button>
        </div>
        <el-button type="success" @click="handleCreateDoctor">新建</el-button>
      </div>
      <div class="table-content">
        <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="doctors">
          <template slot="empty">
            <p v-text="pageNumber===0 ? '暂无数据' : ''"></p>
          </template>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="phone" label="手机号">
          </el-table-column>
          <el-table-column label="权限">
            <template slot-scope="scope">
              <el-tag class="mr-2 mt-1" v-for="(item, index) in scope.row.formatRoles" :key="index">{{item.label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button :type="scope.row.is_active ? 'danger':'success'" @click="updateActiveStatus(scope.row)">{{scope.row.is_active ? '禁用':'激活'}}</el-button>
              <el-button type="primary" @click="showDetailInfo(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
        <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
        <p class="text-center" v-if="!loading && !doctors.length">暂无数据</p>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
      <el-dialog width="46%" :title="innerDialogTitle" :visible.sync="innerDialogVisible" append-to-body>
        <el-transfer v-if="innerDialogType === 'role'" class="mt-2" v-model="currentRoles" :titles="['未分配的权限', '已分配的权限']" :data="totalRoles"></el-transfer>
        <div v-else>
          <div>用户的密码已被重置。请复制并发送密码给该用户。</div>
          <div class="d-flex align-center mt-2">
            <span>密码：</span>
            <span class="mr-2">{{randomPassword}}</span>
            <el-button v-clipboard:copy="randomPassword" v-clipboard:success="onCopySuccess">复制</el-button>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseInnerDialog">关 闭</el-button>
          <el-button v-if="innerDialogType === 'role'" type="primary" @click="confirmAssignmentRole">确 认</el-button>
        </span>
      </el-dialog>
      <el-form ref="form" :model="targetDoctor" label-width="80px">
        <el-form-item label="医生姓名">
          <el-input v-model="targetDoctor.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="targetDoctor.phone"></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-tag class="mr-2 mt-1" v-for="(item, index) in targetDoctor.formatRoles" :key="index">{{item.label}}</el-tag>
          <i class="el-icon-edit cursor-pointer" @click="openAssignmentDoctorRole"></i>
        </el-form-item>
        <el-form-item v-if="this.dialogType === 'edit'" label="禁用医生">
          <input type="checkbox" class="mr-1" :checked="targetDoctor.is_active === 0" @change="handleChangeCheckbox">禁用
        </el-form-item>
        <el-form-item v-if="this.dialogType === 'edit'" label="重置密码">
          <el-button @click="resetPassword">重置</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" :disabled="!isClick" @click="handleUpsertDoctorInfo">{{this.dialogType === 'edit' ? '修 改': '新 建'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'doctor_list',

  data () {
    return {
      name: '',
      pageIndex: 0,
      isClick: true,
      dialogVisible: false,
      innerDialogVisible: false,
      targetDoctor: {},
      currentRoles: [],
      innerDialogType: '',
      innerDialogTitle: '',
      randomPassword: '',
      dialogTitle: '',
      dialogType: '',
    }
  },

  mounted () {
    this.initDoctorList()
  },

  computed: {
    ...mapState('doctor', ['doctors', 'loading', 'pageNumber', 'roleMapping', 'completeCreate']),
    totalRoles () {
      let totalRoles = [], i = 1
      for (let key in this.roleMapping) {
        totalRoles.push({ key: i++, label: this.roleMapping[key], field: key })
      }
      return totalRoles
    },
    disabled () {
      return this.loading || this.noMore
    },
    noMore () {
      return this.pageNumber && this.pageIndex >= this.pageNumber
    },
  },

  methods: {
    ...mapActions('doctor', ['getDoctorList', 'updateUser', 'upsertDoctorInfo']),
    ...mapMutations('doctor', ['switchDoctorLoading', 'emptyDoctorList', 'emptyDoctorPageNumber', 'setCompleteCreate']),
    async load () {
      this.switchDoctorLoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      await this.getDoctorList({ name: this.name, pageIndex: this.pageIndex++ })
    },
    async handleSearchDoctorName () {
      if (this.isClick) {
        this.isClick = false
        this.initDoctorList()
        await this.load()
        this.isClick = true
      }
    },
    async handleUpsertDoctorInfo () {
      this.setCompleteCreate(true)
      if (!this.targetDoctor.role || !this.targetDoctor.role.length) {
        this.$message('必须分配至少一个权限')
        return
      }
      if (!this.targetDoctor.name || !this.targetDoctor.phone) {
        this.$message('请输入姓名、手机号')
      }
      if (this.isClick) {
        this.isClick = false
        if (this.dialogType === 'new')
          this.targetDoctor.password = Number(Math.random().toString().substr(2, 10) + Date.now()).toString(36)
        await this.upsertDoctorInfo(this.targetDoctor)
        if (this.dialogType === 'new' && this.completeCreate) {
          this.dialogVisible = false
          this.initDoctorList()
          await this.load()
          this.currentRoles = []
        }
        this.isClick = true
      }
    },
    async resetPassword () {
      this.$confirm('确定要重置密码吗？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(async () => {
        this.innerDialogType = 'reset'
        this.innerDialogTitle = '重置密码'
        this.randomPassword = Number(Math.random().toString().substr(2, 10) + Date.now()).toString(36)
        await this.updateUser({ id: this.targetDoctor.userid, password: this.randomPassword })
        this.innerDialogVisible = true
      })
    },
    async updateActiveStatus (row) {
      let doctor = {
        id: row.id,
        is_active: row.is_active ? '0' : '1',
        userid: row.userid,
        phone: row.phone
      }
      if (row.is_active == 1) {
        this.$confirm(`确定要禁用"${row.name}"的账号，禁用后医生将无法登陆`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          await this.upsertDoctorInfo(doctor)
        })
      } else {
        await this.upsertDoctorInfo(doctor)
      }
    },
    confirmAssignmentRole () {
      let roles = []
      let formatRoles = []
      this.currentRoles.forEach(item => {
        let targetRole = this.totalRoles.find(role => role.key === item)
        roles.push(targetRole.field)
        formatRoles.push({
          value: targetRole.field,
          label: targetRole.label
        })
      })
      this.targetDoctor.role = roles
      this.targetDoctor.formatRoles = formatRoles
      this.innerDialogVisible = false
    },
    openAssignmentDoctorRole () {
      this.innerDialogType = 'role'
      this.innerDialogTitle = '权限管理'
      if (this.dialogType === 'edit') {
        if (this.targetDoctor.formatRoles) {
          let currentRoles = this.targetDoctor.formatRoles.map(item => {
            return item.label
          })
          this.currentRoles = []
          this.totalRoles.forEach(role => {
            if (currentRoles.includes(role.label)) {
              this.currentRoles.push(role.key)
            }
          })
        }
      }
      this.innerDialogVisible = true
    },
    showDetailInfo (row) {
      this.targetDoctor = JSON.parse(JSON.stringify(row))
      this.dialogTitle = '修改医生信息'
      this.dialogType = 'edit'
      this.dialogVisible = true
    },
    initDoctorList () {
      this.pageIndex = 0
      this.emptyDoctorList()
      this.emptyDoctorPageNumber()
    },
    onCopySuccess () {
      this.$message.success("内容已复制到剪切板！")
    },
    handleCloseInnerDialog () {
      this.randomPassword = ''
      this.innerDialogVisible = false
    },
    handleCreateDoctor () {
      this.targetDoctor = {}
      this.dialogTitle = '新建医生'
      this.dialogVisible = true
      this.dialogType = 'new'
    },
    async handleCloseDialog () {
      this.dialogVisible = false
    },
    handleChangeCheckbox (event) {
      this.targetDoctor.is_active = event.target.checked ? 0 : 1
    }
  },

}
</script>
<style lang="less">
.popover-name {
  border: 0px;
  padding: 0px;
}
.align-center {
  align-items: center;
}
.font-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
</style>