<template>
  <div class="question-container">
    <div v-if="questionItem.data_type === 'checkbox' || questionItem.data_type === 'radio'" class="pl-1">
      <div class="question-header my-2">
        <img class="message-btn" v-if="questionItem.visible_to_patient !== 0 && questionItem.category !=='疫检分诊' && activeClient.openid" src="@/assets/images/send_btn.png" title="给用户发送问题" @click="sendQuestion(questionItem)" />
        {{questionItem.question}}
      </div>
      <template v-if="questionItem.category =='疫检分诊'">
          {{questionItem.answer}}
      </template>
      <template v-else v-for="(option,index) in options">
        <FormElement style="width:10rem" :key="index" formType="IQ" :patientid="activeClient.id" :inquiryid="activeClient.inquiryid" type="radio" :questionid="questionItem.id" :value="questionItem.answer" :label="option">
          <template v-slot:option>{{option}}</template>
        </FormElement>
      </template>
    </div>

    <div v-if="questionItem.data_type === 'number'" class="pl-1">
      <div class="question-header my-2">
        <img class="message-btn" v-if="questionItem.visible_to_patient !== 0 && activeClient.openid" src="@/assets/images/send_btn.png" title="给用户发送问题" @click="sendQuestion(questionItem)" />
        {{questionItem.question}}
      </div>
      <FormElement formType="IQ" :patientid="activeClient.patientid" :inquiryid="activeClient.inquiryid" :value="questionItem.answer" :questionid="questionItem.id" type="number" />
    </div>

    <div v-if="questionItem.data_type === 'file' && questionItem.answer" class="pl-1">
      <div class="question-header my-2">{{questionItem.question}}</div>
      <div class="question-images-container">
        <template v-for="url in questionItem.urls">
          <el-image style="width:120px;height:120px;margin:1rem" :key="url.id" :preview-src-list="questionItem.urls.map(url=>{return url.value})" :src="url.value" fit="cover"></el-image>
        </template>
      </div>
    </div>

    <div v-if="questionItem.data_type === 'text' || questionItem.data_type === 'textarea'" class="pl-1">
      <div class="question-header my-2">
        <img class="message-btn" v-if="questionItem.visible_to_patient !== 0 && activeClient.openid" src="@/assets/images/send_btn.png" title="给用户发送问题" @click="sendQuestion(questionItem)" />
        {{questionItem.question}}
      </div>
      <FormElement formType="IQ" :patientid="activeClient.patientid" :inquiryid="activeClient.inquiryid" :value="questionItem.answer" :questionid="questionItem.id" type="textarea" />
    </div>

    <div v-if="questionItem.data_type === 'picklist'" class="pl-1">
      <div class="question-header my-2">
        <img class="message-btn" v-if="questionItem.visible_to_patient !== 0 && activeClient.openid" src="@/assets/images/send_btn.png" title="给用户发送问题" @click="sendQuestion(questionItem)" />
        {{questionItem.question}}
      </div>
      <FormElement formType="IQ" :patientid="activeClient.patientid" :picklist_options="options.map(option=>{return {label: option, value: option}})" :inquiryid="activeClient.inquiryid" :value="questionItem.answer" :questionid="questionItem.id" type="picklist" />
    </div>

     <div v-if="questionItem.data_type === 'multi-select' " class="pl-1">
      <div class="question-header my-2">
        <img class="message-btn" v-if="questionItem.visible_to_patient !== 0 && questionItem.category !=='疫检分诊' && activeClient.openid" src="@/assets/images/send_btn.png" title="给用户发送问题" @click="sendQuestion(questionItem)" />
        {{questionItem.question}}
      </div>
       <template v-if="questionItem.category =='疫检分诊'">
          {{questionItem.answer}}
      </template>
    </div>

    <template v-if="subQuestions.length">
      <div v-for="question in subQuestions" :key="question.id">
        <InquiryQuestion :question="question" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormElement from '../components/FormElement'

export default {
  name: 'InquiryQuestion',

  data () {
    return {
      answer: '',
      firstLoad: true,
    }
  },

  components: { FormElement },

  props: {
    question: {},
  },

  computed: {
    ...mapGetters("inquiry", ["activeInquiryForm"]),
    ...mapGetters("chat", ["activeClient"]),
    questionItem () {
      return this.question
    },
    subQuestions () {
      let results = []
      if (this.activeInquiryForm instanceof Array) {
        this.activeInquiryForm.forEach(element => {
          if (element.parent_questionid === this.questionItem.id) {
            if (this.firstLoad && this.questionItem.positive_option === element.parent_question_answer && this.questionItem.answer === this.questionItem.positive_option)
              results.push(element)
            else if (!this.firstLoad && this.questionItem.answer === this.questionItem.positive_option)
              results.push(element)
          }
        })
      }
      return results
    },
    options () {
      let options = []
      if (!this.questionItem.options) return
      if (this.questionItem.options) {
        options = this.questionItem.options.split(",");
        options = options.map((element) => {
          return element.replace(/\s+/g, "")
        })
      }
      return options
    },
  },

  mounted () {
    this.initQuestion()
  },

  methods: {
    ...mapActions('chat', ['sendInquiryFormItem']),
    initQuestion () {
      this.answer = this.question.answer
    },
    sendQuestion (questionItem) {
      let payload = Object.assign(questionItem, { inquiryid: this.activeClient.inquiryid })
      this.sendInquiryFormItem(payload)
    }
  }
}
</script>

<style lang='less'>
.sub-container {
  padding-left: 10px;
  .question-images-container {
    display: flex;
    flex-wrap: wrap;
  }
}
.question-container {
  padding: 5px 5px 2px 0px;
}

.message-btn {
  width: 24px;
  cursor: pointer;
}
</style>