<template>
  <el-input v-if="type === 'textarea'" :readonly="readonly" type="textarea" :autosize="{ minRows: 2}" :placeholder="placeholder" v-model="val"></el-input>
  <el-checkbox v-else-if="type === 'checkbox'" v-model="val"></el-checkbox>
  <el-input v-else-if="type === 'number'" :readonly="readonly" :step="step" :min="0" v-model="val"></el-input>
  <el-input v-else-if="type === 'text'" :readonly="readonly" type="text" v-model="val"></el-input>
  <el-date-picker v-else-if="type === 'date'" v-model="val" type="date" placeholder="选择日期时间">
  </el-date-picker>
  <el-date-picker v-else-if="type === 'datetime'" v-model="val" type="datetime" placeholder="选择日期时间">
  </el-date-picker>
  <el-select v-else-if="type==='picklist'" v-model="val" :placeholder="placeholder">
    <el-option v-for="item in picklist_options" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
  <el-radio v-else-if="type === 'radio'" type="radio" v-model="val" :label="label">
    <slot name="option"></slot>
  </el-radio>
  <span v-else-if="type==='label'" @click="changeValue" v-text="value"></span>
  <el-dropdown v-else-if="type==='dropdown'" @command="changeValue" trigger="click" placement="">
    <span class="el-dropdown-link">
      <p v-text="value"></p><i class="el-icon-arrow-down el-icon--right custom-arrow-down"></i>
    </span>
    <el-dropdown-menu slot="dropdown" class="custom-menu">
      <el-dropdown-item v-for="option in dropdown_options" :command="option" :key="option" v-text="option"></el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['formType', 'patientid', 'isField', 'isMedicine', 'isFamilyMember', 'medicineid', 'type', 'placeholder', 'field', 'value', 'careid', 'label', 'picklist_options', 'inquiryid', 'questionid', 'dropdown_options', 'unit_type', 'per_unit', "medicine", "readonly", 'step', 'price'],


  computed: {
    ...mapGetters('inquiry', ['activeHealthRecord']),
    val: {
      get () {
        return this.value
      },
      set (value) {
        let type = 'inquiry/'
        let payload = {}
        if (this.formType === 'HR') {
          if (this.type === 'checkbox') {
            this.$store.commit('inquiry/updatePreferMedicine', { patientid: this.patientid, medicine: this.medicine, value: value })
          }
          type += 'updateActiveHealthRecord'
          payload = { isField: this.isField, isMedicine: this.isMedicine, isFamilyMember: this.isFamilyMember, medicine: {}, familyMember: {}, patientid: this.patientid }
          if (this.isField) {
            payload.field = this.field
            payload.value = value
          } else if (this.isMedicine) {
            payload.medicine.id = this.medicineid
            payload.medicine.field = this.field
            if (this.type === 'number') {
              if (/^(-?\d+)(\.\d+)?$/.test(value) || !value || value.endsWith('.')) {
                payload.medicine.value = value
              } else {
                return
              }
            } else {
              payload.medicine.value = value
            }
          } else if (this.isFamilyMember) {
            if (this.field === 'y' && value > 99) value = 99
            if (this.field === 'm' && value > 12) value = 12
            if (this.field === 'd' && value > 30) value = 20
            payload.field = this.field
            payload.value = value
          }
        } else if (this.formType === 'CR') {
          type += 'updateActiveCareRecords'
          payload = { id: this.careid, patientid: this.patientid }
          payload.field = this.field
          payload.value = value
        } else if (this.formType === 'IQ') {
          type += 'updateInquiryForm'
          payload = {
            userid: this.patientid,
            inquiryid: this.inquiryid,
            answer: value,
            id: this.questionid,
          }
        }
        this.$store.commit(type, payload)
        if (type === 'inquiry/updateActiveHealthRecord') {
          this.$store.commit('chat/changeClientSaveStatus', {
            id: this.patientid,
            hrid: this.activeHealthRecord.id,
            result: true
          })
        }
      }
    }
  },

  methods: {
    ...mapMutations('chat', ['changeClientSaveStatus']),
    changeValue (e) {
      let type = 'inquiry/'
      let payload = {}
      if (this.formType === 'HR') {
        type += 'updateActiveHealthRecord'
        payload = { isField: this.isField, isMedicine: this.isMedicine, medicine: {}, patientid: this.patientid }
        if (this.isField) {
          payload.field = this.field
          payload.value = this.value
        } else if (this.isMedicine) {
          if (this.type === 'label') {
            payload.medicine.id = this.medicineid
            payload.medicine.field = this.field
            let value = ''
            this.dropdown_options.some(option => {
              if (option !== this.value) {
                value = option
                return true
              }
            })
            payload.medicine.value = value
          }
          else if (this.type === 'dropdown') {
            payload.medicine.id = this.medicineid
            payload.medicine.field = this.field
            payload.medicine.value = e
          }
        }
      }
      this.$store.commit(type, payload)
      if (type === 'inquiry/updateActiveHealthRecord') {
        this.$store.commit('chat/changeClientSaveStatus', {
          id: this.patientid,
          hrid: this.activeHealthRecord.id,
          result: true
        })
      }
    }
  }
}
</script>
<style lang="less">
.el-dropdown-link {
  display: flex !important;
  p {
    text-align: right;
    width: 1rem;
  }
}
.custom-menu {
  margin-top: -12px !important;
}
.custom-arrow-down {
  margin-top: 2px;
}
</style>