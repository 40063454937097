<template>
  <div class="table-container">
    <div class="source">
      <div class="filter pb-2">
        <el-date-picker v-model="startDate" value-format="yyyy-MM-dd HH:mm:ss" align="right" type="date" placeholder="开始日期" :picker-options="pickerOptions">
        </el-date-picker>
        <el-date-picker class="mx-3" v-model="endDate" align="right" type="date" placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="handleFilterDate">查找</el-button>
        <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="exportList">导出</el-button>
      </div>
      <div class="table-content">
        <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="treatmentLogs">
          <template slot="empty">
            <p v-text="pageNumber===0 ? '暂无数据' : ''"></p>
          </template>
          <el-table-column prop="inquiry_date" sortable label="就诊日期" width="100">
          </el-table-column>
          <el-table-column sortable label="姓名" width="100">
            <template slot-scope="scope">
              <el-popover placement="right" width="560" trigger="click">
                <div class="info-container">
                  <div class="info-title">
                    <div>{{activeTreatmentLogDetailInfo.name}}</div>
                    <el-tag v-if="activeTreatmentLogDetailInfo.last_caring_status" type="primary">{{activeTreatmentLogDetailInfo.last_caring_status}}</el-tag>
                  </div>
                  <div class="row my-2" v-for="(section,index) in activeTreatmentLogDetailInfo.sections" :key="index">
                    <div class="col-md-2 font-bold">{{section.field}} : </div>
                    <div class="col-md-10">{{section.value}}</div>
                  </div>
                </div>
                <el-button class="popover-name" slot="reference" @click="getTreatmentLogDetail({health_recordid:scope.row.health_recordid, inquiryid:scope.row.inquiryid})">{{scope.row.name}}</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别" width="60">
          </el-table-column>
          <el-table-column prop="ageText" label="年龄" width="60">
          </el-table-column>
          <el-table-column prop="onset_date" sortable label="发病日期" width="120">
          </el-table-column>
          <el-table-column prop="address" label="住址">
          </el-table-column>
          <el-table-column prop="phone" label="联系电话" width="150">
          </el-table-column>
          <el-table-column prop="clinical_diagnose" label="临床诊断(初步)">
          </el-table-column>
          <el-table-column prop="first_subsequent_visit" label="初诊/复诊" width="100">
          </el-table-column>
          <el-table-column label="是否为传染病" width="120">
            <template slot-scope="scope">
              <div class="d-flex align-center" v-if="scope.row.is_infectious == '是'">
                是<i class="ml-1 el-icon-link" @click="gotoInfectiousRecortCard(scope.row.health_recordid)"></i>
              </div>
              <span v-else>否</span>
            </template>
          </el-table-column>
        </el-table>
        <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
        <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
        <p class="text-center" v-if="!loading && !treatmentLogs.length">暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  name: 'treatment_log',

  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      startDate: '',
      endDate: '',
      rangeDate: '',
      pageIndex: 0,
      isClick: true
    }
  },

  mounted () {
    this.initTreatmentLogs()
  },

  methods: {
    ...mapActions('inquiry', ['getLogByHospitalId', 'getTreatmentLogDetail', 'exportData']),
    ...mapMutations('inquiry', ['switchLogLoading', 'emptyTreatmentLog', 'emptyLogPageNumber']),
    async load () {
      this.switchLogLoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      let startDate = this.startDate ? moment(this.startDate).format('yyyy-MM-DD') : null
      let endDate = this.endDate ? moment(this.endDate).format('yyyy-MM-DD') : null
      await this.getLogByHospitalId({ startDate: startDate, endDate: endDate, pageIndex: this.pageIndex++ })
    },
    async handleFilterDate () {
      if (this.isClick) {
        this.isClick = false
        this.initTreatmentLogs()
        await this.load()
        this.isClick = true
      }
    },
    initTreatmentLogs () {
      this.pageIndex = 0
      this.emptyTreatmentLog()
      this.emptyLogPageNumber()
    },
    gotoInfectiousRecortCard (health_recordid) {
      let newPage = this.$router.resolve({ path: `/infectiousReportCard/${health_recordid}` })
      window.open(newPage.href, '_blank')
    },
    async exportList () {
      await this.exportData({ startDate: this.startDate, endDate: this.endDate })
      if (!this.downloadUrl.err) {
        let a = document.createElement('a')
        a.href = this.downloadUrl
        a.click()
      }
    }
  },

  computed: {
    ...mapState('inquiry', ['treatmentLogs', 'loading', 'pageNumber', 'treatmentDetailInfo', 'downloadUrl']),
    ...mapGetters('inquiry', ['activeTreatmentLogDetailInfo']),
    disabled () {
      return this.loading || this.noMore
    },
    noMore () {
      return this.pageNumber && this.pageIndex >= this.pageNumber
    },
  }
}
</script>
<style lang="less" scoped>
.popover-name {
  border: 0px;
  padding: 0px;
}
.align-center {
  align-items: center;
  i {
    color: blue;
    cursor: pointer;
  }
}
.info-container {
  max-height: 400px;
  overflow-y: auto;
  .info-title {
    background: #f2f6fc;
    padding: 3px 2px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.font-bold {
  font-weight: bold;
}
</style>