<template>
  <div class="chat-bottom" :class="[activeClient ? '' : 'chat-hidden']">
    <div class="chat-messagebar">
      <form v-if="activeClient && !showDashboard && !showApproval && !showTreatmentLog && !showBotConfig && !showPostList && !showMedicineList&& !showSubscribe && activeClient.openid && activeName=='chat'">
        <div class="input-group">
          <div class="input-group-prepend" style="margin-top: 5px;">
            <el-popover popper-class="tranparent" placement="top-start" trigger="click" @hide="isRecording = false" v-model="record">
              <audio-recorder v-if="isRecording" :time="60" :after-sending="recordCallback" />
              <a href="#" slot="reference" @click="isRecording = true"><i class="feather icon-mic"></i></a>
            </el-popover>
          </div>
          <div class="input-group-prepend" style="margin-top: 5px;">
            <el-popover placement="top-start" width="300" trigger="click">
              <div style="max-height: 300px; overflow-y: auto;">
                <span class="emoji" v-for="(emoji, index) in emojiList" :key="index" @click="addEmoji(emoji)">
                  {{emoji}}
                </span>
              </div>
              <a href="#" slot="reference" title="发送表情" style="margin-left: 10px;"><i class="fa fa-smile-o"></i></a>
            </el-popover>
          </div>
          <textarea class="form-control" @keydown="onKeyDown" placeholder="请在这里输入聊天内容..." v-model="message"></textarea>
          <div class="input-group-append">
            <div class="file-upload" title="发送文件">
              <div class="file-upload-text"><a href="#" class="mr-3"><i class="feather icon-paperclip"></i></a></div>
              <input @change="getFile($event)" name="upfile" class="file-upload-input" type="file" accept=".jpg, .png, .jpeg, .gif">
            </div>
            <a href="#" @click="pushSendMessageToClient" title="发送消息"><i class="feather icon-send"></i></a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import AudioRecorder from '@/components/Recorder.vue'
export default {

  data () {
    return {
      emojis: '😁-😂-😃-😄-👿-😉-😊-😊-☺️-😌-😍-😏-😒-😓-😔-😖-😘-😚-😜-😝-😞-😠-😡-😢-😣-😥-😨-😪-😭-😰-😱-😲-😳-😷-🙃-😋-😗-😛-🤑-🤓-😎-🤗-🙄-🤔-😫-😤-🤐-🤒-😴-😀-😆-😅-😇-🙂-😙-😟-😕-😟-☹️-😫-😶-😐-😑-😯-😦-😧-😮-😵-😬-🤕-😈-👻-🥺-🥴-🤣-🥰-🤩-🤤-🤫-🤪-🧐-🤬-🤧-🤭-🤠-🤯-🤥-🥳-🤨-🤢-🤡-🤮-🥵-🥶-💩-🌚-🌝-💊-😸-😻-😼-😽-😾-😿-😹-🐵-🙈-🙉-🙊-🐒-🙋-🙏-🛫-🚇-🚃-🚌-🎂-🍄-🍅-🍆-🍇-🍈-🍉-🍑-🍒-🍓-🐔-🐶-🐷-👦-👧-👱-👩-👰-👨-👲-👳-💃-💄-💅-💆-💇-🌹-💑-💓-💘-🚲',
      isRecording: false,
      record: false
    }
  },
  components: { AudioRecorder },

  computed: {
    ...mapState('index', ['showDashboard', 'showApproval', 'showTreatmentLog', 'showBotConfig', 'showPostList', 'showMedicineList', 'showSubscribe']),
    ...mapState('chat', ['activeTabIndex', 'activeName']),
    ...mapGetters('chat', ['activeClient']),
    emojiList () {
      return this.emojis.split('-')
    },
    message: {
      get () {
        return this.activeClient.unSendMessage
      },
      set (value) {
        this.setUnSendMessage(value)
      }
    }
  },

  mounted () {
  },

  methods: {
    ...mapMutations('chat', ['setUnSendMessage']),
    ...mapActions('chat', ['pushSendMessageToClient', 'uploadFile', 'uploadAudio']),
    onKeyDown (event) {
      if (event.keyCode == 13 && !event.shiftKey) {
        event.preventDefault()
        this.pushSendMessageToClient()
      }
    },
    async getFile (event) {
      if (event.target.files && event.target.files.length > 0) {
        let file = event.target.files[0]
        let imgName = file.name;
        let idx = imgName.lastIndexOf(".")
        if (idx != -1) {
          let ext = imgName.substr(idx + 1).toUpperCase()
          ext = ext.toLowerCase()
          if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'gif') {
            //upload file
            await this.uploadFile({
              file: file,
              fileType: ext
            })
          }
        }
      }
    },
    addEmoji (emoji) {
      this.setUnSendMessage(this.message ? this.message + emoji + ' ' : emoji + ' ')
    },
    async recordCallback (msg) {
      console.debug('Event: ', msg)
      await this.uploadAudio({
        file: msg.blob,
        duration: msg.duration
      })
      this.record = false
    },
  }
}
</script>

<style scoped lang="less">
.form-control {
  font-size: 16px;
  margin-right: 10px;
  height: auto;
  min-height: 38px;
  resize: none;
  background-color: transparent !important;
}
.chat-bottom {
  .file-upload {
    position: relative;
    overflow: hidden;
    margin-top: 5px;
  }

  .file-upload-input {
    background-color: transparent;
    position: absolute;
    width: 999px;
    height: 999px;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}
.chat-hidden {
  border: 0;
  padding: 0;
}
.emoji {
  font-size: 25px;
  cursor: pointer;
}
</style>
