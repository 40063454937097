<template>
  <div class="chat-head" v-if="sideBarActive || hasActiveClient || showDashboard ">
    <div class="row align-items-center space-between">
      <div class="ml-1 d-flex align-center">
        <ul class="list-unstyled mb-0">
          <li class="media">
            <div v-if="showChatContent" class="d-flex ">
              <div v-if="activeClient.openid" class="user-status" :class="[activeClient.online ? 'online' : 'offline']"></div>
              <el-image v-if="activeClient.openid" class="align-self-center rounded-circle avatar" :src="activeClient.user_avatar_url" :preview-src-list="[activeClient.user_avatar_url]" alt="avatar" fit="cover"></el-image>
              <div class="media-body">
                <h5>{{ activeClient.username }}</h5>
                <div class="d-flex">
                  <p class="mb-0 family-member">
                    <el-tag size="mini">{{ activeClient.fm_name }}</el-tag>
                    <el-tag size="mini" :type="activeHealthRecord.gender ? 'success' : 'danger'">
                      <i :class="[activeHealthRecord.gender ? 'el-icon-male' : 'el-icon-female']"></i>
                      {{ activeHealthRecord.gender ? '男' : '女' }}
                    </el-tag>
                    <el-tag size="mini" type="warning">{{ activeClient.fm_ageText }}</el-tag>
                  </p>
                  <div class="ml-2" style="color:#888" v-if="activeTodaySubscribe.id">( {{activeTodaySubscribe.convertStatus!=='预约中'?'预约':''}}{{activeTodaySubscribe.convertStatus}} - {{activeTodaySubscribe.subscribe_time}})</div>
                </div>
              </div>
            </div>
            <div v-if="sideBarActive || showDashboard" class="d-flex">
              <el-avatar shape="square" :size="40" :src="userInfo.hospital_avatar_url"></el-avatar>
              <h4 class="ml-1">{{ userInfo.hospital_name }}</h4>
            </div>
          </li>
        </ul>
      </div>
      <template v-if="showChatContent">
        <div>
          <el-select class="health_record-dropdown" value-key="id" :value="activeHealthRecord" placeholder="请选择" @change="handleChangeHR">
            <el-option v-for="item in activeClientHealthRecords" :key="item.id" :label="item.display_text" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex">
          <!-- <ul class="list-inline ml-1 mb-0">
            <li class="list-inline-item">
              <div class="dropdown">
                <a href="#" class="" id="chatDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click.stop="openDropdownMenu">
                  <i class="feather icon-more-vertical-"></i>
                </a>
                <div v-if="showDropdownMenu" class="dropdown-menu dropdown-menu-right show" aria-labelledby="chatDropdown" style="position: absolute; will-change: transformtop: 0pxleft: 0pxtransform: translate3d(-140px, 28px, 0px)">
                  <a class="dropdown-item font-14" href="#" id="view-user-info" @select="handleOpenRightPanel">分屏显示</a>
                  <a class="dropdown-item font-14" href="#" id="view-user-info" @click.stop="handleMergeUser">合并病人信息</a>
                  <a class="dropdown-item font-14" href="#" id="view-user-info" @click.stop="handleDeleteHealthRecord">删除病历</a>
                </div>
              </div>
            </li>
            <li class="list-inline-item">
              <a href="#" class="back-arrow" @click="hideRightBar"><i class="feather icon-x"></i></a>
            </li>
          </ul> -->
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
                <i class="feather icon-more-vertical-" style="font-size: 24px;margin-right: 12px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="splitScreen" @click="handleOpenRightPanel">分屏显示</el-dropdown-item>
              <el-dropdown-item command="mergeUser" @click="handleMergeUser">合并病人信息</el-dropdown-item>
              <el-dropdown-item command="deleteHealthRecord" @click="handleDeleteHealthRecord">删除病历</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </div>
    <el-dialog title="合并病人信息" :visible.sync="mergeUserDialog" width="50%">
      <div class="d-flex mb-2">
        <el-autocomplete class="search-width" popper-class="my-autocomplete" v-model="searchKey" :fetch-suggestions="querySearch" placeholder="输入姓名或者电话号码" @select="handleChoiceMergeUser">
          <i class="el-icon-search el-input__icon" slot="suffix">
          </i>
        </el-autocomplete>
      </div>
      <el-table height="300" ref="multipleTable" :data="mergeUsers" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" :selectable="checkSelectable" width="55">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column label="性别" width="60">
          <template slot-scope="scope">
            <span>{{scope.row.gender?'男':'女'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ageText" label="年龄" width="180">
        </el-table-column>
        <el-table-column prop="phone" label="手机号">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseMergeDialog">关闭</el-button>
        <el-button type="primary" @click="handleConfirmMerge">合并</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// import VideoChatRoom from '@/components/VideoChatRoom.vue'

export default {
  name: 'conversation_header',

  //   components: { VideoChatRoom },

  data () {
    return {
      show: false,
      value: '',
      mergeUserDialog: false,
      choiceUser: '',
      searchKey: '',
      isClick: false,
      refreshChat: false,
      multipleSelection: []
    }
  },

  computed: {
    ...mapState('index', ['status', 'userInfo', 'showDropdownMenu', 'showApproval', 'showTreatmentLog', 'roomVisible', 'showDashboard', 'showPostList', 'showBotConfig', 'showSubscribe','showMedicineList', 'sideBarOperationList']),
    ...mapState('chat', ['activeClientHealthRecords', 'hrWithUserInfo', 'mergeUsers', 'familyMembers', 'allClients']),
    ...mapGetters('chat', ['hasActiveClient', 'activeClient', 'showChatContent', 'sideBarActive']),
    ...mapGetters('inquiry', ['activeHealthRecord', 'activeTodaySubscribe']),
  },

  created () {
    window.addEventListener('click', this.hideDropdownMenu, true)
  },

  beforeDestroy () {
    window.removeEventListener('click', this.hideDropdownMenu, true)
  },

  async mounted () {
    await this.getUserInfo()
    await this.socketStart()
  },

  methods: {
    ...mapMutations('index', ['openRightPanel', 'openDropdownMenu', 'hideDropdownMenu', 'hideRightBar', 'openRoom']),
    ...mapMutations('chat', ['setActiveName', 'updateMergeUsers', 'setUpdateClient']),
    ...mapMutations('inquiry', ['setSecondHR']),
    ...mapActions('index', ['getUserInfo']),
    ...mapActions('socket', ['socketStart']),
    ...mapActions('chat', ['switchActiveUserHr', 'createHealthRecord', 'getMergeUsers', 'confirmMergeUser', 'getFamilyMembers', 'switchUsersByStatus', 'deleteHealthRecord']),
    handleChangeHR (event) {
      if (this.activeHealthRecord.id) {
        let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
        if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
          this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
            confirmButtonText: '离开',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.switchActiveUserHr(Object.assign(event, { secondType: 'main' }))
          }).catch(() => {
          })
        } else {
          this.switchActiveUserHr(Object.assign(event, { secondType: 'main' }))
        }
      } else
        this.switchActiveUserHr(Object.assign(event, { secondType: 'main' }))
    },
    handleOpenRightPanel () {
      this.openRightPanel()
      this.setSecondHR(this.activeHealthRecord)
    },
    handleCommand(e) {
      if (e === 'splitScreen') {
        this.handleOpenRightPanel()
      } else if (e === 'mergeUser') {
        this.handleMergeUser()
      } else if (e === 'deleteHealthRecord') {
        this.handleDeleteHealthRecord()
      }
    },
    async handleMergeUser () {
      await this.getMergeUsers(this.activeHealthRecord.family_memberid)
      this.mergeUserDialog = true
      this.$nextTick(() => {
        this.mergeUsers.forEach(fm => {
          if (fm.userid === this.activeHealthRecord.patientid) {
            this.$refs.multipleTable.toggleRowSelection(fm)
          }
        })
      })
    },
    async querySearch (queryString, cb) {
      if (!queryString) {
        cb([])
      } else {
        await this.getFamilyMembers({ queryString: queryString, type: 'name' })
        cb(this.familyMembers.filter(item => item.userid !== this.activeHealthRecord.patientid))
      }
    },
    async handleConfirmMerge () {
      if (this.multipleSelection.length === 1) {
        this.$message('只有一个用户不用进行合并')
        return
      }
      this.$confirm("此操作将会把病历合并至当前账户，并将勾选的其他用户删除", "警告⚠️", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'error'
      }).then(async () => {
        this.refreshChat = true
        await this.confirmMergeUser({ id: this.activeHealthRecord.patientid, openid: this.activeHealthRecord.openid, checkedFamilyMembers: this.multipleSelection, family_memberid: this.activeHealthRecord.family_memberid })
        this.$nextTick(() => {
          this.mergeUsers.forEach(fm => {
            if (fm.userid === this.activeHealthRecord.patientid) {
              this.$refs.multipleTable.toggleRowSelection(fm)
            }
          })
        })
      })
    },
    async handleCloseMergeDialog () {
      if (this.refreshChat) {
        this.refreshChat = false
        await this.switchUsersByStatus({ is_refresh: true })
      }
      this.mergeUserDialog = false
    },
    handleDeleteHealthRecord () {
      console.log('handleDeleteHealthRecord')
      this.$confirm("病历删除将不能恢复，确定要删除吗？", "警告⚠️", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: 'custom-confirm'
      }).then(async () => {
        await this.deleteHealthRecord(this.activeHealthRecord.id)
        await this.switchUsersByStatus({ is_refresh: true })
        this.setUpdateClient(true)
      }).catch(() => {
      })
    },
    handleChoiceMergeUser (element) {
      this.updateMergeUsers({ value: element, type: 'add' })
      this.$refs.multipleTable.toggleRowSelection(element)
      this.searchKey = ''
    },
    checkSelectable (row) {
      return row.userid !== this.activeHealthRecord.patientid
    },
    handleRemoveMergeUser (id) {
      this.updateMergeUsers({ value: id, type: 'delete' })
    },
    handleSelectionChange (value) {
      this.multipleSelection = value
    },
  }
}
</script>

<style lang="less">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.chat-head {
  .online {
    background-color: #19a299 !important;
  }
  .offline {
    background-color: #9e9e9e !important;
  }
  .connect {
    background-color: #2196f3 !important;
  }
  .avatar {
    height: 40px !important;
    width: 40px !important;
  }
  .media h4 {
    top: 0.6rem;
    position: relative;
  }
  .family-member {
    .el-tag--mini {
      margin-right: 5px;
    }
  }
  .el-dialog__body {
    padding: 0px 20px;
  }
  .health_record-dropdown {
    width: 16rem;
  }
  .space-between {
    justify-content: space-between;
  }
  .el-radio {
    margin-right: 0px;
  }
  .user-close-icon {
    cursor: pointer;
    color: red;
  }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    label {
      margin-bottom: 0px;
    }
    .search-width {
      width: 14rem;
    }
  }
}
.custom-confirm {
  .el-button--primary {
    background: #f56c6c;
  }
}
</style>