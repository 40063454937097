<template>
  <div class="chat-left-headbar">
    <div class="row align-items-center">
      <div class="col-9">
        <ul class="list-unstyled mb-0">
          <li class="media">
            <img class="align-self-center mr-2" v-if="activeTab === 'chat'" src="@/assets/images/logo.svg" alt="Generic placeholder image" />
            <img class="align-self-center mr-2" v-if="activeTab === 'profile'" src="@/assets/images/mine.png" alt="Generic placeholder image" />
            <div class="media-body">
              <h5 class="mb-0 mt-2">{{ activeTab === 'chat' ? '在线问诊' : activeTab === 'profile' ? '关于我' : '' }}</h5>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-3">
        <ul class="list-unstyled mb-0">
          <li class="media">
            <div class="d-flex">
              <div class="user-status" :class="status.class"></div>
              <el-popover placement="top-start" trigger="hover">
                <el-popover placement="top" v-model="visible">
                  <p>确认退出</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">点错了</el-button>
                    <el-button type="primary" size="mini" @click="logout">确定</el-button>
                  </div>
                  <el-button class="logout" slot="reference"><i class="feather icon-log-out"></i> 退出登录</el-button>
                </el-popover>
                <el-image class="align-self-center rounded-circle mini-avatar" trigger="click" slot="reference" :src="userInfo.doctor_avatar_url" alt="avatar" fit="cover"></el-image>
              </el-popover>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'head_bar',
  data () {
    return {
      visible: false,
    };
  },

  computed: {
    ...mapState('index', ['activeTab', 'status', 'userInfo']),
  },

  methods: {
    ...mapMutations('login', ['logout'])
  }
}
</script>

<style lang="less" scoped>
.logout {
  border: none;
  padding: 0;
  font-size: 14px;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }
}
.online {
  background-color: #19a299 !important;
}
.offline {
  background-color: #9e9e9e !important;
}
.connect {
  background-color: #2196f3 !important;
}
.user-status {
  position: relative;
  left: 12px;
  margin-left: -12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  z-index: 1000;
}
.mini-avatar {
  height: 37px !important;
  width: 37px !important;
}
.col-3 {
  .media {
    align-items: flex-end;

    h4 {
      top: 0.6rem;
      position: relative;
    }
  }
}
</style>