<template>
  <div class="inquiry-form-container">
    <template v-if="activeInquiryForm && !activeInquiryForm.length">
      <div class="text-info text-center" style="font-weight: bold;font-size:1.2em;"><br /><br /><br />暂无问诊表单</div>
    </template>
    <template v-else>
      <el-card class="box-card">
        <div class="inquiry-header pl-2">用户信息</div>
        <div class="inquiry-content">
          <el-form ref="form">
            <el-row>
              <el-col :span="6">
                <el-form-item label="姓名">{{activeHealthRecord.name}}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="性别">{{activeHealthRecord.gender === 0 ? '女':'男'}}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="年龄">{{activeHealthRecord.ageText}}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="体重">{{ activeHealthRecord.weight ? activeHealthRecord.weight + ' kg' : '' }}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="患者类型">{{currentInquiry.template_child_adult}}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="门诊类型">{{currentInquiry.template_first_subsequent_visit}}</el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="疾病类型">{{currentInquiry.template_sickness}}</el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="inquiry-header pl-2">临床表现及主诉</div>
        <div class="inquiry-content">
          <template v-for="item in activeInquiryForm">
            <InquiryQuestion v-if="(item.category ==='临床表现及主诉' || item.category ==='基本信息') && !item.parent_questionid && item.is_updatable!==0" :key="item.id" :question="item" />
          </template>
        </div>
        <template v-if="activeInquiryForm.sickness === '其他'">
          <div class="inquiry-header pl-2">个人史</div>
          <div class="inquiry-content">
            <template v-for="item in activeInquiryForm">
              <InquiryQuestion v-if="(item.category ==='个人史') && !item.parent_questionid" :key="item.id" :question="item" />
            </template>
          </div>

          <div class="inquiry-header pl-2">既往史</div>
          <div class="inquiry-content">
            <template v-for="item in activeInquiryForm">
              <InquiryQuestion v-if="item.category === '既往史' && !item.parent_questionid" :key="item.id" :question="item" />
            </template>
          </div>

          <div class="inquiry-header pl-2">临床诊断</div>
          <div class="inquiry-content">
            <template v-for="item in activeInquiryForm">
              <InquiryQuestion v-if="item.category === '临床诊断' && !item.parent_questionid" :key="item.id" :question="item" />
            </template>
          </div>
        </template>
        <template v-if="activeInquiryForm.epidemic_screening">
          <div class="inquiry-header pl-2">疫检分诊</div>
          <div class="inquiry-content">
            <template v-for="item in activeInquiryForm">
              <InquiryQuestion v-if="item.category === '疫检分诊'" :key="item.id" :question="item" />
            </template>
          </div>
        </template>
        <el-button v-if="activeHealthRecord.status==='Inquiring'" type="primary" @click="handleUpdate">保存</el-button>
        <el-button v-if="activeHealthRecord.status==='Inquiring' && activeClient.openid" type="primary" @click="handleSend">发送</el-button>
      </el-card>
    </template>

  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import InquiryQuestion from "@/components/InquiryQuestion.vue";
export default {
  data () {
    return {
      allQuestions: [],
    }
  },
  computed: {
    ...mapState("inquiry", ["inquiryForms", "currentInquiry"]),
    ...mapGetters("inquiry", ["activeInquiryForm"]),
    ...mapGetters("chat", ["activeClient"]),
    ...mapGetters('inquiry', ['activeHealthRecord'])
  },
  components: {
    InquiryQuestion
  },
  methods: {
    ...mapActions("inquiry", ["upsertInquiryItem"]),
    ...mapActions("chat", ['sendInquiryForm']),
    handleUpdate () {
      this.upsertInquiryItem({ userid: this.activeClient.id, inquiryid: this.activeClient.inquiryid })
    },
    async handleSend () {
      this.sendInquiryForm({
        userid: this.activeClient.id,
        inquiryid: this.activeClient.inquiryid
      })
    }
  }
}
</script>
<style lang="less">
.inquiry-form-container {
  padding: 30px;
  margin: 0;
  max-width: 100%;
  height: calc(100vh - 140px);
  overflow: auto;
  .inquiry-header {
    padding: 8px 4px;
    background: #ececec;
    color: #6b6b6b;
    font-weight: bold;
  }
  .inquiry-content {
    padding: 4px;
  }
}
</style>
