import { render, staticRenderFns } from "./InquiryQuestion.vue?vue&type=template&id=d5cfe72a"
import script from "./InquiryQuestion.vue?vue&type=script&lang=js"
export * from "./InquiryQuestion.vue?vue&type=script&lang=js"
import style0 from "./InquiryQuestion.vue?vue&type=style&index=0&id=d5cfe72a&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports