<template>
  <div class="post-container">
    <h3 class="title mb-3">{{edit?'编辑文章':'新建文章'}}</h3>

    <el-form ref="form" label-width="80px">
      <el-form-item label="文章标题">
        <el-input :readonly="readonly" :value="clonePost.title" @input="handleChange($event, 'title')"></el-input>
      </el-form-item>
      <el-form-item label="文章封面">
        <el-image v-if="formatPostDetail.cover_image" class="cover-image" :src="formatPostDetail.cover_image"></el-image>
        <el-upload v-if="!readonly" action="#" class="upload-demo" :http-request="uploadImage" :show-file-list="false">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="分类">
        <div class="d-flex post-type">
          <el-radio-group :value="clonePost.type" @input="handleChange($event, 'type')" size="medium">
            <el-radio v-for="(item,index) in types" :key="index" :label="item" border>{{item}}</el-radio>
          </el-radio-group>
          <i v-if="!showAddType" @click="showAddType = true" class="el-icon-plus type-icon"></i>
          <div class="d-flex add-type ml-4" v-else>
            <el-input v-model="type" placeholder="输入分类"></el-input>
            <i @click="handleAddType" class="el-icon-check mx-1 green"></i>
            <i @click="showAddType = false" class="el-icon-close red"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="关键字">
        <div class="d-flex key-word">
          <el-checkbox-group v-if="words" v-model="formatPostDetail.keyWordCheckList">
            <el-checkbox v-for="(item, index) in words" :key="index" :label="item"></el-checkbox>
          </el-checkbox-group>
          <i v-if="!showAddKeyWord" @click="showAddKeyWord = true" class="el-icon-plus type-icon"></i>
          <div class="d-flex add-type" v-else>
            <el-input class="ml-3" v-model="word" placeholder="输入关键词"></el-input>
            <i @click="handleAddKeyWord" class="el-icon-check mx-1 green"></i>
            <i @click="showAddKeyWord = false" class="el-icon-close red"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="发稿人" v-if="doctors">
        <el-select v-model="formatPostDetail.userid" placeholder="请选择">
          <el-option v-for="item in doctors" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="example">
        <el-upload class="avatar-uploader" action="#" :http-request="uploadContentImage" :on-success="uploadSuccess" :accept="'image/*'" :show-file-list="false">
        </el-upload>
        <quill-editor class="editor" v-model="formatPostDetail.content" ref="myQuillEditor" :options="editorOption" />
      </div>
      <el-form-item v-if="!readonly" class="mt-2">
        <el-button type="primary" @click="handleSave">{{edit ? '更新' : '保存'}}</el-button>
        <el-button :type="post.state==='Published' ? 'info' : 'success'" @click="handleUpdate">{{post.state==='Published'?"存为草稿":'发布'}}</el-button>
        <el-button type="danger" @click="handleDelete">{{edit ? '删除' : '丢弃'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import * as Quill from 'quill'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { ImageDrop } from 'quill-image-drop-module'
import "../assets/styles/fontstyle/size-set.css";
Quill.register('modules/imageDrop', ImageDrop)
import imageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', imageResize)

const keyWords = ['发热', '咳嗽', '腹泻', '感冒', '喘息']
let fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '30px', '32px', '36px', '40px', false];
Quill.register(fontSizeStyle, true)
export default {
  name: 'post_detail',
  data () {
    return {
      editorOption: {
        placeholder: '请输入正文......',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
              [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],     //列表
              [{ 'script': 'sub' }, { 'script': 'super' }],   // 上下标
              [{ 'indent': '-1' }, { 'indent': '+1' }],     // 缩进
              [{ 'direction': 'rtl' }],             // 文本方向
              [{ 'size': fontSizeStyle.whitelist }], // 字体大小
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              [{ 'font': [] }],     //字体
              [{ 'align': [] }],    //对齐方式
              ['clean'],    //清除字体样式
              ['image', 'video']    //上传图片、上传视频
            ],
            handlers: {
              'image': function (value) {
                if (value) {
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            },
            history: {
              delay: 1000,
              maxStack: 50,
              userOnly: false
            },
            imageDrop: true,
            imageResize: {
              modules: ['Resize', 'DisplaySize', 'Toolbar']
            }
          }
        },
        theme: 'snow',
      },
      customTypes: [],
      type: '',
      showAddType: false,
      showAddKeyWord: false,
      customKeyWords: [],
      word: '',
      userid: '',
    }
  },

  components: {
    quillEditor
  },

  props: {
    edit: Boolean
  },

  mounted () {
    // this.getPostDetail()
  },

  computed: {
    ...mapState('index', ['userInfo']),
    ...mapState('post', ['postid', 'post', 'postTypes', 'uploadRes', 'keyWordCheckList', 'doctors']),
    ...mapGetters('post', ['formatPostDetail']),
    types () {
      let types = []
      this.postTypes.forEach(type => {
        if (type.type !== '显示全部')
          types.push(type.type)
      })
      if (this.customTypes.length) {
        this.customTypes.forEach(type => {
          types.push(type)
        })
      }
      return types
    },
    words () {
      let words = []
      keyWords.forEach(word => {
        words.push(word)
      })
      if (this.keyWordCheckList.length) {
        let tempList = this.keyWordCheckList.filter(item =>
          !keyWords.includes(item)
        )
        words = words.concat(tempList)
      }
      if (this.customKeyWords.length) {
        this.customKeyWords.forEach(word => {
          words.push(word)
        })
      }
      return words
    },
    clonePost () {
      return JSON.parse(JSON.stringify(this.formatPostDetail))
    },
    readonly () {
      return (this.formatPostDetail.hospitalid !== this.userInfo.hospitalid) && this.edit
    }
  },

  methods: {
    ...mapMutations('post', ['switchFreshPage', 'updatePostState']),
    ...mapActions('post', ['getPostDetail', 'uploadPostImage', 'uploadContentImage', 'deletePost', 'updatePost']),
    handleChange (e, field) {
      this.updatePostState({ value: e, field: field })
    },
    async uploadImage (image) {
      let file = image.file
      let imgName = file.name
      let idx = imgName.lastIndexOf(".")
      if (idx != -1) {
        let ext = imgName.substr(idx + 1).toUpperCase()
        ext = ext.toLowerCase()
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
          //upload file
          await this.uploadPostImage({
            file: file,
            fileType: ext,
            postid: this.postid,
            type: 'cover'
          })
        } else {
          this.$message({
            message: '仅支持.png, .jpg, .jpeg图片格式',
            type: 'warning'
          });
        }
      }
    },
    async uploadContentImage (image) {
      let file = image.file
      let imgName = file.name
      let idx = imgName.lastIndexOf(".")
      if (idx != -1) {
        let ext = imgName.substr(idx + 1).toUpperCase()
        ext = ext.toLowerCase()
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
          //upload file
          await this.uploadPostImage({
            file: file,
            fileType: ext,
            postid: this.postid,
            type: 'content'
          })
        } else {
          this.$message({
            message: '仅支持.png, .jpg, .jpeg图片格式',
            type: 'warning'
          });
        }
      }
    },
    uploadSuccess () {
      let quill = this.$refs.myQuillEditor.quill;
      if (this.uploadRes.code === 1) {
        let length = quill.getSelection().index; // 获取光标所在位置
        quill.insertEmbed(length, 'image', this.uploadRes.result); // 插入图片  res.info为服务器返回的图片地址
        quill.setSelection(length + 1); // 调整光标到最后
      } else {
        this.$message.error('图片插入失败')
      }
    },
    handleUpdate () {
      let title = this.formatPostDetail.state === 'Published' ? '确定将该文章存为草稿吗' : '确定将该文章发布吗', state = ''
      if (this.formatPostDetail.state === 'Draft') {
        state = 'Published'
        if (!this.formatPostDetail.title || !this.formatPostDetail.content || !this.formatPostDetail.cover_image || !this.formatPostDetail.type) {
          this.$message.warning('标题、内容、封面和分类不能为空')
          return
        }
      }
      else if (this.formatPostDetail.state === 'Published') state = 'Draft'
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.updatePost({ id: this.postid, state: state })
      })
    },
    handleSave () {
      let tag = ''
      if (this.formatPostDetail.keyWordCheckList.length) {
        this.formatPostDetail.keyWordCheckList.forEach(item => {
          tag += `${item},`
        })
        if (tag.endsWith(',')) tag = tag.substring(0, tag.length - 1)
      }
      this.updatePost({ id: this.postid, content: this.formatPostDetail.content, type: this.formatPostDetail.type, title: this.formatPostDetail.title, tag: tag, userid: this.formatPostDetail.userid })
    },

    handleDelete () {
      this.$confirm('确定删除该篇文章', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.switchFreshPage(true)
        this.deletePost(this.postid)
      })
    },
    handleAddType () {
      if (!this.type) return
      if (!this.types.includes(this.type)) {
        this.switchFreshPage(true)
        this.customTypes.push(this.type)
      }
      // else 
      this.formatPostDetail.type = this.type
      this.type = ''
      this.showAddType = false
    },
    handleAddKeyWord () {
      if (!this.word) return
      if (!this.words.includes(this.word))
        this.customKeyWords.push(this.word)
      this.formatPostDetail.keyWordCheckList.push(this.word)
      this.word = ''
      this.showAddKeyWord = false
    }
  },
}
</script>
<style lang="less" scoped>
.green {
  color: #67c23a;
}
.red {
  color: #f56c6c;
}
.post-container {
  padding: 0.5rem 30px;
  margin: 0;
  max-width: 100%;
  height: calc(100vh - 140px);
  overflow: auto;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .cover-image {
    width: 20rem;
  }
  .image-btn {
    position: relative;
    bottom: 1rem;
  }
  .post-type {
    align-items: center;
  }
  .type-icon {
    margin-left: 1rem;
    position: relative;
    bottom: 3px;
    cursor: pointer;
  }
  .add-type {
    align-items: center;
    width: 12.8rem;
    i {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .key-word {
    align-items: center;
  }
  .el-input {
    top: -4px;
  }
  .el-input__inner {
    height: 36px;
  }
}
.example {
  display: flex;
  flex-direction: column;
  height: 30rem;
  .editor {
    height: 24rem;
  }
  .output {
    width: 100%;
    height: 22rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;
  }
  .ql-container {
    height: 20rem;
    overflow: auto;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
    content: '10px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
    content: '12px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
    content: '14px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: '16px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
    content: '20px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
    content: '24px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
    content: '36px';
  }
  .ql-container {
    font-size: 16px;
  }
}
</style>