<template>
  <div class="p-2">
    <div v-if="activeCareRecords.length <= 0">
      <div class="text-info text-center" style="font-weight: bold;font-size:1.2em;"><br /><br /><br />尚无随诊信息，<el-button :disabled="disableBtn" @click="handleCreate('add')">创建随诊</el-button>
      </div>
    </div>
    <div v-else>
      <el-button v-if="showCreateButton" class="mb-2" :disabled="disableBtn" @click="handleCreate('update')">创建随诊</el-button>
      <el-collapse class="infinite-list care-list" v-infinite-scroll="activeCareRecords">
        <el-collapse-item v-for="care in activeCareRecords" :key="care.id">
          <template slot="title">
            <div class="d-flex" style="width:100%">
              <div style="width: 85%;">
                {{care.created_date}}<i v-if="care.isConfirmed==0" title="患者当日未填写随诊记录" class="ml-2 header-icon el-icon-warning" style="color: rgb(249, 170, 12);"></i>
              </div>
              <div>
                <el-tag v-if="care.isConfirmed==1" :type="care.tag">{{care.feeling_better}}</el-tag>
              </div>
            </div>
          </template>
          <template>
            <div>
              <div class="p-2">患者当日随诊问题反馈</div>
              <div class="p-2">
                <FormElement formType="CR" :careid="care.id" field="feeling_better" :value="care.feeling_better" type="radio" :patientid="care.patientid" label="未见明显好转">
                  <template v-slot:option>未见明显好转</template>
                </FormElement>
                <FormElement formType="CR" :careid="care.id" field="feeling_better" :value="care.feeling_better" type="radio" :patientid="care.patientid" label="有好转，继续治疗">
                  <template v-slot:option>有好转，继续治疗</template>
                </FormElement>
                <FormElement formType="CR" :careid="care.id" field="feeling_better" :value="care.feeling_better" type="radio" :patientid="care.patientid" label="已康复">
                  <template v-slot:option>已康复</template>
                </FormElement>
                <FormElement formType="CR" :careid="care.id" field="feeling_better" :value="care.feeling_better" type="radio" :patientid="care.patientid" label="已转诊治疗">
                  <template v-slot:option>已转诊治疗</template>
                </FormElement>
                <FormElement formType="CR" :careid="care.id" field="feeling_better" :value="care.feeling_better" type="radio" :patientid="care.patientid" label="其他，不要再问我">
                  <template v-slot:option>其他，不要再问我</template>
                </FormElement>
              </div>
            </div>
          </template>
          <div class="p-2">
            <el-button v-if="activeHealthRecord.status === 'Caring' || activeHealthRecord.status ==='Inquiring'" type="primary" size="small" @click="handleUpdate({patientid: care.patientid, id: care.id, health_recordid: care.health_recordid, feeling_better: care.feeling_better, isConfirmed: true})">更新</el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import FormElement from '../components/FormElement'
import moment from 'moment'
export default {
  data () {
    return {
      isChanged: false,
      disableBtn: false
    }
  },
  computed: {
    ...mapGetters('inquiry', ['activeCareRecords',]),
    ...mapGetters('inquiry', ['activeHealthRecord']),
    showCreateButton () {
      if (this.activeCareRecords.length) {
        return moment(new Date()).format('LL') != this.activeCareRecords[0].created_date
      }
      return false
    }
  },
  components: { FormElement },
  methods: {
    ...mapActions('inquiry', ['updateCareRecord', 'createCareRecord']),
    handleUpdate (payload) {
      this.updateCareRecord(payload)
    },
    async handleCreate (payload) {
      if (!this.disableBtn) {
        this.disableBtn = true
        await this.createCareRecord(payload)
        this.disableBtn = false
      }
    }
  },
}
</script>
<style lang="less">
.care-list {
  height: calc(100vh - 120px);
  overflow: auto;
  .care-question {
    width: 6rem;
  }
}
</style>