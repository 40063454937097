<template>
  <div class="chat-user-info show" v-show="showRightPanel">
    <div class="chat-user-head">
      <div class="row align-items-center">
        <div class="col-4">
          <h5>分屏显示</h5>
        </div>
        <div class="col-8 d-flex justify-between">
          <el-select class="health_record-dropdown" value-key="id" :value="secondHR" placeholder="请选择" @change="handleChangeSecondHR">
            <el-option v-for="item in activeClientHealthRecords" :key="item.id" :label="item.display_text" :value="item">
            </el-option>
          </el-select>
          <ul class="list-inline float-right mb-0">
            <li class="list-inline-item">
              <a href="#" id="close-user-info"><i class="feather icon-x" @click="hideRightPanel"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="chat-user-body">
      <el-tabs v-if="!secondHR.id || secondHR.id === activeHealthRecord.id" type="border-card">
        <el-tab-pane :key="7" v-if="activeClient && activeClient.openid" label="问卷" name="问卷">
          <InquiryForm />
        </el-tab-pane>
        <el-tab-pane :key="8" label="病历" name="病历">
          <HealthRecord :edit="edit" />
        </el-tab-pane>
        <el-tab-pane :key="9" label="随诊" name="随诊">
          <CareList />
        </el-tab-pane>
        <el-tab-pane :key="10" label="预约" name="预约">
          <SubscribeList />
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-else type="border-card">
        <el-tab-pane :key="11" label="病历" name="病历">
          <SubHealthRecord />
        </el-tab-pane>
        <el-tab-pane :key="12" label="随诊" name="随诊">
          <SubCareList />
        </el-tab-pane>
        <el-tab-pane :key="13" label="预约" name="预约">
          <SubscribeList />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import HealthRecord from '@/components/HealthRecord.vue';
import SubHealthRecord from '@/components/SubHealthRecord.vue';
// import PrescriptionList from '@/components/PrescriptionList.vue';
// import SubPrescription from '@/components/SubPrescription.vue';
import InquiryForm from '@/components/InquiryForm.vue';
import CareList from '@/components/CareList.vue';
import SubscribeList from '@/components/SubscribeList.vue';
import SubCareList from '@/components/SubCareList.vue';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'inquiry',

  data () {
    return {
      edit: false,
      subHealthRecord: {}
    }
  },

  components: { HealthRecord, InquiryForm, CareList, SubHealthRecord, SubCareList, SubscribeList },

  computed: {
    ...mapState('index', ['showRightPanel']),
    ...mapState('inquiry', ['secondHR']),
    ...mapGetters('chat', ['activeClient']),
    ...mapState('chat', ['activeClientHealthRecords']),
    ...mapGetters('inquiry', ['activeHealthRecord']),
  },

  methods: {
    ...mapMutations('index', ['hideRightPanel']),
    ...mapMutations('inquiry', ['setSecondHR']),
    ...mapActions('chat', ['switchActiveUserHr']),
    handleChangeSecondHR (event) {
      this.setSecondHR(event)
      this.switchActiveUserHr(Object.assign(event, { secondType: 'sub' }))
    }
  }
};
</script>
<style scoped>
.health_record-dropdown {
  width: 16rem;
}
.chat-user-head {
  padding-bottom: 16px;
}
.justify-between {
  justify-content: space-between;
}
</style>