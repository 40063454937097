<template>
  <div class="chat-menu">
    <ul class="nav nav-pills nav-justified mb-0">
      <li class="nav-item">
        <a class="nav-link" :class="[showChatPanel ? 'active' : '']" @click="openChatPanel">
          <el-badge :value="allUnReadMessagesCount">
            <i class="feather icon-message-circle"></i>
          </el-badge>

        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" :class="[showChatHistoryPanel ? 'active' : '']">
          <i class="feather icon-edit-1"></i>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" :class="[showProfilePanel ? 'active' : '']" @click="openProfilePanel">
          <i class="feather icon-user"></i>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" :class="[showConfigurationPanel ? 'active' : '']">
          <i class="feather icon-settings"></i>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'chat_menu',

  computed: {
    ...mapState('index', ['showChatPanel', 'showChatHistoryPanel', 'showProfilePanel', 'showConfigurationPanel']),
    ...mapGetters('chat', ['allUnReadMessagesCount'])
  },


  methods: {
    ...mapMutations('index', ['openChatPanel', 'openChatHistoryPanel', 'openProfilePanel', 'openConfigurationPanel'])
  }
};
</script>
<style scoped>
</style>
