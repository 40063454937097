<template>
  <div>
    <div class="table-container">
      <div class="source">
        <div class="filter pb-2 d-flex search-box">
          <div class="d-flex">
            <el-select v-model="category" placeholder="选择药品分类" @change="changeType">
              <el-option label="全部药品类别" :value="''"></el-option>
              <el-option v-for="(type, index) in medicineTypes" :key="index" :label="type" :value="type"></el-option>
            </el-select>
            <div class="demo-input-suffix ml-2">
              <el-input placeholder="请输入药品名称" clearable v-model="name"></el-input>
            </div>
            <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="handleSearchMedicineName">查找</el-button>
          </div>
          <div class="pr-2">
            <el-button type="success" @click="handleCreate">新增药品</el-button>
          </div>
        </div>
        <div class="table-content">
          <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="medicineList">
            <template slot="empty">
              <p v-text="pageNumber===0 ? '暂无数据' : ''"></p>
            </template>
            <el-table-column prop="name" sortable label="名称">
            </el-table-column>
            <el-table-column prop="unit_type" label="单位">
            </el-table-column>
            <el-table-column prop="sku" label="规格">
            </el-table-column>
            <el-table-column prop="price" label="单价" sortable>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="d-flex">
                  <el-button size="small" type="primary" icon="el-icon-edit" @click="editMedicine(scope.row)">修改</el-button>
                  <!-- <el-button size="small" v-if="scope.row.price" type="danger" @click="updateActive(scope.row)">隐藏</el-button> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
          <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
        </div>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="stageMedicine" label-width="80px">
        <el-form-item label="名称">
          <el-input v-model="stageMedicine.name"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-if="!isEdit" class="select" v-model="stageMedicine.category" placeholder="选择药品分类">
            <el-option v-for="(type, index) in medicineTypes" :key="index" :label="type" :value="type"></el-option>
          </el-select>
          <span v-else>{{stageMedicine.category}}</span>
        </el-form-item>
        <template v-if="stageMedicine.category === '普通药品' || stageMedicine.category=== '医疗器械'">
          <el-form-item label="规格">
            <div class="d-flex">
              <el-input class="select" type="text" v-model="stageMedicine.sku" placeholder="10mg*24片"></el-input>
              <span class="mx-2"> / </span>
              <el-select v-if="stageMedicine.unit_type !== '其他单位' && !isEdit" class="select" v-model="stageMedicine.unit_type" placeholder="选择药品单位">
                <template v-if="stageMedicine.category === '普通药品'">
                  <el-option v-for="(type, index) in unitTypes" :key="index" :label="type" :value="type"></el-option>
                </template>
                <el-option v-else v-for="(type, index) in instrumentsUnitTypes" :key="index" :label="type" :value="type"></el-option>
              </el-select>
              <span v-else-if="isEdit">{{stageMedicine.unit_type}}</span>
              <el-input v-else class="select" placeholder="请输入单位" v-model="stageMedicine.new_unit_type"></el-input>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="单价">
          <div class="d-flex">
            <el-input class="select" type="number" v-model="stageMedicine.price"></el-input>
            <span v-if="stageMedicine.category === '普通药品' || stageMedicine.category === '医疗器械'" class="ml-2"> / {{stageMedicine.unit_type ==='其他单位' ? stageMedicine.new_unit_type : stageMedicine.unit_type}}</span>
            <span v-else class="ml-2"> / 贴</span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpsertMedicine">{{dialogButton}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  name: 'medicine_list',

  data () {
    return {
      name: '',
      pageIndex: 0,
      category: '',
      isClick: true,
      edit: false,
      busy: true,
      stageMedicine: { prefer_dosage_type: 'mg', new_unit_type: '', category: '普通药品', price: '' },
      dialogVisible: false,
      dialogTitle: '',
      dialogButton: '',
      dosageTypes: ['mg', 'g'],
      unitTypes: ['粒', '片', '贴', '支', '颗', '包', '盒', '瓶', 'mg', 'g', 'mL', 'L', '其他单位'],
      instrumentsUnitTypes: ['个', '其他单位'],
      isEdit: true,
      medicineTypes: ['普通药品', '沃爱贴', '医疗器械']
    }
  },

  mounted () {
    // await this.getMedicineTypes()
    this.initMedicineList()
  },

  computed: {
    ...mapState('medicine', ['loading', 'pageNumber', 'medicineList', 'medicineTypeMapping']),
    ...mapGetters('post', ['formatPosts', 'formatPostDetail']),
    disabled () {
      return this.loading || this.noMore
    },
    noMore () {
      return this.pageNumber && this.pageIndex >= this.pageNumber
    },
    showNew () {
      return this.stageMedicine.unit_type === '新建单位'
    }
  },

  methods: {
    ...mapActions('medicine', ['getMedicineList', 'upsertMedicine', 'deleteMedicine', 'emptyMedicinePrice']),
    ...mapMutations('medicine', ['switchMedicineLoading', 'emptyMedicineList', 'emptyMedicinePageNumber']),
    async load () {
      this.switchMedicineLoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      await this.getMedicineList({ name: this.name, category: this.category === '全部药品类型' ? '' : this.category, pageIndex: this.pageIndex++ })
    },
    async handleSearchMedicineName () {
      if (this.isClick) {
        this.isClick = false
        this.initMedicineList()
        await this.load()
        this.isClick = true
      }
    },
    initMedicineList () {
      this.pageIndex = 0
      this.emptyMedicineList()
      this.emptyMedicinePageNumber()
    },
    async handleCreate () {
      this.dialogButton = '确 认'
      this.dialogTitle = '新增药品'
      this.dialogVisible = true
      this.isEdit = false
      this.stageMedicine = { category: "普通药品" }
    },
    async changeType () {
      this.initMedicineList()
      await this.load()
    },
    editMedicine (medicine) {
      this.dialogTitle = '修改药品信息'
      this.dialogButton = '修 改'
      this.isEdit = true
      this.stageMedicine = JSON.parse(JSON.stringify(medicine))
      this.stageMedicine['typeWapper'] = this.medicineTypeMapping[this.stageMedicine.type]
      this.dialogVisible = true
    },
    async handleUpsertMedicine () {
      if (!this.stageMedicine.name) {
        this.$message('药品名称不能为空')
        return
      }
      this.stageMedicine.unit_type = this.stageMedicine.new_unit_type ? this.stageMedicine.new_unit_type : this.stageMedicine.unit_type
      if (this.stageMedicine.category === '普通药品') {
        if (!this.stageMedicine.sku || !this.stageMedicine.unit_type) {
          this.$message('药品规格和单位不能为空')
          return
        }
      }
      if (this.stageMedicine.category === '沃爱贴')
        this.stageMedicine.unit_type = '贴'
      await this.upsertMedicine(this.stageMedicine)
      this.stageMedicine = { prefer_dosage_type: 'mg', new_unit_type: '', category: '普通药品' },
        this.dialogVisible = false
      if (this.dialogButton === '确 认') {
        await this.changeType()
      }
    },
    async handleDelete (id) {
      this.$confirm('确认删除', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.deleteMedicine(id)
        this.changeType()
      })
    },
    async updateActive (row) {
      this.$confirm(`确定要从药品库里隐藏"${row.name}"`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(async () => {
        if (row.active == 0) {
          row.active = 1
        } else {
          row.active = 0
        }
        await this.emptyMedicinePrice({ medicine: row, priceid: row.priceid })
      })
    }
  }
}
</script>
<style lang="less">
.search-box {
  justify-content: space-between;
}
.back {
  padding: 0.3rem 0 0.3rem 0.5rem;
  .el-page-header__left::after {
    display: none;
  }
}
.post-header {
  align-items: center;
  border-bottom: 1px solid #ebeef5;
  padding: 2px;
  h5 {
    position: relative;
    top: 6px;
  }
}
.select {
  width: 15.6rem;
}
</style>