<template>
  <div class="clinical-process">
    <h5 class="ml-3">临床处置</h5>
    <el-tabs :value="editableTabsValue" tab-position="left">
      <el-tab-pane
        v-for="item in editableTabs"
        :key="item.name"
        :label="item.title"
        :name="item.name"
      >
        <div class="medicine-table">
          <table>
            <thead>
              <tr>
                <td width="40"></td>
                <td width="120">药物名称</td>
                <td width="240">剂量</td>
                <td
                  width="380"
                  v-if="
                    item.category === '普通药品' || item.category === '沃爱贴'
                  "
                >
                  Sig.
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(medicine, index) in activeHealthRecord.medicines"
                :key="index"
              >
                <template v-if="medicine.category === item.category">
                  <td>
                    <i
                      class="el-icon-close cursor-pointer"
                      @click="handleUncheckMedicine(medicine)"
                      style="color: red"
                    ></i>
                  </td>
                  <td>
                    {{ medicine.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <span
                        v-if="
                          item.category === '普通药品' ||
                          item.category === '医疗器械'
                        "
                        class="mr-2"
                        style="line-height: 40px; width: 4.5rem"
                        >{{ medicine.sku }}</span
                      >
                      <span
                        v-if="item.category === '普通药品'"
                        class="switch_type"
                        >×</span
                      >
                      <FormElement
                        class="mx-2"
                        formType="HR"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="unit"
                        :value="medicine.unit"
                        :price="medicine.price"
                        type="number"
                      />
                      <span class="switch_type">{{ medicine.unit_type }}</span>
                    </div>
                  </td>
                  <td v-if="item.category === '普通药品'">
                    <div class="d-flex">
                      <FormElement
                        class="mx-1"
                        formType="HR"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="per_quantity"
                        :value="medicine.per_quantity"
                        type="number"
                      />
                      <FormElement
                        v-if="medicine.per_unit !== '其他单位'"
                        class="switch_type cursor_pointer mr-2"
                        formType="HR"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="per_unit"
                        :value="medicine.per_unit"
                        type="dropdown"
                        :dropdown_options="unitTypes"
                        :unit_type="medicine.unit_type"
                      />
                      <FormElement
                        v-else
                        formType="HR"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="new_per_unit"
                        :value="medicine.new_per_unit"
                        type="text"
                      />
                      <span class="switch_type">×</span>
                      <FormElement
                        class="mx-2"
                        formType="HR"
                        style="width: 8rem"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="frequency"
                        :picklist_options="frequencyOptions"
                        :value="medicine.frequency"
                        type="picklist"
                      />
                      <!-- <el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in deliveryPlaces" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select> -->
                      <FormElement
                        class="mx-2"
                        formType="HR"
                        style="width: 8rem"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="delivery"
                        :picklist_options="deliveryPlaces"
                        placeholder="口服"
                        :value="medicine.delivery"
                        type="picklist"
                      />
                    </div>
                  </td>
                  <td v-if="item.category === '沃爱贴'">
                    <div class="d-flex">
                      <FormElement
                        class="mx-1"
                        formType="HR"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="per_quantity"
                        :value="medicine.per_quantity"
                        type="number"
                      />
                      <span class="switch_type">
                        {{medicine.unit_type == '瓶' ? '喷' : '贴'}}
                      </span>
                      <FormElement
                        class="mx-2"
                        formType="HR"
                        style="width: 8rem"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="frequency"
                        :picklist_options="watFrequencyOptions"
                        :value="medicine.frequency"
                        type="picklist"
                      />
                      <FormElement
                        class="mx-2"
                        formType="HR"
                        style="width: 8rem"
                        :patientid="activeHealthRecord.patientid"
                        :medicineid="medicine.id"
                        isMedicine="true"
                        field="delivery"
                        :picklist_options="watDeliveryPlaces"
                        placeholder=""
                        :value="medicine.delivery"
                        type="picklist"
                      />
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="my-3">
      <el-autocomplete
        class="medicine-search"
        popper-class="my-autocomplete"
        v-model="searchKey"
        :fetch-suggestions="querySearch"
        placeholder="请输入药品名称"
        @select="handleSelect"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"> </i>
      </el-autocomplete>
    </div>
    <el-input
      class="mt-1"
      type="textarea"
      :value="activeHealthRecord.clinical_treatment"
      @input="handleUpdateInspection"
      placeholder="请输入临床处置"
    ></el-input>
  </div>
</template>
<script>
const typeOptions = ["贴剂治疗", "口服治疗", "喷剂治疗", "雾化治疗"];
const clinicalProcess = {
  贴剂治疗: "Patch",
  口服治疗: "Oral",
  喷剂治疗: "Spray",
  雾化治疗: "Atomization",
};
const frequencyOptions = [
  { label: "两日一次", value: 0.5 },
  { label: "一日一次", value: 1 },
  { label: "一日两次", value: 2 },
  { label: "一日三次", value: 3 },
  { label: "半月一次", value: 10 },
  { label: "一月一次", value: 20 },
  { label: "三月一次", value: 30 },
  { label: "必要时", value: -1 },
  { label: "自备", value: 9 },
];

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import FormElement from "../components/FormElement";
export default {
  data() {
    return {
      types: typeOptions,
      activeTab: "",
      newMedicineName: "",
      unit_type: "",
      frequencyOptions: frequencyOptions,
      clinicalProcess: clinicalProcess,
      searchKey: "",
      activeMedicineType: "",
      unitTypes: [
        "粒",
        "片",
        "贴",
        "支",
        "颗",
        "包",
        "盒",
        "瓶",
        "滴",
        "mg",
        "g",
        "mL",
        "L",
        "其他单位",
      ],
      deliveryPlaces: [
        { label: "外用", value: "外用" },
        { label: "滴眼", value: "滴眼" },
        { label: "滴鼻", value: "滴鼻" },
        { label: "滴耳", value: "滴耳" },
        { label: "塞肛门", value: "塞肛门" },
        { label: "雾化吸入", value: "雾化吸入" },
        { label: "肌肉注射", value: "肌肉注射" },
        { label: "静脉滴注", value: "静脉滴注" },
        { label: "皮下注射", value: "皮下注射" },
        { label: "洗鼻", value: "洗鼻" },
        { label: "喷咽部", value: "喷咽部" },
        { label: "睡前服", value: "睡前服" },
        { label: "饭后服", value: "饭后服" },
        { label: "空腹服", value: "空腹服" },
        { label: "备用", value: "备用" },
        { label: "自备", value: "自备" },
        { label: "", value: "" },
      ],
      watFrequencyOptions: [
        { label: "48小时一次", value: 0.5 },
        { label: "72小时一次", value: 0.3 },
        { label: "必要时", value: 60 },
      ],
      watDeliveryPlaces: [
        { label: "外用", value: "外用" },
        { label: "喷咽部", value: "喷咽部" },
        { label: "", value: "" },
      ],
    };
  },

  components: { FormElement },

  computed: {
    ...mapGetters("inquiry", ["activeHealthRecord"]),
    ...mapState("inquiry", [
      "clinicalProcessReverse",
      "medicinePerUnitDialog",
      "medicine",
    ]),
    ...mapState("medicine", ["medicineTypeMapping"]),
    ...mapState("index", ["medicines"]),
    selectedTypes: {
      get() {
        return this.activeHealthRecord.selectedTypes
          ? this.activeHealthRecord.selectedTypes
          : [];
      },
      set(types) {
        this.updateHR("selectedTypes", types);
      },
    },
    editableTabs() {
      if (this.selectedTypes.length > 0) {
        return this.selectedTypes.map((type) => {
          return {
            title: type,
            name: type,
            category: type,
          };
        });
      } else {
        return [];
      }
    },
    editableTabsValue() {
      if (!this.activeTab) {
        return this.editableTabs.length > 0
          ? this.editableTabs[this.editableTabs.length - 1].name
          : "";
      } else {
        return this.activeTab;
      }
    },
  },
  methods: {
    ...mapMutations("chat", ["changeClientSaveStatus"]),
    ...mapMutations("inquiry", [
      "updateActiveHealthRecord",
      "updatePreferMedicine",
      "pushPopMedicine",
    ]),
    ...mapActions("inquiry", ["createMedicine"]),
    ...mapActions("index", ["getMedicines"]),
    updateHR(key, value) {
      this.updateActiveHealthRecord({
        isField: true,
        patientid: this.activeHealthRecord.patientid,
        field: key,
        value: value,
      });
      this.changeClientSaveStatus({
        id: this.activeHealthRecord.patientid,
        hrid: this.activeHealthRecord.id,
        result: true,
      });
    },
    handleCheckboxChange(isChecked, event) {
      if (isChecked) {
        this.activeTab = event.target.innerText;
      } else {
        this.activeTab = "";
      }
    },
    handleSelect(item) {
      if (item.value === "没有找到任何药品") {
        this.searchKey = "";
        return;
      }
      this.pushPopMedicine({
        patientid: this.activeHealthRecord.patientid,
        type: "push",
        medicine: item.medicine,
      });
      if (!this.activeHealthRecord.selectedTypes.includes(item.category)) {
        let type = [];
        type = [...this.activeHealthRecord.selectedTypes, item.category];
        this.updateHR("selectedTypes", type);
      }
      this.activeTab = item.category;
      this.searchKey = "";
      let clinical_treatment = this.activeHealthRecord.clinical_treatment;
      clinical_treatment += clinical_treatment
        ? `, ${item.medicine.name}`
        : item.medicine.name;
      this.updateActiveHealthRecord({
        isField: true,
        patientid: this.activeHealthRecord.patientid,
        field: "clinical_treatment",
        value: clinical_treatment,
      });
      this.changeClientSaveStatus({
        id: this.activeHealthRecord.patientid,
        hrid: this.activeHealthRecord.id,
      });
    },
    handleUncheckMedicine(item) {
      this.pushPopMedicine({
        patientid: this.activeHealthRecord.patientid,
        type: "pop",
        medicine: item,
      });
    },
    handleUpdateInspection(event) {
      this.updateActiveHealthRecord({
        patientid: this.activeHealthRecord.patientid,
        isField: true,
        field: "clinical_treatment",
        value: event,
      });
      this.changeClientSaveStatus({
        id: this.activeHealthRecord.patientid,
        hrid: this.activeHealthRecord.id,
      });
    },
    async querySearch(queryString, cb) {
      if (!queryString) {
        cb([]);
      } else {
        let list = [];
        await this.getMedicines({ searchKey: queryString });
        if (this.medicines.length) {
          this.medicines.forEach((item) => {
            let value = "";
            if (item.category === "沃爱贴") {
              value = item.name;
            } else {
              value = `${item.name} - ${item.sku} / ${item.unit_type}`;
            }
            item.per_unit = item.unit_type;
            item.unit = "";
            item.per_quantity = "";
            item.new_per_unit = "";
            item.frequency = "";
            list.push({
              id: item.id,
              value: value,
              category: item.category,
              medicine: item,
            });
          });
        } else {
          list.push({ value: "没有找到任何药品" });
        }
        cb(list);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clinical-process {
  .el-input {
    width: 100px;
  }
}
.medicine-table {
  overflow-x: auto;

  table {
    table-layout: fixed;
    width: fit-content !important;
    .switch_type {
      position: relative;
      top: 10px;
    }
  }
}
.medicine-name {
  width: 420px !important;
}
.medicine-type {
  width: 80px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.medicine-search {
  width: 19rem;
}
</style>