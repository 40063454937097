<template>
  <div>
    <div v-if="activeSubscribes.length" class="infinite-list subscribe-list" v-infinite-scroll="activeSubscribes">
      <div class="mt-2">
        <div v-for="subscribe in activeSubscribes" :key="subscribe.id">
          <div class="d-flex ml-2">
            <div class="mt-2" style="width:90%">
              {{subscribe.name}} - {{subscribe.timeInterval}}
            </div>
            <div>
              <el-tag :type="subscribe.type">{{subscribe.convertStatus}}</el-tag>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-info text-center" style="font-weight: bold;font-size:1.2em;"><br /><br /><br />尚无预约信息
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('inquiry', ['activeSubscribes'])
  }
}
</script>
<style>
.subscribe-list {
  height: calc(100vh - 120px);
  overflow: auto;
  color: #000;
  font-weight: 500;
}
.el-divider--horizontal {
  margin: 10px 0;
}
</style>