<template>
  <div class="tab-pane fade show" :class="[showChatPanel ? 'active' : '']">
    <div class="chat-listbar">
      <HeadBar />
      <!-- <ChatSearchBar /> -->
      <ChatUsers />
    </div>
  </div>
</template>
<script>
import ChatUsers from '@/components/ChatUsers.vue';
import HeadBar from '@/components/HeadBar.vue';
import { mapState } from 'vuex';
export default {
  name: 'chat',

  computed: {
    ...mapState('index', ['showChatPanel']),
  },

  components: { ChatUsers, HeadBar },
};
</script>