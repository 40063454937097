<template>
  <div>
    <div v-if="showApprovalList" class="table-container">
      <div class="source">
        <div class="filter">
          <el-select v-model="value" placeholder="请选择状态" @change="handleChangeStatus">
            <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input class="search-input" placeholder="请输入病人姓名" v-model="search_name" clearable></el-input>
          <el-button type="primary" :disabled="!isClick" id="search" @click="handleSearchPrescription">查找</el-button>
        </div>
        <div class="table-content">
          <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="prescriptions" style="width: 100%;">
            <template slot="empty">
              <p></p>
            </template>
            <el-table-column prop="patient_name" sortable label="病人姓名"></el-table-column>
            <el-table-column prop="created_date" sortable label="就诊日期"></el-table-column>
            <el-table-column prop="doctor_name" sortable label="医生姓名"></el-table-column>
            <el-table-column prop="status.value" sortable label="状态">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status.class" disable-transitions>{{scope.row.status.value}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="viewPrescriptionDetail(scope.row.id)" type="text" size="small"><i :class="getOpeartion(scope.row.status.value)==='查看'?'el-icon-search':'el-icon-tickets'"></i>{{getOpeartion(scope.row.status.value)}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
          <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
          <p class="text-center" v-if="!loading && !prescriptions.length">暂无数据</p>
        </div>
      </div>
    </div>
    <div v-else class="d-flex approval-header p-1">
      <el-page-header @back="backToApproalList"></el-page-header>
      <h5>处方单明细</h5>
      <el-tag class="ml-3" :type="prescriptionStatus.class" size="small">{{prescriptionStatus.value}}</el-tag>
    </div>
    <PrescriptionApprovalDetail />
  </div>
</template>
<script>
import PrescriptionApprovalDetail from "@/components/PrescriptionApprovalDetail.vue";
import { mapState, mapActions, mapMutations } from "vuex"
import moment from "moment"
moment.locale("zh-cn")
export default {
  components: { PrescriptionApprovalDetail },
  data () {
    return {
      value: "", // current status,
      search_name: "",
      showApprovalList: true,
      page: 0,
      isClick: true
    }
  },

  computed: {
    ...mapState("index", ["userInfo", "showRightBar", "showProfilePanel"]),
    ...mapState("approval", [
      "statusOptions",
      "prescriptions",
      "prescription",
      "totalNumber",
      "pageNumber",
      "pageSize",
      "prescriptionStatus",
      "loading"
    ]),
    noMore () {
      return this.pageNumber && this.page >= this.pageNumber
    },
    disabled () {
      return this.loading || this.noMore
    }
  },

  async mounted () {
    this.emptyPageNumber()
    await this.getPrescriptionStatusOptions()
  },

  destroyed () {
    this.emptyPrescriptions()
    this.page = 0
  },

  methods: {
    ...mapActions("approval", ["getPrescriptions", "getPrescriptionItems", "getPrescriptionStatusOptions"]),
    ...mapMutations("index", ["openProfilePanel"]),
    ...mapMutations("approval", ["setCurrentPrescriptionId", "openDetail", "emptyPrescriptions", "switchPrescriptionLoading", "emptyPageNumber"]),

    viewPrescriptionDetail (id) {
      this.setCurrentPrescriptionId(id) //set select prescription firstly
      this.getPrescriptionItems()  // set selected prescription detail 
      this.showApprovalList = false // show prescription detail 
    },
    getOpeartion (value) {
      return (value === "已通过" || value === "已取消" || value === '已取药') ? "查看" : "审核"
    },
    handleChangeStatus (e) {
      this.value = e
      this.search_name = ""
      this.initPrescirptionList()
      this.load()
    },
    async handleSearchPrescription () {
      if (this.isClick) {
        this.isClick = false
        this.initPrescirptionList()
        await this.load()
        this.isClick = true
      }
    },
    backToApproalList () {
      this.showApprovalList = true
    },
    async load () {
      this.switchPrescriptionLoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      await this.getPrescriptions({ status: this.value, page: this.page++, name: this.search_name })
    },
    initPrescirptionList () {
      this.emptyPageNumber()
      this.emptyPrescriptions()
      this.page = 0
    }
  }
}
</script>
<style lang="less">
.table-container {
  border-radius: 3px;
  transition: 0.2s;
  .source {
    padding: 24px;
  }
  .table-content {
    .log {
      height: calc(100vh - 170px);
      overflow: auto !important;
      .el-table {
        td {
          height: 57px;
          vertical-align: top;
        }
      }
    }
  }
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .cancel-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}

.filter {
  display: flex;
  .search-input {
    margin: 0 1rem;
    width: auto;
  }
}

.approval-header {
  border-bottom: 1px solid #ebeef5;
  h5 {
    position: relative;
    top: 3px;
  }
}
.empty-height {
  height: 100px !important;
}
</style>