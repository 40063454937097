<template>
  <div class="signature">
    <VueSignaturePad :customStyle="customStyle" ref="signaturePad" />
    <div class="btns-group">
      <el-button type="success" size="small" @click="reset">重置</el-button>
      <el-button type="success" size="small" @click="undo">撤销</el-button>
      <el-button type="success" size="small" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: 'MySignaturePad',
  props: ['type', 'field'],
  data () {
    return {
      customStyle: {
        'width': '600px',
        'height': '200px',
        'margin': '10px 0',
        'border': '1px solid #4CAF50',
        'border-radius': '0.25rem',
        'background-color': '#fff'
      }
    }
  },

  methods: {
    ...mapActions('inquiry', ['updateSignature']),
    reset () {
      this.$refs.signaturePad.clearSignature()
    },
    undo () {
      this.$refs.signaturePad.undoSignature()
    },
    save () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.updateSignature({
          file: data,
          fileType: 'png',
          signType: this.type,
          field: this.field,
        })
        this.$emit('saveSign')
      } else {
        this.$message({
          message: '请签名',
          type: 'warning'
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.signature {
  .btns-group {
    text-align: center;
  }
}
</style>