<template>
  <div class="health_record-container">
    <el-card class="box-card">
      <el-form class="hr-form" ref="form" label-width="70px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="问诊时间">{{subActiveHealthRecord.print_inquiry_date}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="门诊号">{{subActiveHealthRecord.id}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="病人姓名">{{subActiveHealthRecord.name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别">{{subActiveHealthRecord.gender === 0 ? '女' : '男'}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电话">{{subActiveHealthRecord.patient_phone}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="住址">{{subActiveHealthRecord.patient_address}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年龄">{{subActiveHealthRecord.ageText}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="科别">{{subActiveHealthRecord.department}}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-position="top" class="form2">
        <template v-for="(field, index) in subActiveHealthRecord.sections">
          <div :key="index">
            <el-form-item :label="field.label">
              <el-input type="textarea" readonly autosize :value="subActiveHealthRecord[field.fieldName]" />
            </el-form-item>
          </div>
        </template>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {

  computed: {
    ...mapState('inquiry', ['secondActiveClient']),
    ...mapGetters('inquiry', ['subActiveHealthRecord'])
  },

  methods: {
  }
}
</script>
<style lang="less">
.health_record-container {
  padding: 30px;
  margin: 0;
  max-width: 100%;
  height: calc(100vh - 140px);
  overflow: auto;
}
.hr-form .el-form-item {
  margin-bottom: 0;
}
.form2 .el-form-item__label {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
}
.signature {
  .el-dialog {
    width: fit-content !important;
  }
}
.signature-image {
  width: 200px;
}
.input-border-bottom input {
  border: 0px;
  border-radius: 0px;
  padding-right: 0px;
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;
}
.auxiliary_inspection-images {
  justify-content: space-between;
  .title {
    text-align: center;
  }
}
</style>