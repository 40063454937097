<template>
  <div class="prescription-container">
    <el-card class="box-card">
      <el-form label-width="65px">
        <el-form-item label="日期">
          {{prescription.created_date}}
        </el-form-item>

        <el-form-item label="科别">{{ prescription.department }}
        </el-form-item>
        <el-form-item label="费别"> {{ prescription.fee_type }}
        </el-form-item>
      </el-form>

      <el-form label-width="65px">
        <el-form-item label="姓名">{{prescription.name}}</el-form-item>
        <el-form-item label="年龄">{{prescription.ageText}}</el-form-item>
        <el-form-item label="性别">{{prescription.gender === 0 ? '女' : '男'}}</el-form-item>
        <el-form-item label="住址">{{prescription.patient_address}}</el-form-item>
      </el-form>

      <el-form label-width="65px" style="white-space:nowrap;">
        <el-form-item label="过敏史">{{ prescription.allergic_history }}
        </el-form-item>
        <br />
        <el-form-item label="临床诊断">{{ prescription.clinical_diagnose }}
        </el-form-item>
        <br />
        <el-form-item label="备注">{{ prescription.comment }}
        </el-form-item>
        <br />
        <el-form-item label="挂号费">{{ prescription.registration_fee }}
        </el-form-item>
        <br />
        <el-form-item label="治疗费">{{ prescription.treatment_fee }}
        </el-form-item>
        <br />
        <el-form-item label="药品费">{{ prescription.medicine_fee }}
        </el-form-item>
        <br />
        <el-form-item label="总计">{{ prescription.amount }}
        </el-form-item>
      </el-form>
    </el-card>
    <br />
    <el-card class="box-card" v-if="prescriptionItems.length > 0">
      <br />
      <h2>Rp:</h2>
      <template v-for="(prescriptionItem, index) in prescriptionItems">
        <div class="persciption-item" :key="index">
          <div class="d-flex">
            <div class="item-name ml-5">
              {{prescriptionItem.medicine_name}}
            </div>
            <div class="item-dosage">
              {{prescriptionItem.sku}} ×
              {{prescriptionItem.unit}}
              {{prescriptionItem.unit_type}}
            </div>
          </div>
          <div class="item-usage" v-if="prescriptionItem.frequency">
            Sig. {{prescriptionItem.per_quantity}} {{prescriptionItem.per_unit}}<span class="px-1"></span>{{getFrequency(prescriptionItem.frequency)}}
          </div>
        </div>
      </template>
    </el-card>
    <br />
    <el-card class="box-card">
      <el-form :inline="true">
        <el-form-item label="审核签名">
          <el-dialog title="医生签名" :visible.sync="signDialogVisible" center>
            <ESignature type="prescription" @saveSign="signDialogVisible = false" />
          </el-dialog>
          <img v-if="prescription.approval_doctor_signature_url" class="signature-image" :src="prescription.approval_doctor_signature_url" alt="">
          <el-popover placement="top" v-model="signVisible">
            <p>签名</p>
            <div>
              <img class="signature-image" :src="doctor_signature_url" alt="" @click="sign">
            </div>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="signVisible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="signDialogVisible = true">新建</el-button>
            </div>
            <el-link class="sign-btn" slot="reference" icon="el-icon-edit"></el-link>
          </el-popover>
        </el-form-item>
        <el-form-item label="医师">
          <el-input class="input-border-bottom" :readonly="prescription.status==='Delivered'" :value="clonePrescription.doctor_name" @input="handleChange($event, 'doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="药师（审核）">
          <el-input class="input-border-bottom" :readonly="prescription.status==='Delivered'" :value="clonePrescription.pharmacist_name" @input="handleChange($event, 'pharmacist_name')"></el-input>
        </el-form-item>
        <el-form-item label="调配">
          <el-input class="input-border-bottom" :readonly="prescription.status==='Delivered'" :value="clonePrescription.allocation_doctor_name" @input="handleChange($event, 'allocation_doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="核对">
          <el-input class="input-border-bottom" :readonly="prescription.status==='Delivered'" :value="clonePrescription.approval_doctor_name" @input="handleChange($event, 'approval_doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="发药">
          <el-input class="input-border-bottom" :readonly="prescription.status==='Delivered'" :value="clonePrescription.dispensing_doctor_name" @input="handleChange($event, 'dispensing_doctor_name')"></el-input>
        </el-form-item>
      </el-form>
      <el-form>
        <br />
        <div class="d-flex">
          <div v-if="prescriptionStatus.value ==='待审核'">
            <el-button type="primary" @click="approvalPrescription('Approved')">审核</el-button>
          </div>
          <div v-if="prescriptionStatus.value ==='已通过'">
            <el-button type="primary" @click="approvalPrescription('Delivered')">取药</el-button>
          </div>
          <div class="pl-2" v-if="prescriptionStatus.value ==='待审核'">
            <el-button type="danger" @click="approvalPrescription('Canceled')">拒绝</el-button>
          </div>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import ESignature from '../components/ESignature'
export default {
  data () {
    return {
      placeholder: '',
      signDialogVisible: false,
      signVisible: false,
    };
  },
  computed: {
    ...mapGetters('inquiry', ['activeHealthRecord']),
    ...mapGetters('index', ['doctor_signature_url', 'doctor_signature_path']),
    ...mapState("index", ["userInfo"]),
    ...mapState("approval", ["prescriptionItems", "medicineStr", "prescription", "currentPrescritionId",
      "prescriptionStatus"
    ]),
    clonePrescription () {
      return JSON.parse(JSON.stringify(this.prescription))
    }
  },
  async mounted () {
    await this.getPrescriptionItems()
  },
  components: { ESignature },
  methods: {
    ...mapActions('inquiry', ['doctorSign']),
    ...mapActions('approval', ['getPrescriptionItems', 'updatePrescriptionStatus']),
    ...mapMutations('approval', ['openList', 'updatePrescription']),
    approvalPrescription (status) {
      let title = '', tempPrescription = {
        doctor_name: this.prescription.doctor_name,
        approval_doctor_name: this.prescription.approval_doctor_name,
        allocation_doctor_name: this.prescription.allocation_doctor_name,
        dispensing_doctor_name: this.prescription.dispensing_doctor_name,
        pharmacist_name: this.prescription.pharmacist_name
      }
      if (status === 'Approved') {
        title = '确定通过'
        Object.assign(tempPrescription, {
          approved_by_doctorid: this.userInfo.doctorid,
          approved_date: new Date()
        })
      } else if (status === 'Canceled') {
        title = '确定拒绝'
      } else {
        title = '确定取药'
        Object.assign(tempPrescription, {
          take_medicine_by_doctorid: this.userInfo.doctorid,
          take_medicine_date: new Date(),
          health_recordid: this.prescription.health_recordid
        })
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.updatePrescriptionStatus({ status: status, health_recordid: this.prescription.health_recordid, tempPrescription: tempPrescription })
      })
    },
    handleCancel () {
      this.openList();
    },
    getFrequency (frequency) {
      let frequencyMapping = {
        0.5: '两日一次',
        1: '一日一次',
        2: '一日两次',
        3: '一日三次',
        10:'半月一次',
        20:'一月一次',
        30:'三月一次',
        '-1': '必要时',
        9: '自备'
      }
      return frequencyMapping[frequency]
    },
    handleChange (e, field) {
      this.updatePrescription({ value: e, field: field })
    },
    sign () {
      this.doctorSign({
        id: this.prescription.id,
        patientid: this.prescription.patientid,
        approval_doctor_signature: this.doctor_signature_path,
        approval_doctor_signature_url: this.doctor_signature_url,
        signType: 'prescription'
      })
      this.signVisible = false
    },
  },

};
</script>
<style lang="less">
.prescription-container {
  padding: 20px 30px;
  margin: 0;
  max-width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
}

.prescription-container .el-form-item {
  margin-bottom: 0;
}

.form .el-form-item__label {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 5px;
}

.el-form-item__label {
  text-align: left !important;
}

.button-group {
  margin-top: 20px;
}

.medicine-label {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
  color: #606266;
}

.input-border-bottom input {
  border: 0px;
  border-radius: 0px;
  padding-right: 0px;
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;
}

.editTable .el-table__expanded-cell {
  padding: 0px;
}

.persciption-item {
  padding: 2px 0;
  .item-name {
    width: 10rem;
  }
  .item-usage {
    margin-left: 18rem;
  }
}
</style>
