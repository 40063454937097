<template>
  <div class="p-2">
    <div v-if="secondActiveClient.careList.length <= 0">
      <div class="text-info text-center" style="font-weight: bold;font-size:1.2em;"><br /><br /><br />尚无随诊信息</div>
    </div>
    <div v-else>
      <el-collapse class="infinite-list care-list" v-infinite-scroll="secondActiveClient.careList">
        <el-collapse-item v-for="care in secondActiveClient.careList" :key="care.id">
          <template slot="title">
            <div class="d-flex" style="width:100%">
              <div style="width: 85%;">
                {{care.created_date}}<i v-if="care.isConfirmed==0" title="患者当日未填写随诊记录" class="ml-2 header-icon el-icon-warning" style="color: rgb(249, 170, 12);"></i>
              </div>
              <div>
                <el-tag v-if="care.isConfirmed==1" :type="care.tag">{{care.feeling_better}}</el-tag>
              </div>
            </div>
          </template>
          <template>
            <div>
              <div class="p-2">患者当日随诊问题反馈</div>
              <div class="p-2">
                <el-radio-group :value="care.feeling_better">
                  <el-radio v-for="item in careAnswer" :key="item" :label="item"></el-radio>
                </el-radio-group>
              </div>
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      isChanged: false,
      careAnswer: ['未见明显好转', '有好转，继续治疗', '已康复', '已转诊治疗', '其他，不要再问我']
    }
  },
  computed: {
    ...mapState('inquiry', ['secondActiveClient']),
  },
  methods: {
  },
}
</script>
<style lang="less">
.care-list {
  height: calc(100vh - 120px);
  overflow: auto;
  .care-question {
    width: 6rem;
  }
}
</style>