<template>
  <div v-if="activeHealthRecord.familyMember">
    <el-dialog :title="title" :visible.sync="show" width="50%" :show-close="dialogClose" :close-on-press-escape="dialogClose" :close-on-click-modal="dialogClose">
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" label-width="75px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名">
              <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="name" :value="activeHealthRecord.familyMember.name" type="text" :readonly="type !=='edit'" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="ml-2" label="电话号">
              <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="phone" :value="activeHealthRecord.familyMember.phone" type="number" :readonly="type !=='edit'" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="住址">
              <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="address" :value="activeHealthRecord.familyMember.address" type="text" :readonly="type !=='edit'" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="年龄">
              <div class="d-flex">
                <FormElement formType="HR" :min="0" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="y" :value="activeHealthRecord.familyMember.y" type="number" :readonly="type !=='edit'" /><span class="mx-1 adjust-form">岁</span>
                <FormElement formType="HR" :min="0" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="m" :value="activeHealthRecord.familyMember.m" type="number" :readonly="type !=='edit'" /><span class="mx-1 adjust-form">月</span>
                <FormElement formType="HR" :min="0" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="d" :value="activeHealthRecord.familyMember.d" type="number" :readonly="type !=='edit'" /><span class="mx-1 adjust-form">天</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别">
              <div v-if="type==='edit'">
                <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="gender" :value="activeHealthRecord.familyMember.gender" type="radio" :label="1">
                  <template v-slot:option>男</template>
                </FormElement>
                <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="gender" :value="activeHealthRecord.familyMember.gender" type="radio" :label="0">
                  <template v-slot:option>女</template>
                </FormElement>
              </div>
              <span v-else>{{activeHealthRecord.familyMember.gender?'男':'女'}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="ml-2" label="体重">
              <div class="d-flex">
                <FormElement style="width:14.6rem" formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="weight" :value="activeHealthRecord.familyMember.weight" type="number" :readonly="type !=='edit'" />
                <span style="width: 2rem;" class="ml-2 flex-shrink">kg</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="type==='edit'">
          <el-col>
            <el-form-item label="问诊时间">
              <FormElement formType="HR" :patientid="activeHealthRecord.patientid" isFamilyMember="true" field="inquiry_date" :value="activeHealthRecord.familyMember.inquiry_date" type="datetime" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="type==='createHR'">
          <el-form-item label="问诊模板">
            <el-select class="select-template" value-key="id" v-model="hrTemplate" placeholder="请选择病历模板">
              <template v-for="item in hrTemplates">
                <div v-if="item.visible" :key="item.id">
                  <el-option :label="item.name" :value="item"></el-option>
                </div>
              </template>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="isClick" @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" :disabled="isClick" @click="handleConfirm">{{type === 'edit' ? '确认修改':'创建'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import FormElement from '../components/FormElement'
import { mapGetters, mapState } from 'vuex'
export default {

  data () {
    return {
      sicknessTypes: ['咳嗽', '发热', '腹泻', '呕吐', '其他'],
      dialogClose: false,
      hrTemplate: {}
    }
  },

  props: {
    type: String,
    show: Boolean,
    familyInfo: Object,
    title: String,
    isClick: {
      type: Boolean,
      default: false
    }
  },

  components: { FormElement },

  computed: {
    ...mapGetters('inquiry', ['activeHealthRecord']),
    ...mapState('index', ['hrTemplates'])
  },

  methods: {
    handleConfirm () {
      this.activeHealthRecord.familyMember.age = (this.activeHealthRecord.familyMember.y * 10000 + this.activeHealthRecord.familyMember.m * 100 + this.activeHealthRecord.familyMember.d * 1) / 10000
      if (this.type === 'edit') {
        this.$emit('hrUpdateFMInfo')
      }
      else if (this.type === 'createHR') {
        if (!this.hrTemplate.id) {
          this.$message('请选择病历模板')
        }
        this.$emit('createHR', this.hrTemplate)
        this.hrTemplate = {}
      }
    },
    handleCloseDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>
<style>
.select-template {
  width: 15rem;
}
</style>