<template>
  <div>
    <el-card>
      <ClinicalProcess />
    </el-card>
    <br />
    <br />
    <el-card>
      <el-form label-width="65px" style="white-space:nowrap;">
        <el-form-item label="过敏史">
          <el-input :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" type="textarea" :value="selectedPrescription.allergic_history" @input="handleChange($event, 'allergic_history')" placeholder="过敏史"></el-input>
        </el-form-item>
        <br />
        <br />
        <el-form-item label="备注">
          <el-input :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" type="textarea" :value="selectedPrescription.comment" @input="handleChange($event, 'comment')" placeholder="备注"></el-input>
        </el-form-item>
        <br />
      </el-form>
      <!-- 临床处置 -->
    </el-card>
    <br />
    <br />
    <el-card>
      <el-form class="mb-2" :inline="true">
        <el-form-item class="input-border-bottom signature" label="医生">
          <el-dialog title="医生签名" :visible.sync="signDialogVisible" center>
            <ESignature type="healthRecord" field="doctor_signature_url" @saveSign="signDialogVisible = false" />
          </el-dialog>
          <img v-if="activeHealthRecord.doctor_signature_url" class="signature-image" :src="activeHealthRecord.doctor_signature_url" alt="">
          <el-popover placement="top" v-model="signVisible">
            <p>签名</p>
            <div>
              <img class="signature-image" :src="doctor_signature_url" alt="" @click="sign">
            </div>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="signVisible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="signDialogVisible = true">新建</el-button>
            </div>
            <el-link class="sign-btn" slot="reference" icon="el-icon-edit"></el-link>
          </el-popover>
        </el-form-item>
        <el-form-item label="药师（审核）">
          <!-- <el-input class="input-border-bottom" :readonly="activeHealthRecord.status !== 'Inquiring'" :value="selectedPrescription.pharmacist_name" @input="handleChange($event, 'pharmacist_name')"></el-input> -->
          <el-dialog title="药师审核" :visible.sync="signApprovalDialogVisible" center>
            <ESignature type="healthRecord" field="approval_doctor_signature_url" @saveSign="signApprovalDialogVisible = false" />
          </el-dialog>
          <img v-if="activeHealthRecord.approval_doctor_signature_url" class="signature-image" :src="activeHealthRecord.approval_doctor_signature_url" alt="">
          <el-popover placement="top" v-model="signApprovalVisible">
            <p>签名</p>
            <div>
              <img class="signature-image" :src="doctor_signature_url" alt="" @click="signApproval">
            </div>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="signApprovalVisible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="signApprovalDialogVisible = true">新建</el-button>
            </div>
            <el-link class="sign-btn" slot="reference" icon="el-icon-edit"></el-link>
          </el-popover>
        </el-form-item>
        <el-form-item label="调配">
          <el-input class="input-border-bottom" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" :value="selectedPrescription.allocation_doctor_name" @input="handleChange($event, 'allocation_doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="核对">
          <el-input class="input-border-bottom" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" :value="selectedPrescription.approval_doctor_name" @input="handleChange($event, 'approval_doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="发药">
          <el-input class="input-border-bottom" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" :value="selectedPrescription.dispensing_doctor_name" @input="handleChange($event, 'dispensing_doctor_name')"></el-input>
        </el-form-item>
        <el-form-item label="挂号费">
          <el-input type="number" min="0" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" class="input-border-bottom" :value="selectedPrescription.registration_fee" @input="handleChange($event, 'registration_fee')"></el-input>
        </el-form-item>
        <el-form-item label="治疗费">
          <el-input type="number" min="0" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" class="input-border-bottom" :value="selectedPrescription.treatment_fee" @input="handleChange($event, 'treatment_fee')"></el-input>
        </el-form-item>
        <el-form-item label="药品费">
          <el-input type="number" min="0" :readonly="!forceUpdate && activeHealthRecord.status !== 'Inquiring'" class="input-border-bottom" :value="selectedPrescription.medicine_fee" @input="handleChange($event, 'medicine_fee')"></el-input>
        </el-form-item>
        <el-form-item label="总计">
          <span>{{amount}}</span>
        </el-form-item>
        <el-form-item label="费别">
          <el-select :value="selectedPrescription.fee_type" @change="handleChange($event, 'fee_type')" placeholder="请选择">
            <el-option label="公费" value="公费"></el-option>
            <el-option label="自费" value="自费"></el-option>
            <el-option label="医保" value="医保"></el-option>
          </el-select>
        </el-form-item>
        <div class="button-group d-flex justify-between">
          <div class="d-flex flex-warp">
            <el-button class="mt-2" type="success" v-if="forceUpdate|| (activeHealthRecord.status==='Inquiring' || activeHealthRecord.status === 'Caring')" @click="update">保存</el-button>
            <el-button class="mt-2" @click="printHeathRecord">打印病历</el-button>
            <el-button class="mt-2" v-if="selectedPrescription.status == 'Draft' || selectedPrescription.status =='Pending'" @click="savePrescription('submit')">{{activeClient && activeClient.openid ? '发送处方单':'确认处方单'}}</el-button>
            <el-button class="mt-2" @click="printPrescription">打印处方单</el-button>
          </div>
          <div>
            <el-button class="mt-2" @click="gotoInfectiousRecortCard" type="info">传染病报告卡</el-button>
          </div>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import ESignature from '../components/ESignature'
import ClinicalProcess from '../components/ClinicalProcess'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
export default {
  props: {
  },
  data () {
    return {
      signDialogVisible: false,
      signVisible: false,
      signApprovalDialogVisible: false,
      signApprovalVisible: false,
      forceUpdate: true
    };
  },
  // watch: {
  //   'medicines': {
  //     handler (newVal) {
  //       if (this.activeHealthRecord.id) {
  //         let medicine_fee = 0
  //         if (newVal && newVal.length) {
  //           newVal.forEach(item => {
  //             medicine_fee = (parseFloat(medicine_fee ? medicine_fee : 0) + parseFloat(item.unit * item.price ? item.unit * item.price : 0)).toFixed(2)
  //           })
  //         }
  //         this.updatePrescriptionState({ health_recordid: this.activeHealthRecord.id, field: 'medicine_fee', value: medicine_fee })
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  computed: {
    ...mapState('index', ['userInfo']),
    ...mapState('inquiry', ['message']),
    ...mapGetters('inquiry', ['activeHealthRecord', 'activePrescriptions', 'selectedPrescription']),
    ...mapGetters('index', ['doctor_signature_url', 'doctor_signature_path']),
    ...mapGetters('chat', ['activeClient']),
    amount () {
      return (parseFloat(this.selectedPrescription.medicine_fee ? this.selectedPrescription.medicine_fee : 0) + parseFloat(this.selectedPrescription.treatment_fee ? this.selectedPrescription.treatment_fee : 0) + parseFloat(this.selectedPrescription.registration_fee ? this.selectedPrescription.registration_fee : 0)).toFixed(2)
    },
    medicines () {
      return this.activeHealthRecord.id ? this.activeHealthRecord.medicines : []
    }
  },
  components: { ESignature, ClinicalProcess },
  async mounted () {
  },
  methods: {
    ...mapMutations('chat', ['changeClientSaveStatus']),
    ...mapMutations('inquiry', ['updatePrescriptionState', 'emptyMessage']),
    ...mapActions('inquiry', ['updateHealthRecord', 'doctorSign', 'updatePrescription']),
    sign () {
      this.doctorSign({
        id: this.activeHealthRecord.id,
        patientid: this.activeHealthRecord.patientid,
        doctor_signature: this.doctor_signature_path,
        doctor_signature_url: this.doctor_signature_url,
        signType: 'healthRecord',
        field: 'doctor_signature_url'
      })
      this.signVisible = false
    },
    signApproval () {
      this.doctorSign({
        id: this.activeHealthRecord.id,
        patientid: this.activeHealthRecord.patientid,
        approval_doctor_signature: this.doctor_signature_path,
        approval_doctor_signature_url: this.doctor_signature_url,
        signType: 'healthRecord',
        field: 'approval_doctor_signature_url'
      })
      this.signApprovalVisible = false
    },
    printHeathRecord () {
      this.$emit('printHeathRecord')
    },
    gotoInfectiousRecortCard () {
      this.$emit('gotoInfectiousRecortCard')
    },
    update () {
      this.$emit('update')
    },
    handleChange (e, field) {
      this.updatePrescriptionState({ health_recordid: this.activeHealthRecord.id, value: e, field: field })
      this.changeClientSaveStatus({ id: this.activeHealthRecord.patientid, hrid: this.activeHealthRecord.id })
    },
    savePrescription (saveType) {
      if (saveType === 'submit' && this.activeClient.openid) {
        this.$confirm("处方单会被发送到病人的微信小程序上，确定吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(() => {
          this.updatePrescription({ saveType: saveType, data: this.selectedPrescription, openid: this.activeClient.openid })
        }).catch(() => {

        })
      } else
        this.updatePrescription({ saveType: saveType, data: this.selectedPrescription, openid: this.activeClient.openid })
    },
    printPrescription () {
      if (!this.selectedPrescription.id) {
        this.$notify.info({
          message: '请先保存病历',
          position: 'bottom-right',
          offset: 50
        });
        return
      }
      let newPage = this.$router.resolve({ path: `/prescriptionPrint/${this.selectedPrescription.id}/${this.activeHealthRecord.id}` })
      window.open(newPage.href, '_blank')
    },
  }
};
</script>
<style scoped>
.prescription-print {
  float: right;
}
.flex-warp {
  flex-wrap: wrap;
}
.message {
  float: left;
}
.el-card__body {
  padding-bottom: 30px;
}
.success {
  color: #67c23a;
}
.warning {
  color: #e6a23c;
}
.error {
  color: #f56c6c;
}
.info {
  color: #909399;
}
</style>
