<template>
  <div>
    <el-card class="_calendar">
      <el-container>
        <el-main>
          <el-card>
            <el-calendar v-model="checkedDate" :first-day-of-week="7">
              <template slot="dateCell" slot-scope="date">
                <div class="div-Calendar" @click="calendarOnClick(date)">
                  <p :class="date.data.isSelected ? 'is-selected' : ''">
                    {{ String(date.data.day.split('-').slice(2)) }}
                    <!-- <i :class="[date.data.isSelected ?'el-icon-check':'']"></i> -->
                    <!-- <span>{{inday(date.data.day) ? '请假':''}}</span> -->
                  </p>
                </div>
              </template>
            </el-calendar>
          </el-card>
        </el-main>

        <el-aside width="40%" style="overflow: hidden;">
          <el-card v-loading="showSpinner">
            <div class="el-calendar__header">
              <div class="el-calendar__title">排班详情</div>
              <div v-if="new Date() < checkedDate" class="el-calendar__button-group">
                <div class="el-button-group">
                  <button type="button" class="el-button el-button--plain el-button--mini" @click="changeSchedule('work')">
                    <span>工作日排班</span>
                  </button>
                  <button type="button" class="el-button el-button--plain el-button--mini" @click="changeSchedule('off')">
                    <span>周末排班</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="calendar-info">
              <template v-if="showPicker">
                <div class="time-picker p-2">
                  <div>请设置上班时间</div>
                  <div class="d-flex align-center mt-2">
                    <el-time-select style="width:9rem" placeholder="起始时间" v-model="startTime" :picker-options=pickerOption>
                    </el-time-select>
                    <div class="mx-2">至</div>
                    <el-time-select style="width:9rem" placeholder="结束时间" v-model="endTime" :picker-options=pickerOption>
                    </el-time-select>
                  </div>
                  <div class="mt-2">
                    <el-checkbox class="mt-2" v-model="rest">中午休息</el-checkbox>
                    <el-button size="small" class="ml-2" type="primary" @click="handleConfirmTime">确定</el-button>
                    <el-button size="small" @click="backToList">取消</el-button>
                  </div>
                </div>
              </template>

              <div style="padding: 15px 20px;">
                <template v-if="activeSchedules.length && !showPicker">
                  <div v-if="showMsg" style="color:red" class="mb-2">此操作将修改该预约时段的可预约人数</div>
                  <div class="d-flex justify-between mb-2">
                    <div>
                      <el-checkbox :value="allCheck" @change="checkAllItem"></el-checkbox>
                      <span class="mx-3">预约时段</span>
                    </div>
                    <div class="d-flex">
                      <div class="mx-3">人数</div>
                      <div>状态</div>
                    </div>
                  </div>
                  <div v-for="item in activeSchedules" :key="item.id">
                    <div v-if="item.limit" class="d-flex justify-between">
                      <div>
                        <el-checkbox :disabled="!item.limit || !item.id" :value="item.checked" @change="checkTimeItem(item.id)"></el-checkbox>
                        <span class="mx-3">{{item.timeInterval}}</span>
                      </div>
                      <div class="d-flex">
                        <div class="mr-2">{{item.limit}}</div>
                        <div>可预约</div>
                        <!-- <div v-else-if="!item.limit && !item.showEditLimit" class="cursor-pointer" @click="resetScheduleLimit({id: item.id, value:true})">
                          不可预约
                          <i class="el-icon-edit-outline"></i>
                        </div> -->
                        <!-- <div v-else-if="item.showEditLimit">
                          <el-input style="width: 4rem;" v-model="limit" size="mini" type="text"></el-input>
                          <i style="color:green" class="el-icon-circle-check ml-2 mr-1 cursor-pointer" @click="confirmUpdateScheduleLimit(item.id)"></i>
                          <i style="color:red" class="el-icon-circle-close cursor-pointer" @click="resetScheduleLimit({id:item.id, value: false})"></i>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="!activeSchedules.length && !showPicker" class="mt-2">
                  <span v-if="new Date() < checkedDate">暂无预约排班</span>
                  <span v-else>已完成</span>
                </template>
                <div v-if="!showPicker && showConfirmBtn" style="text-align:right">
                  <el-button size="small" type="primary" @click="confirmChangeSchedule">调整预约</el-button>
                  <el-button size="small" @click="backToPicker">取消</el-button>
                </div>
                <div v-if="isChangeScheduled && !showPicker">
                  <el-button size="small" type="primary" @click="closeSelectedSchedule">关闭选中预约</el-button>
                </div>
                <div v-if="activeSchedules.length && new Date()<=checkedDate && !isChangeScheduled && !showConfirmBtn && !showPicker" class="el-calendar__button-group">
                  <div class="el-button-group">
                    <button type="button" class="el-button el-button--plain el-button--mini" @click="checkTime({checkedDate:checkedDate,type:'morning'})">
                      <span>上午休息</span>
                    </button>
                    <button type="button" class="el-button el-button--plain el-button--mini" @click="checkTime({checkedDate:checkedDate,type:'afternoon'})">
                      <span>下午休息</span>
                    </button>
                    <button type="button" class="el-button el-button--plain el-button--mini" @click="checkTime({checkedDate:checkedDate,type:'day'})">
                      <span>全天休息</span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </el-card>
          <div class="tip">
            <span>⚠️ 修改上班时间将影响微信小程序线上挂号的可预约时段</span>
          </div>
          <div>
            <el-button @click="showBatch" class="ml-4" size="mini">批量生成上班时间</el-button>
          </div>
        </el-aside>
      </el-container>
    </el-card>
    <el-dialog title="批量生成上班时间" :visible.sync="showBatchModal" width="50%">
      <div class="text-center my-3">
        <el-date-picker class="mb-2" v-model="batchTimeValue" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerBatchOptions">
        </el-date-picker>
        <br>
        <span class="mt-2">⚠️ 如果未来的时段已有病人预约将无法生成上班时间</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showBatchModal = false">取 消</el-button>
        <el-button type="primary" @click="comfirmBatchWork">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  data: function () {
    return {
      showSpinner: false,
      checkedDate: new Date(),
      allCheck: false,
      showEditLimit: false,
      limit: 15,
      showMsg: false,
      startTime: '',
      endTime: '',
      pickerOption: {
        start: '08:00',
        step: '00:10',
        end: '19:00'
      },
      showPicker: false,
      showConfirmBtn: false,
      rest: true,
      showBatchModal: false,
      pickerBatchOptions: {
        shortcuts: [{
          text: '未来一个月',
          onClick (picker) {
            const start = new Date();
            const end = new Date();
            end.setMonth(start.getMonth() + 1)
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '未来一个季度',
          onClick (picker) {
            const start = new Date();
            const end = new Date();
            end.setMonth(start.getMonth() + 3)
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '未来一年',
          onClick (picker) {
            const start = new Date();
            const end = new Date();
            end.setMonth(start.getMonth() + 12)
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      batchTimeValue: ''
    }
  },

  computed: {
    ...mapState('inquiry', ['schedules', 'unScheduledDate']),
    ...mapState('index', ['userInfo']),
    ...mapGetters('inquiry', ['activeSchedules']),
    isChangeScheduled () {
      let value = false
      this.activeSchedules.some(item => {
        if (item.checked) {
          value = true
          return true
        }
      })
      return value
    }
  },
  created: function () {
    this.$nextTick(() => {
      // 点击前一个月
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      )
      prevBtn.addEventListener("click", () => {
        this.getCurrentDateSchedule(this.checkedDate)
      })

      //点击下一个月
      let nextBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(3)"
      )
      nextBtn.addEventListener("click", () => {
        this.getCurrentDateSchedule(this.checkedDate)
      })

      //点击今天
      let todayBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      )
      todayBtn.addEventListener("click", () => {
        this.getCurrentDateSchedule(this.checkedDate)
      })
    });
  },
  watch: {
    'schedules': {
      handler (newVal) {
        let result = false
        newVal.forEach(item => {
          if (item.checked) {
            result = true
          }
        })
        this.allCheck = result
      },
      deep: true,
      immediate: true
    },
    'checkedDate': {
      handler (newVal, oldVal) {
        if (new Date(newVal) != new Date(oldVal).getTime()) {
          this.showPicker = false
          this.rest = true
        }
      },
      deep: true,
      immediate: true
    },
  },

  mounted () {
    this.getCurrentDateSchedule(this.checkedDate)
  },

  methods: {
    ...mapMutations('inquiry', ['checkTime', 'checkTimeItem', 'checkAllItem', 'updateSchedule', 'resetCurrentDateSchedule', 'resetSchedule', 'emptyOriginalSchedule']),
    ...mapActions('inquiry', ['getCurrentDateSchedule', 'updateScheduleLimit', 'getCurrentMonthSchedule', 'resetHospitalSchedule', 'generateHospitalSchedule']),
    async calendarOnClick (data) {
      this.showSpinner = true
      this.checkedDate = data.data.day
      await this.getCurrentDateSchedule(this.checkedDate)
      this.showSpinner = false
    },
    closeSelectedSchedule () {
      this.$confirm('确定要关闭选中的预约时段?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let changedSchedules = []
        this.schedules.forEach(item => {
          if (item.checked) {
            changedSchedules.push(item.id)
          }
        })
        this.updateScheduleLimit({ changedSchedules: changedSchedules, limit: 0 })
      }).catch(() => {
      })
    },
    resetScheduleLimit (payload) {
      this.showMsg = payload.value
      this.updateSchedule({ id: payload.id, field: 'showEditLimit', value: payload.value })
    },
    confirmUpdateScheduleLimit (id) {
      this.$confirm(`确定要将选中的预约时段人数设置为${this.limit}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.updateScheduleLimit({ changedSchedules: [id], limit: this.limit })
        this.updateSchedule({ id: id, field: 'showEditLimit', value: false })
        this.showMsg = false
      }).catch(() => {
      })
    },
    changeSchedule (type) {
      this.type = type || this.type
      if (this.type === 'work') {
        this.startTime = type ? '07:50' : this.startTime || '07:50'
        this.endTime = type ? '18:00' : this.endTime || '18:00'
      } else {
        this.startTime = type ? '08:30' : this.startTime || '08:30'
        this.endTime = type ? '11:30' : this.endTime || '11:30'
      }
      this.showPicker = true
      this.rest = true
    },
    backToPicker () {
      this.changeSchedule()
    },
    backToList () {
      this.resetSchedule()
      this.showConfirmBtn = false
      this.showPicker = false
      this.rest = true
    },
    confirmChangeSchedule () {
      this.$confirm(`确定要调整该日的预约时间？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.showSpinner = true
        await this.resetHospitalSchedule()
        this.getCurrentDateSchedule(this.checkedDate)
        this.showSpinner = false
        this.backToList()
      }).catch(() => {
      })
    },
    handleConfirmTime () {
      this.resetCurrentDateSchedule({
        time: {
          startTime: this.startTime,
          endTime: this.endTime,
        }, rest: this.rest, checkedDate: this.checkedDate, hospitalid: this.userInfo.hospitalid
      })
      this.showPicker = false
      this.showConfirmBtn = true
      this.$forceUpdate()
    },
    showBatch () {
      this.showBatchModal = true
    },
    async comfirmBatchWork () {
      if (!this.batchTimeValue) {
        this.$message({
          message: "请选择时间范围"
        })
        return
      }
      let [startDate, endDate] = this.batchTimeValue
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      startDate = moment(startDate).format('yyyy-MM-DD')
      endDate = moment(endDate).format('yyyy-MM-DD')
      await this.generateHospitalSchedule({ startDate, endDate })
      this.showBatchModal = false
      this.batchTimeValue = ''
    }
  }
};
</script>
<style scoped>
.is-selected {
  color: #1989fa;
}
.p-popover {
  text-align: center;
}
._calendar {
  height: 99.5%;
  width: 100%;
}
.el-main {
  padding: 0px;
  overflow: hidden;
}
.calendar-info {
  height: 94%;
  overflow: auto;
}
.el-alert {
  margin: 15px 0px;
}
.el-alert:hover {
  transform: scale(1.02);
}
.el-alert:active {
  transform: scale(1.01);
}
</style>
<style >
._calendar .div-Calendar {
  height: 90px;
  box-sizing: border-box;
  padding: 8px;
}
._calendar .el-calendar-table .el-calendar-day {
  padding: 0px;
  height: 90px;
}
._calendar .el-container,
._calendar .el-calendar {
  height: 100%;
}
.is-today p {
  background: red;
  display: inline;
  padding: 5px;
  border-radius: 50%;
  font-weight: bold;
  color: #fff !important;
}
._calendar .el-card__body {
  padding: 0px;
}
.tip {
  padding: 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px;
}
.time-picker {
}
</style>