<template>
  <div class="tab-content" ref="conversation">
    <div class="tab-pane fade show active">
      <!-- <div class="chat-day text-center mb-3"><span class="badge badge-secondary-inverse">Today</span></div> -->
      <div class="chat-message" v-for="(msg, index) in activeMessages" :key="index" :class="[msg.sent_by == 'Patient' ? 'chat-message-left' : 'chat-message-right']">
        <div v-if="msg.is_bot">
          <img class="card-iamge" style="width: 30px;" src="@/assets/images/chat-bot.png" fit="cover" />
        </div>
        <div class="chat-message-text" v-if="msg.type === 'Text'">
          <span class="message">{{ msg.message }}</span>
        </div>
        <div v-else-if="msg.type === 'Image'">
          <el-image class="img-fluid message-image" alt="media" :src="msg.url" :preview-src-list="activeImagePreviewList" fit="contain">
            <div slot="placeholder" class="image-slot">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </div>
        <div class="chat-message-text prescription-card" v-else-if="msg.type === 'Prescription'">
          <div class="card-header">处方单</div>
          <div class="card-content">
            <img class="card-iamge" src="@/assets/images/order.png" fit="cover" />
            <div class="card-detail message-prescription">
              <div class="label">金额</div>
              <div class="amount">¥ {{ msg.amount }}</div>
            </div>
          </div>
          <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        </div>
        <div class="chat-message-text prescription-card" v-else-if="msg.type === 'Inquiry'">
          <div class="card-header">问诊表单</div>
          <div class="card-content">
            <img class="card-iamge" src="@/assets/images/e_signature.png" fit="fit" />
            <div class="card-detail message-inquiry">
              <div class="label">更新确认</div>
              <div class="status">
                <span class="pending" v-if="msg.status === 'Pending'">待确认</span>
                <span class="success" v-else>已确认</span>
              </div>
            </div>
          </div>
          <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        </div>
        <div class="chat-message-text prescription-card" v-else-if="msg.type === 'Question'">
          <div class="card-header status">
            <span>{{ msg.question }}</span>
            <i :class="[msg.status === 'Pending' ? 'el-icon-time pending' : 'el-icon-success success']" :title="msg.status === 'Pending' ? '待回答' : '已回答'"></i>
          </div>
          <div class="card-content">
            <div class="card-detail message-inquiry">
              <div class="label">{{ msg.answer }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="msg.type === 'Audio'">
          <div>
            <audio-player :src="msg.url" :messageid="msg.id" />
          </div>
        </div>
        <div class="chat-message-text" v-else-if="msg.type === 'BotText'">
          <span class="message">{{ msg.reply }}</span>
        </div>
        <div class="chat-message-text prescription-card" v-else-if="msg.type === 'Post'">
          <div class="card-header status">
            <span>为您找到以下相关文章，如没有帮到您，我会尽快通知医生上线为您解答(*^▽^*)</span>
          </div>
          <div class="card-content">
            <div class="card-detail message-inquiry">
              <div class="label" style="white-space: nowrap;" v-for="(post, pi) in msg.posts" :key="pi">{{ post.title }}</div>
            </div>
          </div>
        </div>
        <div class="chat-message-meta">
          <span>
            {{ msg.formatDate }}
            <i class="feather ml-2" v-if="msg.sent_by == 'Doctor'">{{msg.has_read ? '已读' : '未读' }}</i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AudioPlayer from '@/components/Player'
export default {

  components: { AudioPlayer },

  computed: {
    ...mapGetters('chat', ['activeMessages', 'activeImagePreviewList'])
  },

  updated () {
    this.scrollToEnd();
  },

  mounted () {
    this.setActiveTabIndex({ index: 0 })
    this.openChatTab()
    this.scrollToEnd()
  },

  methods: {
    ...mapMutations('chat', ['setActiveTabIndex']),
    ...mapActions('chat', ['openChatTab']),
    scrollToEnd () {
      var content = this.$refs.conversation;
      content.scrollTop = content.scrollHeight;
    }
  }
}
</script>

<style scoped lang="less">
.chat-body {
  height: calc(100vh - 200px) !important;
}
.chat-message-text {
  max-width: 65%;
}
.chat-message-left {
  .media-body {
    a {
      color: #4c595f !important;
    }
  }
}
.prescription-card {
  background-color: #fff !important;

  &::after {
    border: none !important;
  }

  .card-header {
    background-color: transparent;
    padding: 2px 0 5px 0 !important;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    color: #8a98ac !important;
    display: flex;
    justify-content: space-between;
  }

  .card-content {
    color: #8a98ac !important;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 10px;

    .card-iamge {
      height: 48px;
      width: 48px;
    }
    .card-detail {
      margin-top: 5px;
    }
  }
}
.message {
  white-space: pre-wrap;
}
.message-image {
  width: 200px;
  height: 200px;
}

.message-prescription {
  .label {
    font-size: 12px;
    font-weight: bold;
  }
  .amount {
    font-size: 14px;
    font-weight: bold;
    color: #4caf50;
  }
}

.message-inquiry {
  .label {
    font-size: 12px;
    font-weight: bold;
  }
}

.status {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;

  .pending {
    color: #ff9800;
    cursor: help;
    margin-left: 10px;
  }

  .success {
    color: #4caf50;
    cursor: help;
    margin-left: 10px;
  }
}

.image-slot {
  text-align: center;
  padding-top: 80px;
  font-size: 30px;
  color: #9e9e9e;
}
</style>
