<template>
  <div class="health_record-container">
    <el-card class="box-card">
      <div class="d-flex template align-center">
        <div class="d-flex flex-wrap">
          <el-select style="width: 14.8rem" class="mr-2 mb-2" :value="activeHealthRecord.trackTemplateid" @change="handleChangeTemplate" placeholder="请选择病历模板">
            <template v-for="item in hrTemplates">
              <div v-if="item.visible" :key="item.id">
                <el-option :label="item.name" :value="item.id"></el-option>
              </div>
              <div v-else style="display: none" :key="item.id">
                <el-option :label="item.name" :value="item.id"></el-option>
              </div>
            </template>
            <el-option label="管理病历模板" value="999999999" />
          </el-select>
          <el-popover class="mb-2" v-if="activeHealthRecord.status === 'Inquiring'" placement="right" width="300" v-model="templateVisible">
            <p>修改病历模板后，当前病历已经填写的内容会丢失，你确定要修改模板吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="templateVisible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="confirmSwitchTemplate">确定</el-button>
            </div>
            <el-button slot="reference">更换模板</el-button>
          </el-popover>
          <el-button class="ml-2 mb-2" type="primary" @click="handleCreateHR">创建病历</el-button>
        </div>
        <div v-if="activeHealthRecord.status === 'Inquiring' && edit" class="modify-info mb-2" @click="handleUpdateHRInfo"><i class="el-icon-edit"></i>修改信息</div>
      </div>
      <el-form class="hr-form" ref="form" label-width="70px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="问诊时间">{{activeHealthRecord.print_inquiry_date}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="门诊号">{{activeHealthRecord.id}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="病人姓名">{{activeHealthRecord.name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别">{{activeHealthRecord.gender === 0 ? '女' : '男'}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电话">{{activeHealthRecord.patient_phone}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="住址">{{activeHealthRecord.patient_address}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年龄">{{activeHealthRecord.ageText}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重">{{activeHealthRecord.weight ? activeHealthRecord.weight + ' kg':''}} </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="!activeHealthRecord.first_subsequent_visit && !activeHealthRecord.related_id && activeFMHealthRecords.length">
        <el-checkbox v-model="isSubsequentHR">这是一个复诊病历</el-checkbox>
      </div>
      <div v-if="edit && activeFMHealthRecords.length && (activeHealthRecord.first_subsequent_visit === '复诊' || activeHealthRecord.related_id || isSubsequentHR)">
        <div class="d-flex align-center">
          <span>上次问诊：</span>
          <el-select class="health_record-dropdown" v-model="lastHRid" placeholder="请选择">
            <el-option v-for="item in activeFMHealthRecords" :key="item.id" :label="item.display_text_care" :value="item.id">
            </el-option>
          </el-select>
          <div class="ml-2 d-flex">
            <span style="line-height:41px">{{showChoiceCareFeeling? '请选择：':'上次问诊结果：'}}</span>
            <span style="line-height:41px" v-if="!showChoiceCareFeeling">{{currentHRCareInfo.print_date}}{{currentHRCareInfo.feeling_better}}</span>
            <div v-else class="d-flex ml-2 align-center">
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ careChoice }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in careList" :command="item" :key="index">{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <i v-if="activeFMHealthRecords.length && !showChoiceCareFeeling" class="ml-2 cursor-pointer el-icon-edit" @click="showChoiceCareFeeling = true"></i>
          <img v-if="activeFMHealthRecords.length && showChoiceCareFeeling" class="icon ml-3 cursor-pointer" @click="showChoiceCareFeeling = false" src="@/assets/images/back.svg">
        </div>
      </div>
      <el-form label-position="top" class="form2">
        <template v-for="(field, index) in activeHealthRecord.sections">
          <div :key="index">
            <el-form-item v-if="field.label !=='辅助检查' && field.label !=='临床诊断' && field.label !== '临床处置'" :label="field.label">
              <FormElement formType="HR" :readonly="false" :patientid="activeHealthRecord.patientid" isField="true" :field="field.fieldName" :value="activeHealthRecord[field.fieldName]" :placeholder="field.label" type="textarea" />
            </el-form-item>
            <div v-if="field.label === '辅助检查'" class="auxiliary_inspection-images">
              <el-form-item :label="field.label">
                <FormElement formType="HR" :readonly="false" :patientid="activeHealthRecord.patientid" isField="true" :field="field.fieldName" :value="activeHealthRecord[field.fieldName]" :placeholder="field.label" type="textarea" />
              </el-form-item>
              <div>
                <div class="cursor-pointer">
                  <el-upload action="#" class="upload-demo" :http-request="uploadImage" :show-file-list="false" :file-list="fileList">
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </div>
                <div class="d-flex flex-wrap">
                  <template v-for="(file) in activeHealthRecord.formatFiles">
                    <div class="relative" :key="file.id">
                      <el-image style="width:60px; height:60px; margin:.6rem" :preview-src-list="activeHealthRecord.formatFiles.map(file => file.url)" :src="file.url" fit="cover"></el-image>
                      <i class="el-icon-close file-delete" @click="handleDeleteFiles(file.id)"></i>
                    </div>
                  </template>
                </div>
                <div class="d-flex flex-wrap">
                  <div class="inquiry-pics mt-1">
                    <template v-for="(image, index) in activeInquiryImages">
                      <div :key="index">
                        <template v-for="url in image.urls">
                          <el-image style="width:60px; height:60px; margin:.6rem" :key="url.id" :preview-src-list="activeInquiryImagesUrls" :src="url.value" fit="cover"></el-image>
                        </template>
                        <div class="title">
                          问诊资料
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="field.label === '临床诊断'">
              <el-form-item :label="field.label">
                <el-popover placement="bottom" width="400" trigger="focus">
                  <div class="diagnosis-containter">
                    <el-tabs>
                      <el-tab-pane v-for="item in diagnosisTemplates" :key="item.id" :label="item.category">
                        <span class="mr-3 illness-item" v-for="(illness,index) in item.illnessItems" :key="index" @click="handleClickIllnessItem(illness)">{{illness}}</span>
                      </el-tab-pane>
                    </el-tabs>
                    <i class="el-icon-setting popover-icon" @click="showDiagnosisDialog = true"></i>
                  </div>
                  <el-input type='textarea' :readonly="false" :placeholder="field.label" :value="activeHealthRecord[field.fieldName]" @input="handleChangeDiagnose" slot="reference"></el-input>
                </el-popover>
              </el-form-item>
            </div>
          </div>
        </template>
      </el-form>
    </el-card>
    <br />
    <br />
    <DiagnosisTemplate :show="showDiagnosisDialog" @close="showDiagnosisDialog = false" />
    <Prescription @printHeathRecord="printHeathRecord" @update="update" @gotoInfectiousRecortCard="gotoInfectiousRecortCard" />
    <HealthRecordDialog :title="hrTitle" :type="hrType" :show="infoDialogVisible" @hrUpdateFMInfo="confirmUpdateFmInfo" @closeDialog="infoDialogVisible = false" @createHR="handleCreateHealthRecord" />
    <HRTemplate :show="manhrTemplateDialogVisible" @closeDialog="manhrTemplateDialogVisible = false" :isClick="isClick" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import FormElement from '../components/FormElement'
import HealthRecordDialog from '../components/HealthRecordDialog'
import HRTemplate from '../components/HRTemplate'
import Prescription from '../components/Prescription'
import DiagnosisTemplate from '../components/DiagnosisTemplate'
export default {
  data () {
    return {
      dialogVisible: false,
      createDialogVisible: false,
      signDialogVisible: false,
      signVisible: false,
      templateVisible: false,
      infoDialogVisible: false,
      manhrTemplateDialogVisible: false,
      isClick: false,
      fileList: [],
      careList: ['未见明显好转', '有好转，继续治疗', '已康复', '已转诊治疗'],
      careChoice: '问诊结果',
      isSubsequentHR: '',
      showChoiceCareFeeling: false,
      showDropdownList: false,
      hrTitle: '',
      hrType: '',
      active: false,
      showDiagnosisDialog: false,
      switchHR: false,
    }
  },

  components: { FormElement, HealthRecordDialog, HRTemplate, Prescription, DiagnosisTemplate },

  computed: {
    ...mapState('index', ['userInfo', 'hrTemplates', 'diagnosisTemplates']),
    ...mapState('inquiry', ['currentHealthRecord', 'familyMember', 'currentHRCareInfo']),
    ...mapState('chat', ['activeClientHealthRecords', 'hrWithUserInfo', 'allClients']),
    ...mapGetters('chat', ['activeClient']),
    ...mapGetters('inquiry', ['activeHealthRecord', 'activeInquiryImages', 'selectedPrescription', 'activeTodaySubscribe']),
    ...mapGetters('index', ['doctor_signature_url', 'doctor_signature_path']),
    activeInquiryImagesUrls () {
      let urls = []
      this.activeInquiryImages.forEach(item => {
        item.urls.forEach(url => {
          urls.push(url.value)
        })
      })
      return urls
    },
    activeFMHealthRecords () {
      if (this.activeHealthRecord.family_memberid) {
        return this.activeClientHealthRecords.filter((item) => {
          return (item.family_memberid === this.activeHealthRecord.family_memberid || item.name == this.activeHealthRecord.name) && (item.id !== this.activeHealthRecord.id)
        })
      }
      else return []
    },
    lastHRid: {
      get () {
        if (this.activeHealthRecord.related_id) {
          return this.activeHealthRecord.related_id
        }
        let index = this.activeFMHealthRecords.findIndex(item => item.id === this.activeHealthRecord.id)
        return index !== this.activeFMHealthRecords.length - 1 ? this.activeFMHealthRecords[index + 1].id : ''
      },
      set (value) {
        let hrRecords = this.activeFMHealthRecords.find(item => item.id === value)
        this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'related_id', value: value })
        this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'clinical_diagnose', value: hrRecords.clinical_diagnose })
      }
    },
    isToday () {
      return new Date(this.currentHRCareInfo.created_date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    },
  },

  props: {
    edit: Boolean
  },
  watch: {
    'activeHealthRecord.id': {
      handler: function () {
        this.showChoiceCareFeeling = false
        this.isSubsequentHR = false
      }
    }
  },

  methods: {
    ...mapMutations('inquiry', ['updateActiveHealthRecord', 'deleteHRFiles']),
    ...mapMutations('chat', ['updateActiveClientHealthRecords', 'addActiveClientHealthRecords', 'changeClientSaveStatus']),
    ...mapActions('inquiry', ['updateHealthRecord', 'doctorSign', 'updateHealthRecordTemplate', 'switchHealthRecordTemplate', 'getFamilyMember', 'updateFamilyInfo', 'uploadHRPics', 'updateHRFiles', 'upsertCare', 'createPrescription', 'updatePrescription']),
    ...mapActions('chat', ['switchActiveUserHr', 'createHealthRecord']),
    async update () {
      await this.updateHealthRecord()
      if (!this.selectedPrescription.id) {
        await this.createPrescription()
      } else {
        await this.updatePrescription({ saveType: 'save', data: this.selectedPrescription, openid: this.activeClient.openid })
      }
    },
    sign () {
      this.doctorSign({
        id: this.activeHealthRecord.id,
        patientid: this.activeHealthRecord.patientid,
        doctor_signature: this.doctor_signature_path,
        doctor_signature_url: this.doctor_signature_url,
        signType: 'healthRecord'
      })
      this.signVisible = false
    },
    printHeathRecord () {
      let newPage = this.$router.resolve({ path: `/healthRecordPrint/${this.activeHealthRecord.id}` })
      window.open(newPage.href, '_blank')
    },
    handleChangeTemplate (event) {
      if (event === '999999999') {
        this.manhrTemplateDialogVisible = true
      } else {
        this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'trackTemplateid', value: event })
        this.$forceUpdate()
      }
    },
    async confirmSwitchTemplate () {
      if (this.activeHealthRecord.trackTemplateid === this.activeHealthRecord.hr_templateid) {
        this.$message('无法切换相同的病历模板')
        this.templateVisible = false
        return
      }
      if (this.activeHealthRecord.trackTemplateid === '999999999') {
        this.$message('无法选择管理病历按钮')
        this.templateVisible = false
        return
      }
      await this.switchHealthRecordTemplate()
      this.updateActiveClientHealthRecords(this.currentHealthRecord)
      await this.switchActiveUserHr(Object.assign(this.currentHealthRecord, { secondType: 'main' }))
      this.templateVisible = false

    },
    async confirmUpdateFmInfo () {
      this.isClick = true
      await this.updateFamilyInfo(this.activeHealthRecord.familyMember)
      this.isClick = false
      this.infoDialogVisible = false
    },
    handleExceed (files) {
      if (files.length > 3) {
        this.$message('只能同时上传三张')
      }
    },
    async uploadImage (image) {
      try {
        if (this.activeHealthRecord.files.length >= 8) {
          this.$message('不能上传超过八张图片')
          return
        }
        let file = image.file
        let imgName = file.name
        let idx = imgName.lastIndexOf(".")
        if (idx != -1) {
          let ext = imgName.substr(idx + 1).toUpperCase()
          ext = ext.toLowerCase()
          if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
            //upload file
            await this.uploadHRPics({
              file: file,
              fileType: ext,
              hrid: this.activeHealthRecord.id,
              patientid: this.activeHealthRecord.patientid
            })
          } else {
            this.$message({
              message: '仅支持.png, .jpg, .jpeg图片格式',
              type: 'warning'
            });
          }
        }
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        });
      }
    },
    handleDeleteFiles (id) {
      this.$confirm("确认删除该图片", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(async () => {
        await this.updateHRFiles({ id: id, patientid: this.activeHealthRecord.patientid, hrid: this.activeHealthRecord.id, formatFiles: this.activeHealthRecord.formatFiles, files: this.activeHealthRecord.files })
      })
    },
    async handleCommand (command) {
      this.careChoice = command
      if (this.careChoice === '问诊结果') return
      let care = { health_recordid: this.lastHRid, patientid: this.activeHealthRecord.patientid, doctorid: this.userInfo.doctorid, feeling_better: this.careChoice, isConfirmed: 1 }
      if (this.currentHRCareInfo.id && this.lastHRid === this.activeHealthRecord.copy_related_id) {
        if (this.isToday) Object.assign(care, { id: this.currentHRCareInfo.id, created_date: new Date() })
      } else {
        if (!this.currentHRCareInfo.id) {
          this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'related_id', value: this.lastHRid })
          this.$forceUpdate()
        }
        await this.updateHealthRecord()
      }
      await this.upsertCare(care)
      this.showChoiceCareFeeling = false
      this.careChoice = '问诊结果'
    },
    gotoInfectiousRecortCard () {
      let newPage = this.$router.resolve({ path: `/infectiousReportCard/${this.activeHealthRecord.id}` })
      window.open(newPage.href, '_blank')
    },
    handleCreateHR () {
      this.infoDialogVisible = true
      this.hrType = 'createHR'
      this.hrTitle = '创建病历'
    },
    handleUpdateHRInfo () {
      this.infoDialogVisible = true
      this.hrType = 'edit'
      this.hrTitle = '修改病人信息'
    },
    async handleCreateHealthRecord (template) {
      let activeClient = this.allClients.find(client => client.id === this.activeHealthRecord.patientid)
      if (activeClient.unSaveHRs.length && activeClient.unSaveHRs.includes(this.activeHealthRecord.id)) {
        this.$confirm('病历未保存，离开这里可能会丢失你的修改，你确定离开吗？', '提示', {
          confirmButtonText: '离开并保存',
          cancelButtonText: '离开',
          type: 'warning'
        }).then(async () => {
          await this.update()
          this.bundleCreateHealthRecord(template)
        }).catch(() => {
          this.bundleCreateHealthRecord(template)
        })
      } else {
        this.bundleCreateHealthRecord(template)
      }
    },
    async bundleCreateHealthRecord (template) {
      await this.createHealthRecord(Object.assign(this.activeHealthRecord.familyMember, { hrTemplate: template }))
      this.switchActiveUserHr(Object.assign(this.hrWithUserInfo, { secondType: 'main' }))
      this.addActiveClientHealthRecords(Object.assign(this.hrWithUserInfo, { sickness: template.sickness }))
      this.infoDialogVisible = false
    },
    handleClickIllnessItem (event) {
      let clinical_diagnose = this.activeHealthRecord.clinical_diagnose
      clinical_diagnose += clinical_diagnose ? `, ${event}` : event
      this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'clinical_diagnose', value: clinical_diagnose })
      this.$forceUpdate()
      this.updateClientUnSaveStatus()
    },
    handleChangeDiagnose (event) {
      this.updateActiveHealthRecord({ patientid: this.activeHealthRecord.patientid, isField: true, field: 'clinical_diagnose', value: event })
      this.$forceUpdate()
      this.updateClientUnSaveStatus()
    },
    updateClientUnSaveStatus () {
      this.changeClientSaveStatus({ id: this.activeHealthRecord.patientid, hrid: this.activeHealthRecord.id })
    },
  }
}
</script>
<style lang="less">
.health_record-container {
  padding: 30px;
  margin: 0;
  max-width: 100%;
  height: calc(100vh - 140px);
  overflow: auto;
}
.hr-form .el-form-item {
  margin-bottom: 0;
}
.form2 .el-form-item__label {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
}
.signature {
  .el-dialog {
    width: fit-content !important;
  }
}
.signature-image {
  width: 200px;
}
.input-border-bottom input {
  border: 0px;
  border-radius: 0px;
  padding-right: 0px;
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;
}
.auxiliary_inspection-images {
  justify-content: space-between;
  .title {
    text-align: center;
  }
}
.template {
  align-items: center;
  justify-content: space-between;
  .switch-btn {
    border: none;
    background: #fff;
    color: #606266;
    padding: 0px;
  }
  .modify-info {
    cursor: pointer;
    width: 5.1rem;
  }
}
.age-input {
  width: 12rem;
}
.cursor-pointer {
  cursor: pointer;
}
.upload-file {
  margin-left: -75px;
  opacity: 0;
  display: unset !important;
  width: 7rem;
}
.file-delete {
  color: red;
  position: relative;
  font-weight: bold;
  z-index: 1;
  left: -12px;
  cursor: pointer;
}
.inquiry-pics {
  border: 1px solid #cccccc;
}
.flex-wrap {
  flex-wrap: wrap;
}
.el-image-viewer__btn {
  color: #fff !important;
}
.relative {
  position: relative;
}
.justify-between {
  justify-content: space-between !important;
}
.health_record-dropdown {
  width: 11rem;
}
.icon {
  width: 1.2rem;
}
.hide {
  display: none;
}
.illness-item {
  cursor: pointer;
  line-height: 24px;
}
.illness-item:hover {
  color: #409eff;
}
.popover-icon {
  cursor: pointer;
  right: 0px;
  position: relative;
  float: right;
}
.diagnosis-containter {
  position: relative;
  overflow: auto;
}
</style>