<template>
  <div>
    <div v-if="showList" class="table-container">
      <div class="source">
        <div class="filter pb-2 d-flex search-box">
          <div class="d-flex">
            <el-select v-model="type" placeholder="选择文章分类" @change="changeType">
              <el-option v-for="(type, index) in postTypes" :key="index" :label="type.type" :value="type.type"></el-option>
            </el-select>
            <div class="demo-input-suffix ml-2">
              <el-input placeholder="请输入查找内容" clearable v-model="title"></el-input>
            </div>
            <el-button type="primary" :disabled="!isClick" class="ml-3" id="search" @click="handleSearchTitle">查找</el-button>
          </div>
          <div class="pr-2">
            <el-button type="success" @click="handleCreate">创建文章</el-button>
          </div>
        </div>
        <div class="table-content">
          <el-table class="log" v-infinite-scroll="load" :infinite-scroll-disabled="disabled" :data="formatPosts">
            <template slot="empty">
              <p v-text="pageNumber===0 ? '暂无数据' : ''"></p>
            </template>
            <el-table-column prop="title" sortable label="标题">
            </el-table-column>
            <el-table-column label="封面">
              <template slot-scope="scope">
                <img :src="scope.row.cover_image" width="60" height="60" />
              </template>
            </el-table-column>
            <el-table-column prop="type" label="分类">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="100" sortable>
              <template slot-scope="scope">
                <el-tag :type="scope.row.state === 'Published'?'success':'info'">{{scope.row.state === 'Published'?'已发布':'草稿'}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width=200>
              <template slot-scope="scope">
                <div v-if="scope.row.hospitalid === userInfo.hospitalid" class="d-flex">
                  <el-button size="small" :type="scope.row.state === 'Published' ? 'danger':'success'" :icon="scope.row.state === 'Published' ? 'el-icon-bottom':'el-icon-top' " @click="handleUpdate(scope.row.id ,scope.row.state, scope.row)">{{scope.row.state === 'Published' ? '暂存':'发布'}}</el-button>
                  <el-button size="small" type="primary" icon="el-icon-edit" @click="editPost(scope.row.id)">编辑</el-button>
                </div>
                <div v-else class="d-flex">
                  <el-button size="small" type="primary" @click="editPost(scope.row.id)">查看</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <p v-if="loading" class="text-center"><i class="el-icon-loading"></i>加载中...</p>
          <p v-if="noMore" class="text-center mt-2">------ 我是有底线的 -----</p>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="d-flex post-header p3">
        <el-page-header @back="backToPostList"></el-page-header>
        <h5>{{formatPostDetail.title}}</h5>
        <div class="ml-2 d-flex" v-if="edit && formatPostDetail.state==='Published'">
          <div class="mr-2">观看数<i class="el-icon-view"></i></div>{{formatPostDetail.views}}
          <div class="mx-2">互动量<i class="el-icon-s-data"></i></div>{{formatPostDetail.counts}}
        </div>
        <el-tag class="ml-2" :type="formatPostDetail.state ==='Published' ? 'success' : 'info'" size="small">{{formatPostDetail.state ==='Published'?'已发布':'草稿'}}</el-tag>
      </div>
      <PostDetail :edit="edit" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import PostDetail from '@/components/PostDetail.vue'
export default {
  name: 'post_list',

  data () {
    return {
      title: '',
      state: '',
      pageIndex: 0,
      type: '',
      isClick: true,
      postid: '',
      edit: false,
      busy: true
    }
  },

  components: { PostDetail },

  async mounted () {
    this.getDoctors()
    this.initPostList()
    await this.getPostTypes()
  },

  methods: {
    ...mapActions('post', ['getPostList', 'getPostTypes', 'updatePost', 'createPost', 'getDoctors', 'getPostDetail']),
    ...mapMutations('post', ['switchPostLoading', 'emptyPostList', 'emptyPostPageNumber', 'switchShowList', 'setPostDetailId', 'switchFreshPage']),
    async load () {
      this.switchPostLoading()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000);
      })
      await this.getPostList({ title: this.title, type: this.type === '显示全部' ? '' : this.type, pageIndex: this.pageIndex++ })
    },
    async handleSearchTitle () {
      if (this.isClick) {
        this.isClick = false
        this.initPostList()
        await this.load()
        this.isClick = true
      }
    },
    initPostList () {
      this.pageIndex = 0
      this.emptyPostList()
      this.emptyPostPageNumber()
    },
    async handleCreate () {
      if (this.isClick) {
        this.isClick = false
        await this.createPost()
        this.edit = false
        this.switchShowList()
        this.switchFreshPage(true)
        this.isClick = true
      }
    },
    changeType () {
      this.initPostList()
      this.load()
    },
    handleUpdate (id, state, post) {
      let title = state === 'Published' ? '该操作将暂存文章' : '确定发布文章', changeState
      if (state === 'Published') changeState = 'Draft'
      else if (state === 'Draft') changeState = 'Published'
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        if (state === 'Draft') {
          if (!post.content || !post.type || !post.cover_image) {
            this.$message.warning('内容、封面或分类不能为空')
            return
          }
        }
        this.updatePost({ id: id, state: changeState })
      })
    },
    editPost (id) {
      this.setPostDetailId(id)
      this.getPostDetail()
      this.edit = true
      this.switchShowList()
    },
    async backToPostList () {
      if (!this.formatPostDetail.title) {
        this.$message({
          message: '标题是必填',
          type: 'warning'
        });
      }
      else {
        this.switchShowList()
        if (this.freshPage) {
          this.initPostList()
          await this.getPostTypes()
        }
      }
    }
  },

  computed: {
    ...mapState('index', ['userInfo']),
    ...mapState('post', ['loading', 'pageNumber', 'postTypes', 'showList', 'freshPage']),
    ...mapGetters('post', ['formatPosts', 'formatPostDetail']),
    disabled () {
      return this.loading || this.noMore
    },
    noMore () {
      return this.pageNumber && this.pageIndex >= this.pageNumber
    },
  }
}
</script>
<style lang="less">
.search-box {
  justify-content: space-between;
}
.back {
  padding: 0.3rem 0 0.3rem 0.5rem;
  .el-page-header__left::after {
    display: none;
  }
}
.post-header {
  align-items: center;
  border-bottom: 1px solid #ebeef5;
  padding: 2px;
  h5 {
    position: relative;
    top: 6px;
  }
}
</style>